import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 150px;
    align-self: center;
    margin-bottom: 20px;
  }
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 60px;
    img {
      width: 150px;
      align-self: left;
      margin-bottom: 0;
    }
  }
`;

export const HighlightBox = styled.div`
  ${() => css`
    text-align: center;
    margin: 20px 5px 20px;
    padding: 20px 30px;
    background-color: #feffd9;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 600px) {
      margin: 30px 60px 0px;
      padding: 10px 5px;
      font-size: 1.1em;
    }
  `};
`;

export const TextMiddle = styled.div`
  ${() => css`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    @media (min-width: 600px) {
      margin: 10px 60px 14px;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  `};
`;

export const WrapperTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    div {
      color: ${theme.colors.body};
      font-size: 14px;
      text-align: left;
    }
    @media (min-width: 600px) {
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 30px;
    }
  `};
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 4px;
    font-size: 26px;
    line-height: 27px;
    font-weight: 700;
    color: ${theme.colors.primary};
    text-transform: capitalize;
    font-family: ${theme.font.family.montserrat};
    margin-bottom: 11px;
    text-align: center;
    @media (min-width: 600px) {
      font-size: 50px;
      line-height: 44px;
      font-weight: 700;
    }
  `};
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    color: black;
    font-family: ${theme.font.family.montserrat};
    text-align: center;
    @media (min-width: 600px) {
      font-size: 19px;
      line-height: 26px;
      font-weight: 600;
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  @media (min-width: 600px) {
    width: 250px;
    padding: 16px 63px;
    border-width: 0px;
    box-shadow: none;
    font-size: 19px;
    text-align: center;
    border-radius: 40px;
  }
`;

export const Text = styled.div`
  ${({ theme }) => css`
    margin-bottom: 43px;
    font-family: ${theme.font.family.montserrat};
    color: #0e0d0d;
    font-size: 15px;
    line-height: 28px;
    font-weight: 300;
    @media (min-width: 600px) {
    }
  `}
`;

export const SectionBenefits = styled.div`
  ${() => css`
    margin: 40px 0;
    border: none;
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      flex-direction: column;
      flex: 0.8;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      margin: 40px 60px 41px;
      > div:first-child {
        padding-right: 50px;
      }
      > div:nth-child(2) {
        padding-left: 50px;
      }
    }
  `};
`;

export const Benefit = styled.div`
  ${({ theme }) => css`
    width: 100%;
    font-family: ${theme.font.family.montserrat};
    margin-bottom: 34px;
    position: relative;
    padding-left: 30px;
    div {
      margin-bottom: 5px;
      color: ${theme.colors.primary};
      font-size: 23px;
      line-height: 25px;
      font-weight: 600;
    }
    p {
      color: #0e0d0d;
      font-size: 15px;
      line-height: 23px;
      font-weight: 300;
      margin-bottom: 10px;
    }
  `}
`;

export const IconCheck = styled(CheckCircleIcon)`
  ${({ theme }) => css`
    && {
      position: absolute;
      top: 0;
      left: 0;
      fill: ${theme.colors.primary};
      @media (min-width: 600px) {
      }
    }
  `}
`;

export const IconAlert = styled(WarningIcon)`
  && {
    fill: #ffc600;
    @media (min-width: 600px) {
      font-size: 32px;
      margin-right: 10px;
    }
  }
`;

export const SectionDisclaimer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.colors.border};
    border-radius: 15px;
    overflow: hidden;
    > div:first-child {
      padding: 20px 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        background-image: ${({ src }) => `url(${src})`};
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        width: 50%;
        height: 150px;
        border-radius: 25px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px 10px;
      h2 {
        padding-right: 16px;
        padding-left: 16px;
        font-weight: 600;
        margin-bottom: 21px;
        font-size: 17px;
        line-height: 22px;
      }
      span {
        font-size: 14px;
      }
    }
    @media (min-width: 600px) {
      margin: 20px 60px;
      flex-direction: row;
      > div:first-child {
        padding: 0px;
        flex: 0.3;
        > div {
          width: 100%;
          height: 290px;
          border-radius: 0px;
        }
      }
      > div:nth-child(2) {
        padding: 0 55px;
        flex: 0.7;
        h2 {
          margin-bottom: 13px;
          font-size: 27px;
          line-height: 33px;
        }
        span {
          line-height: 19px;
        }
      }
    }
  `};
`;

export const SectionDisclaimerTwo = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    > div:first-child {
      color: #0e0d0d;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 26px;
    }
    > div:nth-child(2) {
      font-size: 18px;
      line-height: 26px;
      color: black;
      font-weight: 700;
    }
    button {
      margin-bottom: 30px;
    }
    @media (min-width: 600px) {
      padding: 0 6%;
      > div:nth-child(2) {
        font-size: 23px;
        line-height: 30px;
      }
    }
  `};
`;

export const SectionBoxInfo = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding: 15px 6%;
    margin: 20px 0;
    @media (min-width: 600px) {
      padding: 15px 6%;
    }
  `};
`;

export const BoxInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    span {
      background-color: #ff6d97;
      border-radius: 50%;
      padding: 4px 7px;
      margin-right: 15px;
      text-align: center;
      font-size: 13px;
      font-weight: 800;
      color: white;
    }
    div {
      font-size: 22px;
      color: ${theme.colors.primary};
      font-weight: 600;
    }
    @media (min-width: 600px) {
      align-items: center;
    }
  `};
`;

export const WrapperAlert = styled.div`
  ${() => css`
    margin-top: 35px;
    margin-bottom: 41px;
    padding: 43px;
    border: 1px solid #dde075;
    border-radius: 13px;
    background-color: #feffd9;
    @media (min-width: 600px) {
    }
  `};
`;

export const AlertTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    font-family: ${theme.font.family.montserrat};
    color: #000;
    font-size: 26px;
    @media (min-width: 600px) {
    }
  `};
`;

export const AlertMessage = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.montserrat};
    color: #0e0d0d;
    line-height: 24px;
    font-weight: 300;
    @media (min-width: 600px) {
    }
  `};
`;
