import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Select = styled.select`
  display: block;
  font-size: 16px;
  color: #444;
  line-height: 29px;
  padding: 8px 30px 8px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #d8d8d8;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  appearance: none;
  outline: none;
  background-color: rgba(216, 216, 216, 0.2);
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, blue 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
`;

const Option = styled.option``;

const Dropdown = ({ options, value, onChange }) => {
  const handleChange = event => {
    if (onChange) {
      return onChange(event.target.value);
    }
  };

  return (
    <Select onChange={handleChange} value={value}>
      <Option value="">Please Choose</Option>
      {options.map(item => (
        <Option key={item.value} value={item.value}>
          {item.text}
        </Option>
      ))}
    </Select>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Dropdown;
