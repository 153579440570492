import React, { useState, useEffect, useCallback } from 'react';
import {
  ContainerRankInformation,
  WrapperInputs,
  SetField,
  Label,
  WrapperPlacementStrategy,
  Strong,
  InfoText,
  WrapperIcon,
  IconCalendar,
  WrapperDateInput,
} from 'pages/TheFlow/UserUtils/RankInformation/styles';
import { Header, DateSelect } from 'pages/TheFlow/UserUtils/styles';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import useRankInformation from 'pages/TheFlow/UserUtils/data/useRankInformation';
import Dropdown from 'ui/Dropdown';
import Button from 'ui/Button/Default';
import get from 'lodash/get';

const blank = ' - ';
const defaultInfo = {
  qualification: blank,
  growthStrategy: blank,
  nextRankName: blank,
};

const RankInformation = () => {
  const { data, handleUpdateSelfGoal } = useRankInformation();
  const [rankOptions, setRankOptions] = useState([]);
  const [rankInfo, setRankInfo] = useState(defaultInfo);
  const [startDate, setStartDate] = useState();
  const [updateInfo, setUpdateInfo] = useState();
  const [selectedRank, setSelectedRank] = useState('');
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const saveButtonLabel = showSaveSuccess
    ? 'Rank and Advancement Goal Date Saved'
    : 'Set Rank and Save Advancement Goal Date';

  const updateItemInfo = item => {
    setSelectedRank(item.value);
    setRankInfo({
      qualification: item.qualification,
      growthStrategy: item.growthStrategy,
      nextRankName: item.nextRankName,
      nextRankQualification: item.nextRankQualification,
    });
    setUpdateInfo({
      currentRankId: item.value,
      goalRankId: item.nextRankId,
    });
  };

  const getNextRank = useCallback(
    id => {
      const selectedItem = rankOptions.filter(x => x.value === id);
      updateItemInfo(selectedItem[0]);
      setDisableSaveButton(false);
    },
    [rankOptions]
  );

  const onChangeDate = date => {
    setStartDate(date);
    setDisableSaveButton(false);
  };

  const handleSetupRank = async () => {
    await handleUpdateSelfGoal({
      ...updateInfo,
      goalDate: startDate ? startDate.toISOString() : null,
    });
    setShowSaveSuccess(true);
    setDisableSaveButton(true);
  };

  useEffect(() => {
    if (data) {
      const ranks = get(data, 'ranks', []);
      const currentRankId = get(data, 'self.rankGoal.currentRank.id', '');
      const actual = get(data, 'self.rankGoal.goalDate', false);
      const currentDateGoal = actual ? new Date(actual) : actual;

      const options = ranks.map(item => ({
        value: item.id,
        text: item.name,
        qualification: item.qualification,
        growthStrategy: item.growthStrategy,
        nextRankName: get(item, 'nextRank.name', blank),
        nextRankQualification: get(item, 'nextRank.qualification', blank),
        nextRankId: get(item, 'nextRank.id', blank),
      }));
      setRankOptions(options);
      if (currentRankId) {
        setStartDate(currentDateGoal);
        const selectedItem = options.filter(x => x.value === currentRankId);
        updateItemInfo(selectedItem[0]);
      }
    }
  }, [data]);

  return (
    <ContainerRankInformation>
      <Header>
        <span>Rank Information</span> <StarOutlinedIcon />
      </Header>
      <WrapperInputs>
        <SetField>
          <Label>Current Rank:</Label>
          <Dropdown
            value={selectedRank}
            options={rankOptions}
            onChange={getNextRank}
          />
        </SetField>
        <SetField>
          <Label>Qualify for Commissions:</Label>
          <span>{rankInfo.qualification}</span>
        </SetField>
        <SetField>
          <Label>Next Rank:</Label>
          <span>{rankInfo.nextRankName}</span>
        </SetField>
        <SetField>
          <Label>Advancement Goal Date:</Label>
          <WrapperDateInput onClick={() => setShowDatePicker(true)}>
            <DateSelect
              selected={startDate}
              onChange={onChangeDate}
              open={showDatePicker}
              onClickOutside={() => setShowDatePicker(false)}
            />
            <WrapperIcon>
              <IconCalendar />
            </WrapperIcon>
          </WrapperDateInput>
        </SetField>
        <SetField>
          <Label>Requirements:</Label>
          <span>{rankInfo.nextRankQualification}</span>
        </SetField>
        <SetField>
          <Button
            disabled={disableSaveButton}
            isDisabled={disableSaveButton}
            onClick={handleSetupRank}>
            {saveButtonLabel}
          </Button>
        </SetField>
      </WrapperInputs>
      <WrapperPlacementStrategy>
        <Strong>Placement Strategy:</Strong>
        <InfoText>{rankInfo.growthStrategy}</InfoText>
      </WrapperPlacementStrategy>
    </ContainerRankInformation>
  );
};

export default RankInformation;
