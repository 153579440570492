import gql from 'graphql-tag';

export const GET_KITS_BY_REGION = gql`
  query getKitsByRegion(
    $sort: String
    $limit: Int
    $start: Int
    $regionId: String
  ) {
    self {
      id
      oweStatus
      shadowUser {
        id
      }
      referredBy {
        id
        doterraAccount {
          id
          memberId
          backOfficeLink
        }
      }
    }
    kits(
      sort: $sort
      limit: $limit
      start: $start
      where: { region: $regionId }
    ) {
      id
      name
      retailPrice
      wholesalePrice
      url
      description
      mostPopular
      isActive
      region {
        id
        name
        suffix
        prefix
      }
      image {
        id
        url
        label
      }
    }
  }
`;
