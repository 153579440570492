import React from 'react';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import validate from 'validate.js';
import { chartjs } from './helpers';
import theme from './theme';
import { theme as styledTheme } from './theme/styledTheme';
import validators from 'helpers/validators';
import Routes from 'routes';
import Loading from 'ui/Loading';
import { useAuth0 } from 'context/auth';
import { ApolloProvider } from '@apollo/react-hooks';
import useApolloClientConfig from 'hooks/useApolloClientConfig';
import refChecker from 'helpers/refChecker';
import ModalCookie from 'ui/ModalCookie';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-datepicker/dist/react-datepicker.css';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

const App = () => {
  const { client } = useApolloClientConfig();
  const { loading, token } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  refChecker(token);

  return (
    <StyledThemeProvider theme={styledTheme}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Routes />
          <ModalCookie />
        </ApolloProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

export default App;
