import styled, { css } from 'styled-components';

export const ShareLinkBarContainer = styled.div`
  ${() => css`
    display: flex;
    span {
      font-size: 14px;
    }
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    @media (min-width: 600px) {
    }
  `};
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    width: 45px;
    height: 35px;
    border: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    border-radius: 5px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    transition: transform 0.2s ease-out;
    &:hover {
      transform: translate(0px, -2px);
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const IconImage = styled.img`
  ${({ src }) => css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    width: 35px;
    background-image: ${`url(${src})`};
    @media (min-width: 600px) {
    }
  `};
`;

export const PopoverMessange = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: 5px;
    background-color: #eef9fb;
    font-size: 12px;
    @media (min-width: 600px) {
    }
  `};
`;
