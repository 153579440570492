import React, { memo } from 'react';
import StartedSection from 'pages/CreateAccount/StartedSection';
import WelcomeSection from 'pages/CreateAccount/WelcomeSection';
import Button from 'ui/Button/Default';
import {
  Container,
  PageTitle,
  H3,
  RadioInput,
  AccountForm,
  TextInput,
} from 'pages/CreateAccount/styles';
import { useFormState } from 'react-use-form-state';

const CreateAccount = () => {
  const [formState, { label, radio, text, email, password }] = useFormState();
  const { member, positionStatus } = formState.values;

  const validatePassword = value => {
    if (!value.trim()) {
      return 'Password is required';
    }
  };

  return (
    <Container>
      <form>
        <WelcomeSection />
        <H3>Are you a member?</H3>
        <RadioInput isSelected={member === 'new'}>
          <input {...radio('member', 'new')} />
          <label {...label('member', 'new')}>I am new</label>
        </RadioInput>
        <RadioInput isSelected={member === 'alreadyMember'}>
          <input {...radio('member', 'alreadyMember')} />
          <label {...label('member', 'alreadyMember')}>
            I am Already a Member
          </label>
        </RadioInput>

        <H3>What is your member status with dōTERRA?</H3>
        <RadioInput isSelected={positionStatus === 'wholesale'}>
          <input {...radio('positionStatus', 'wholesale')} />
          <label {...label('positionStatus', 'wholesale')}>
            Wholesale Customer
          </label>
        </RadioInput>
        <RadioInput isSelected={positionStatus === 'wellness'}>
          <input {...radio('positionStatus', 'wellness')} />
          <label {...label('positionStatus', 'wellness')}>
            Wellness Advocate
          </label>
        </RadioInput>
        <StartedSection />
        <PageTitle>Create an Account</PageTitle>
        <AccountForm>
          <TextInput>
            <label {...text('firstName')}>First Name</label>
            <input {...text('firstName')} />
          </TextInput>
          <TextInput>
            <label {...text('lastName')}>Last Name</label>
            <input {...text('lastName')} />
          </TextInput>
          <TextInput>
            <label {...email('email')}>Email Address</label>
            <input {...email('email')} />
          </TextInput>
          <TextInput>
            <label {...password('password')}>Password</label>
            <span>
              Passwords must contain 6 characters, a number and a special
              character
            </span>
            <input
              {...password({
                name: 'password',
                onChange: e => console.log('password input changed!', e),
                validate: (value, values, e) => validatePassword(value),
                validateOnBlur: true,
              })}
            />
            <span>{formState.errors.password}</span>
          </TextInput>
          <TextInput>
            <label {...password('verifyPassword')}>Verify Password</label>
            <input {...password('verifyPassword')} />
          </TextInput>
          <Button>SIGN UP</Button>
        </AccountForm>
      </form>
    </Container>
  );
};

export default memo(CreateAccount);
