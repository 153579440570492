import React from 'react';
import styled, { css } from 'styled-components';
import ReactLoader from 'react-loader-spinner';

const ContainerLoader = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    @media (min-width: 600px) {
    }
  `};
`;

const Loader = () => (
  <ContainerLoader>
    <ReactLoader type="ThreeDots" color="#00d1d0" height={60} width={60} />
  </ContainerLoader>
);

export default Loader;
