import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import DoterraIdConfirmation from 'pages/TheFlow/Steps/UpdateMembershipModal/DoterraIdConfirmation';
import {
  Container,
  SectionHeader,
  SectionMemberOptions,
  WrapperInput,
} from 'pages/TheFlow/Steps/UpdateMembershipModal/styles';
import useWellAdvocate from '../../Setup/data/useWellAdvocate';

import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';

const UpdateMembershipModal = ({ isOpen, handleClose, doTerraStatus }) => {
  const { CUSTOMER, PENDING_BUILDER, BUILDER, EXPLORING } = POSITION_STATUS;
  const { MEMBER, PREMEMBER } = MEMBER_STATUS;
  const { handleUpdateMemberStatuses } = useTheFlow();
  const {
    memberId,
    handleUpdateMemberId,
    handleUpdatePositionStatus,
  } = useWellAdvocate();
  const [option, setOption] = useState('');
  const [isWellnessAdvocate, setIsWellnessAdvocate] = useState(false);

  const onChange = e => setOption(e.target.value);

  const onSubmit = () => {
    const oweStatus = option === EXPLORING ? PREMEMBER : MEMBER;
    const positionStatus = option;
    handleUpdateMemberStatuses({ positionStatus, oweStatus });
    handleClose();
  };

  const onSubmitWellAdvocate = async () => {
    const positionStatus =
      doTerraStatus === PENDING_BUILDER ? BUILDER : CUSTOMER;
    await handleUpdatePositionStatus(positionStatus);
    handleClose();
  };

  useEffect(() => {
    if (option === PENDING_BUILDER) {
      return setIsWellnessAdvocate(true);
    }
    setIsWellnessAdvocate(false);
  }, [option, PENDING_BUILDER]);

  return (
    <Modal
      openAfterSubmit={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      onSubmit={onSubmit}
      isDisableSubmit={!option}
      submitLabel={'Save'}
      hideButtons={isWellnessAdvocate}>
      <Container>
        <SectionHeader>
          <h2>Welcome! Please take a moment to update your dōTERRA status.</h2>
          <p>
            We'll use this information to make sure you stay on the right track!
          </p>
        </SectionHeader>
        <SectionMemberOptions>
          <WrapperInput>
            <input
              id="member"
              onChange={onChange}
              value={CUSTOMER}
              type="radio"
              checked={option === CUSTOMER}
            />
            <label htmlFor="member">
              <strong>Wholesale Customer</strong> - My dōTERRA account allows me
              to purchase products at a discount, but I cannot sell products or
              earn commissions.
            </label>
          </WrapperInput>

          <WrapperInput>
            <input
              id="new"
              onChange={onChange}
              value={PENDING_BUILDER}
              type="radio"
              checked={option === PENDING_BUILDER}
            />
            <label htmlFor="new">
              <strong>Wellness Advocate</strong> - I can sell dōTERRA products
              and earn commissions
            </label>
          </WrapperInput>
          {isWellnessAdvocate && (
            <DoterraIdConfirmation
              memberId={memberId}
              handleUpdateMemberId={handleUpdateMemberId}
              handleOpenCancelModal={handleClose}
              onSubmit={onSubmitWellAdvocate}
            />
          )}
        </SectionMemberOptions>
      </Container>
    </Modal>
  );
};

export default UpdateMembershipModal;

UpdateMembershipModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
