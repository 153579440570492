import styled, { css } from 'styled-components';

export const PageTitle = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 3px;
    font-size: 19px;
    line-height: 27px;
    font-weight: 600;
    color: #333;
    text-transform: capitalize;
    font-family: ${theme.font.family.montserrat};
    @media (min-width: 600px) {
      font-size: 32px;
      line-height: 36px;
    }
  `};
`;

export default PageTitle;
