import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import {
  Container,
  SectionHeader,
  SectionBody,
} from 'pages/TheFlow/Steps/CancelAccountModal/styles';

const CancelAccountModal = ({ isOpen, handleClose }) => {
  const onSubmit = () => {
    handleClose();
  };

  return (
    <Modal
      openAfterSubmit={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      onSubmit={onSubmit}
      submitLabel={'YES'}
      cancelLabel={'NO'}>
      <Container>
        <SectionHeader>
          <h2>Canceling Account</h2>
        </SectionHeader>
        <SectionBody>
          <p>
            Would you like to proceed with the cancellation and deletion of your
            account?
          </p>
        </SectionBody>
      </Container>
    </Modal>
  );
};

export default CancelAccountModal;

CancelAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
