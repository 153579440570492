import React from 'react';
import {
  Container,
  NumberedItem,
} from 'pages/CreateAccount/StartedSection/styles';
import { PageTitle, H3, Text } from 'pages/CreateAccount/styles';

const StartedSection = () => (
  <Container>
    <PageTitle>Thank you!</PageTitle>
    <H3>Let’s get started, yeah?! </H3>
    <Text>
      With Drippl, you can come back anytime you want, pick up where you left
      off, explore at your own pace, and look forward to supplemental
      information so you are empowered to decide if this is a good fit for you!
    </Text>
    <Text>What you’ll find inside:</Text>
    <NumberedItem>
      <div>
        <span>1</span>
      </div>
      <p>
        A step-by-step guide to go from curious bystander (if you’re new) or
        motivated member to a fast-tracking dōTERRA leader.
      </p>
    </NumberedItem>
    <NumberedItem>
      <div>
        <span>2</span>
      </div>
      <p>
        Video Library: A series of well-organized, modules that answer all your
        questions <br /> about dōTERRA – the company, the products, the quality,
        and their impact around the world – essential oils, and how our Drippl
        platform provides a fun and efficient approach to growing a successful
        business.
      </p>
    </NumberedItem>
    <NumberedItem>
      <div>
        <span>3</span>
      </div>
      <p>
        Webinars, Q&A sessions, how-to vids, and trainings to not only rock your
        biz, but to rock your life!
      </p>
    </NumberedItem>
    <NumberedItem>
      <div>
        <span>4</span>
      </div>
      <p>
        Informative, fun, emails providing additional education and content. (Of
        course you can opt out at any time, but our goal is to keep you hooked!)
      </p>
    </NumberedItem>
    <NumberedItem>
      <div>
        <span>5</span>
      </div>
      <p>
        The chance to demo Drippl and evaluate if this is a good fit for you.
        (NOTE: If our approach resonates with you, chances are it will resonate
        with others your refer
        <span role="img" aria-label="smile emoji">
          😊
        </span>
        )
      </p>
    </NumberedItem>
    <NumberedItem>
      <div>
        <span>6</span>
      </div>
      <p>
        Access to great social media content, social media coaching, and SO MUCH
        MORE...
      </p>
    </NumberedItem>
  </Container>
);

export default StartedSection;
