import styled, { css } from 'styled-components';

const LinkButtonDefault = styled.a`
  ${({ theme }) => css`
    color: white;
    font-size: 16px;
    background-color: ${theme.colors.primary};
    width: 100%;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: ${theme.colors.buttons.primary.buttonshover};
      transform: translate(0px, -2px);
    }
  `}
`;

export default LinkButtonDefault;
