import React from 'react';
import {
  Container,
  CenterContent,
  Wrapper,
  Left,
  Right,
  Button,
} from 'pages/Home/styles';
import ladyLaptop from 'assets/img/home/lady-laptop.jpeg';
import smileFace from 'assets/img/home/emoji-face.png';

const Home = () => (
  <Container>
    <CenterContent>
      <Wrapper>
        <Left>
          <div>
            Oops !{' '}
            <img alt="smile-face" width="80px" height="80px" src={smileFace} />
          </div>
          <span>
            Looks like we had a little hiccup. <br />
            Click on the button below to get back in.
          </span>
          <Button to="/do-doterra">Back to Drippl</Button>
        </Left>
        <Right>
          <img alt="Logo" width="518px" height="390px" src={ladyLaptop} />
        </Right>
      </Wrapper>
    </CenterContent>
  </Container>
);

export default Home;
