import React, { useState, useEffect } from 'react';
import Modal from 'ui/Modal';
import RadioSet from 'ui/RadioSet';
import useChooseLeader from 'ui/ChooseLeaderModal/data/useChooseLeader';
import {
  ChooseLeaderContainer,
  SectionHeader,
  SectionBody,
  WrapperTitle,
} from 'ui/ChooseLeaderModal/styles';
import getCookie from 'helpers/getCookie';

const ChooseLeaderModal = () => {
  const { data, oweStatus, getReferralUser, handleSetRef } = useChooseLeader();

  const [selectedLeaderId, setSelectedLeaderId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleChange = ({ target }) => setSelectedLeaderId(target.value);

  const onSubmit = async () => {
    const validId = selectedLeaderId === 'default' ? '' : selectedLeaderId;
    document.cookie = 'drippl_ref_id=;domain=.drippl.com;path=/';
    await handleSetRef(validId);
    handleClose();
  };

  useEffect(() => {
    setIsOpen(oweStatus === 'PREMEMBER' && data.length > 1);
  }, [data]); //eslint-disable-line

  useEffect(() => {
    const id = getCookie('drippl_ref_id');
    const fetchInfo = async idUser => {
      await getReferralUser({ variables: { id: idUser } });
    };
    if (id) {
      fetchInfo(id);
    }
   }, []);//eslint-disable-line

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      onSubmit={onSubmit}
      submitLabel="Save"
      isDisableSubmit={!selectedLeaderId}>
      <ChooseLeaderContainer>
        <SectionHeader>
          <WrapperTitle>
            <h2>Choose Your Leader</h2>
          </WrapperTitle>
          <p>
            Wow! You're popular! It looks like multiple VIP members have
            referred you to our site. Please select your preferred VIP member
            and the person who would make the best business partner.
          </p>
        </SectionHeader>
        <SectionBody>
          {data.map(({ id, text }) => (
            <RadioSet key={id}>
              <input
                id={id}
                onChange={handleChange}
                value={id}
                type="radio"
                checked={selectedLeaderId === id}
              />
              <label htmlFor={id}>{text}</label>
            </RadioSet>
          ))}
        </SectionBody>
      </ChooseLeaderContainer>
    </Modal>
  );
};

export default ChooseLeaderModal;
