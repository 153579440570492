const primary = '#00d1d0';

export const theme = {
  container: {
    max: '1200px',
    min: '',
    boxShadow: '',
  },
  colors: {
    primary,
    secondary: '',
    white: '#fff',
    black: '#000',
    lightGrey: 'rgb(225, 229, 232)',
    grey: '#8D9797',
    lightYellow: '',
    lightGreen: '',
    lightPrimary: '#D2F6F5',
    p: '#0e0d0d',
    body: '#0e0d0d',
    border: '#bababa',
    buttons: {
      primary: {
        bgColor: primary,
        hover: '#00b4b2',
        color: '',
        border: '',
        disabled: 'rgba(0, 209, 208, 0.25)',
        boxShadow: '',
      },
      secondary: {
        bgColor: primary,
        hoverBgColor: '',
        color: '',
        borderColor: '',
        disableBgColor: '',
        boxShadow: '',
      },
    },
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px',
  },
  font: {
    family: {
      montserrat: 'Montserrat, sans-serif',
      visby: 'Visbyroundcf, sans-serif',
    },
    size: {
      body: '14px',
      title: '18px',
      bigTitle: '32px',
      subTitle: '25px',
      s26: '26px',
      s25: '25px',
    },
    lineHeight: {
      subTitle: '29px',
    },
  },
};
