import { useQuery } from '@apollo/react-hooks';
import { GET_MEMBER_STATUS } from 'graphql/queries/getMemberStatus';

const usePersonalLink = () => {
  const { loading, error, data } = useQuery(GET_MEMBER_STATUS);

  return {
    loading,
    error,
    data,
  };
};

export default usePersonalLink;
