import styled from 'styled-components';
import MuiDrawer from '@material-ui/core/Drawer';

export const Drawer = styled(MuiDrawer)`
  && {
    height: '100%';
    @media (min-width: 600px) {
      width: 240;
    }
  }
`;
