import styled, { css } from 'styled-components';

const TextTag = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.lightPrimary};
    color: ${theme.colors.grey};
    height: 28px;
    border-radius: 14px;
    padding: 0px 14px;
    font-weight: 700;
    font-size: 11px;
    width: fit-content;
  `}
`;

export default TextTag;
