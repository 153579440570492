import React from 'react';
import Cards from 'pages/TheFlow/Steps/Cards';
import Loader from 'ui/Loader';

const Steps = ({
  data,
  loading,
  handleUpdatePositionStatus,
  handleUpdateMemberStatus,
  videosInfo,
  referralId,
  doterraId,
  isShadowUserEnabled,
  handleSetShawdowUser,
}) => {
  if (loading) return <Loader />;

  if (data) {
    const { oweStatus, positionStatus } = data.self;

    return (
      <Cards
        doTerraStatus={positionStatus}
        oweStatus={oweStatus}
        handleUpdatePositionStatus={handleUpdatePositionStatus}
        handleUpdateMemberStatus={handleUpdateMemberStatus}
        videosInfo={videosInfo}
        referralId={referralId}
        doterraId={doterraId}
        isShadowUserEnabled={isShadowUserEnabled}
        handleSetShawdowUser={handleSetShawdowUser}
      />
    );
  }
  return <Loader />;
};

export default Steps;
