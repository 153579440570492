import { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_KITS_BY_REGION } from 'graphql/queries/getKitsByRegion';
import { GET_REGION_BY_ID } from 'graphql/queries/getRegionById';
import get from 'lodash/get';
import useReactRouter from 'use-react-router';
import { GET_REGIONS_LANGUAGES } from 'graphql/queries/getLanguagesAndRegions';
import sortBy from 'lodash/sortBy';
import { GET_THE_FLOW_INFO } from 'graphql/queries/getTheFlowInfo';

const DEFAULT_LINK = 'https://www.doterra.com/US/en/pl/enrollment-kits';

const useWhosale = () => {
  const { location } = useReactRouter();

  const [setupLink, setSetupLink] = useState(DEFAULT_LINK);
  const [productList, setProductList] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [regionId, setRegionId] = useState('');
  const [description, setDescription] = useState('');

  const { data: dataUser, loading } = useQuery(GET_THE_FLOW_INFO);

  const { data } = useQuery(GET_REGIONS_LANGUAGES);

  const [handleKitUpdate, { data: dataKitsUpdate }] = useLazyQuery(
    GET_KITS_BY_REGION
  );

  const [handleRegionUpdate, { data: dataRegionUpdate }] = useLazyQuery(
    GET_REGION_BY_ID
  );

  useEffect(() => {
    if (data) {
      const regOptions = get(data, 'regions', [])
        .filter(region => region.isActive)
        .map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      setRegionsOptions(sortBy(regOptions, ['text']));
    }
  }, [data]);

  const generateProductList = (kitsPayload, locationState) => {
    const referralMemberId = get(
      kitsPayload,
      'self.referredBy.doterraAccount.memberId'
    );
    const tempMemberId = get(locationState, 'state.memberId') || '';
    const DOTERRA_ID = referralMemberId || tempMemberId;
    const link = get(
      kitsPayload,
      '.self.referredBy.doterraAccount.backOfficeLink',
      DEFAULT_LINK
    );
    const kits = get(kitsPayload, 'kits') || [];
    const shadowUser = get(kitsPayload, 'self.shadowUser', '');

    const list = kits
      .filter(kit => kit.isActive && kit.url)
      .map(product => {
        const regex = /doTERRA_ID/gi;
        let productUrl;
        if (DOTERRA_ID) {
          productUrl = product.url.replace(regex, DOTERRA_ID);
        } else {
          productUrl = DEFAULT_LINK;
        }
        const savePrice = Math.round(
          product.retailPrice - product.wholesalePrice
        );

        const re = /\*\*(.*?)\*\*/gi;
        const re2 = /\*\*/gi;
        const productDescription = get(product, 'description', '');
        const description = productDescription.replace(
          re,
          match => `<strong>${match.replace(re2, '')}</strong>`
        );

        const prefix =
          get(product, 'region.prefix', '') ||
          get(kitsPayload, 'self.region.prefix', '');
        const suffix =
          get(product, 'region.suffix', '') ||
          get(kitsPayload, 'self.region.suffix', '');
        return {
          ...product,
          description,
          productUrl,
          savePrice,
          prefix,
          suffix,
        };
      });
    setSetupLink(link);
    setShowRegions(!!shadowUser || get(dataUser, 'self.oweStatus') === 'MEMBER');
    setProductList(list.filter(kit => kit.isActive));
    const description = get(dataUser, 'self.region.kitInformation')
    setDescription(description)
  };

  useEffect(() => {
    if (dataUser) {
      const regionID = get(dataUser, 'self.region.id', '');
      const description = get(dataUser, 'self.region.kitInformation')
      setDescription(description)
      setRegionId(regionID);
      handleKitUpdate({
        variables: {
          sort: 'wholesalePrice',
          start: 0,
          regionId: regionID,
        },
      });
    }
  }, [dataUser]);

  useEffect(() => {
    if (dataKitsUpdate) {
      generateProductList(dataKitsUpdate, location);
    }
    if(dataRegionUpdate){
      setDescription(dataRegionUpdate.region.kitInformation)
    }
  }, [dataKitsUpdate,dataRegionUpdate, location]);

  const handleChangeRegion = ({ target }) => {
    setProductList([]);
    handleKitUpdate({
      variables: {
        sort: 'wholesalePrice',
        start: 0,
        regionId: target.value,
      },
    });
    handleRegionUpdate({
      variables: { id: target.value },
    })
  };

  return {
    setupLink,
    productList,
    oweStatus: get(dataUser, 'self.oweStatus', ''),
    loading,
    regionsOptions,
    showRegions,
    handleChangeRegion,
    regionId,
    description
  };
};

export default useWhosale;
