import gql from 'graphql-tag';

export const GET_SELF = gql`
  query getSelf {
    self {
      id
      username
      email
    }
  }
`;
