import styled from 'styled-components';
import { Col, Row } from 'ui/layouts';

export const Section = styled(Col)`
  p {
    padding: 0 10%;
  }
  > * {
    display: block;
    margin: 15px 0;
  }
`;

export const ImageFamily = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  width: 100%;
  height: 120px;
  background-image: ${({ src }) => `url(${src})`};
  margin-top: 10px;
  margin-bottom: 38px;
  border: solid 1px #e6e6e6;
  border-radius: 15px;
  @media (min-width: 600px) {
    width: 550px;
    height: 200px;
  }
`;

export const BoldText = styled.span`
  color: black;
  font-weight: bold;
`;

export const StepRow = styled(Row)`
  justify-content: space-between;
  margin: 30px 0 20px;
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const ChooseText = styled.p`
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0e0d0d;
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  text-align: center;
`;

export const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #d1d1d1;
  border-radius: 14px;
  width: 23%;
  padding: 25px 0px;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const SetBoxLabel = styled.div`
  height: auto;
  margin-top: auto;
  margin-bottom: 18px;
  padding: 0px 10px;
  color: #000;
  font-size: 20px;
  line-height: 21px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`;

export const ButtonPrimary = styled.button`
  margin: 25px 0px;
  color: white;
  font-size: 19px;
  background-color: #00d1cf;
  width: 90%;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  border: 0;
  height: 52px;
  align-self: center;
  font-weight: 300;
  cursor: pointer;
  &:hover {
    background-color: #00b4b2;
    transform: translate(0px, -2px);
    transition: transform 0.2s, background-color 0.2s ease-out;
  }
  @media (min-width: 600px) {
    width: 40%;
  }
`;

export const StyledLink = styled.a`
  color: #05d1cf;
  text-decoration: underline;
  font-style: italic;
`;
