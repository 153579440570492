import React from 'react';

import {
  ContainerSectionPrice,
  WrapperPriceMonth,
  WrapperPriceYear,
} from 'pages/BecomeVIP/SectionPrice/styles';

const SectionPrice = ({ handleOpenMoldal, region }) => {
  const handleActivateMonth = () => {
    handleOpenMoldal('monthly');
  };

  const handleActivateYear = () => {
    handleOpenMoldal('yearly');
  };

  return (
    <>
      {region
        ? <ContainerSectionPrice>
          <WrapperPriceMonth>
            <h2>Month-to-Month</h2>
            <p>
              First 14 Days FREE, then
        <br />
              <span>{region.prefix}{region.monthSubscription} {region.suffix}</span> Only {region.prefix}{region.monthSubscriptionDiscount} {region.suffix} Per Month!
      </p>
            <button onClick={handleActivateMonth}>Activate Now!</button>
          </WrapperPriceMonth>
          <WrapperPriceYear>
            <span>GET 2 MONTHS FREE</span>
            <h2>One Year</h2>
            <p>
              First 14 Days FREE, then
        <br />
        <span>{region.prefix}{region.yearSubscription} {region.suffix}</span> Only {region.prefix}{region.yearSubscriptionDiscount} {region.suffix}
      </p>
            <button onClick={handleActivateYear}>Activate Now!</button>
          </WrapperPriceYear>
        </ContainerSectionPrice> : "loading..."}

    </>
  );
};

export default SectionPrice;
