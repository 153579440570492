import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Modal,
  CloseButton,
  Container,
  Footer,
  ConfirmButton,
  Description,
} from './styles';

const ModalRedirect = ({ isOpen, handleClose, onConfirm, product }) => (
  <Modal
    open={isOpen}
    onClose={handleClose}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}>
    <Fade in={isOpen}>
      <Container>
        <Description>
          <p>
            You are about to leave Drippl and enter the doTERRA site. After
            you’ve purchased your kit, just return here for next steps!
          </p>
        </Description>
        <Footer>
          <CloseButton onClick={handleClose}>Cancel</CloseButton>
          <ConfirmButton
            onClick={onConfirm}
            href={product.productUrl}
            target="_blank"
            rel="noopener noreferrer">
            Ok
          </ConfirmButton>
        </Footer>
      </Container>
    </Fade>
  </Modal>
);

export default ModalRedirect;
