import styled, { css } from 'styled-components';

const RadioSet = styled.div`
  ${({ theme }) => css`
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${theme.colors.primary};
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    @media (min-width: 600px) {
    }
  `};
`;

export default RadioSet;
