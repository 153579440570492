import styled, { css } from 'styled-components';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ThumbUpIconMUI from '@material-ui/icons/ThumbUp';

export const ContainerCourses = styled.div`
  ${({ isMobileView }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 25px 0 10px;
      ${isMobileView &&
        `
        justify-content: space-around;
      `};
    }
  `};
`;

export const CourseCard = styled.div`
  ${({ theme, isLocked, isMobileView }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    margin-bottom: 29px;
    border: 1px solid #e4e4e4;
    transition: all 150ms ease;
    cursor: pointer;
    border-radius: 9px;

    & .video-error {
      display: none;
    }

    :hover {
      border-color: ${theme.colors.primary};
      box-shadow: 0 18px 14px -11px silver;
      transform: translate(0px, -5px);

      & .locker-img {
        display: ${isLocked ? 'none' : 'auto'};
      }
      & .video-error {
        display: ${isLocked ? 'flex' : 'none'};
      }
    }
    @media (min-width: 600px) {
      width: 32%;
      ${isMobileView &&
        `
        width: 360px;
      `};
    }
  `};
`;

export const WrapperImage = styled.div`
  ${() => css`
    position: relative;
    @media (min-width: 600px) {
    }
  `};
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 18px;
    background-color: white;
    border: 2px solid ${theme.colors.primary};
    border-radius: 12px;
    width: 80%;
    color: ${theme.colors.primary};
    font-weight: bold;
    text-align: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const ImageCard = styled.div`
  ${() => css`
    height: 150px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
    }
  `};
`;

export const LockedLayer = styled.div`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const ImageLocker = styled.div`
  ${() => css`
    height: 80px;
    width: 80px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInfo = styled.div`
  ${({ isLocked }) => css`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    opacity: ${isLocked ? '0.3' : '1'};
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInfoTime = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    padding: 0px 24px;
    background-color: ${theme.colors.primary};
    height: 34px;
    span {
      color: ${theme.colors.white};
      line-height: 14px;
      font-size: 14px;
      font-weight: 600;
      align-self: center;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const Description = styled.div`
  ${({ theme }) => css`
    padding: 0 24px;
    display: flex;
    font-family: ${theme.font.family.montserrat};
    color: #0e0d0d;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 15px;
    padding: 0 24px;
    font-family: ${theme.font.family.montserrat};
    color: #000;
    font-weight: 600;
    @media (min-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  `};
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    line-height: 19px;
    font-size: 15px;
    margin-bottom: 18px;
    font-family: ${theme.font.family.montserrat};
    font-weight: 500;
    color: #333;
    @media (min-width: 600px) {
      font-size: 22px;
      line-height: 20px;
    }
  `};
`;

export const WrapperTag = styled.div`
  margin: 12px 24px 0px 24px;
`;

export const IconCheck = styled(CheckCircleRoundedIcon)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.white};
      height: auto;
      margin-left: 4px;
      font-size: 18px;
    }
  `}
`;

export const ThumbUpIcon = styled(ThumbUpIconMUI)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.primary};
      height: auto;
      margin-left: 4px;
      font-size: 12px;
    }
  `}
`;
