import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 360 360" className={props.className}>
      <path
        d="M297,223.6c0.2,3.1,0.6,6.2,0.5,9.2c-0.1,6,0,12-0.8,17.9c-0.9,6.2-2.3,12.5-4.4,18.4
			c-2.5,7-5.5,13.8-9.1,20.2c-4.1,7.3-9,14.3-15.1,20.2c-5,4.9-10.1,9.8-15.5,14.4c-2.7,2.3-5.9,4-9,5.9c-4,2.5-7.9,5.2-12.2,7.2
			c-5.8,2.6-11.8,4.8-17.8,6.9c-3.5,1.2-7.1,1.8-10.7,2.6c-2.2,0.5-4.4,1-6.6,1.1c-7.9,0.4-15.9,1.1-23.7,0.7
			c-7.3-0.4-14.6-2.2-21.9-3.4c-8.4-1.4-16.1-4.8-23.6-8.6c-5.6-2.9-10.8-6.7-16.2-10.1c-8.7-5.6-15.7-13.1-22.5-20.8
			c-3.5-4-6.5-8.5-9.2-13c-3.2-5.3-6-10.8-8.6-16.5c-1.8-4.1-3-8.6-4.3-13c-1.1-3.8-2.1-7.6-2.9-11.5c-0.4-2-0.1-4.1-0.2-6.1
			c-0.2-4.7-0.5-9.3-0.5-14c0-4.1,0.1-8.1,0.7-12.1c0.6-4,1.5-8,2.7-11.8c1.6-4.9,3.5-9.8,5.4-14.6c3.3-8.7,8.5-16.4,13.5-24
			c3.9-5.9,8.6-11.2,13.1-16.6c9-10.6,18.2-21,27.2-31.5c7.9-9.1,14.5-19.1,21-29.2c2.5-3.9,4.6-8.1,6.8-12.2
			c2.3-4.4,4.6-8.9,6.6-13.5c1.5-3.4,2.5-7,3.8-10.5c1.2-3.1,2.5-6.2,3.6-9.4c0.9-2.4,1.6-4.8,2.3-7.2c1-3.4,2.1-6.8,3.1-10.2
			c1.5-4.8,3-9.6,4.3-14.5c0.5-2,1.7-2.6,3.5-2.4c1.6,0.2,3,0.6,3,2.9c0,1.6,0.9,3.1,1.4,4.7c2.4,7.8,4.8,15.6,7.2,23.4
			c1.1,3.6,2,7.4,3.4,10.9c1.6,4.2,3.5,8.3,5.3,12.4c1.7,3.8,3.2,7.7,5.2,11.4c2.8,5.3,5.8,10.5,9.1,15.5c5,7.5,10.2,14.8,15.6,22.1
			c3.7,5.1,7.7,10,11.8,14.8c7.5,8.6,15.4,16.9,22.7,25.7c5.1,6.2,9.6,13,14.1,19.8c3,4.5,5.6,9.2,8.1,14c1.6,3.1,2.5,6.6,4,9.8
			c2.9,6,3.8,12.4,5.3,18.8c0.5,1.9,0.5,3.9,0.8,5.8C297.2,223.6,297.1,223.6,297,223.6z"
      />
      <path
        d="M128.6,243.6c0.2-12.5,2.2-24.9,9.2-35.9c7.1-11.3,17.5-18,30.4-20.8c8.8-1.9,17.8-2.1,26.6-0.6
			c19.3,3.3,31.9,14.4,37.2,33.4c3,10.6,3.4,21.4,2.2,32.3c-1,9.1-3.5,17.6-8.3,25.5c-7,11.4-17.1,18.5-30.1,21.3
			c-12.5,2.8-25,2.6-37.2-1.8c-12.8-4.6-21.2-13.6-25.9-26.2C129.6,262.1,128.7,253,128.6,243.6z M214.3,242.5
			c0.1-6.8-0.8-13.9-3.1-20.7c-2.6-7.8-7.3-13.9-15-17.3c-6.4-2.8-13.1-3.1-20-2.3c-5.7,0.7-10.8,2.7-15.1,6.5
			c-8.2,7.3-10.9,17.1-11.8,27.4c-0.7,7.6-0.3,15.3,1.4,22.8c2,8.9,6,16.4,14.3,21.1c5.9,3.4,12.3,4.1,18.8,3.7
			c12.6-0.7,21.8-6.6,26.7-18.6C213.5,258.2,214.4,250.6,214.3,242.5z"
        fill="#00d1d0"
      />
      <path
        d="M181.7,149.1c9.7,0,19.4,0,29.2,0c3.7,0,4.9,0.8,5.3,4.4c0.3,3,0.2,6.1-0.1,9.1c-0.3,2.5-1.7,3.5-4.3,3.5
      c-20,0-40,0-60,0c-3,0-4.6-1.7-4.8-5.6c-0.1-2.5,0.1-5,0.4-7.5c0.3-2.3,1.5-4.1,4.8-4C162,149.1,171.9,149.1,181.7,149.1z"
        fill="#00d1d0"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
