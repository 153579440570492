import { Link as RouterLink } from 'react-router-dom';
import { Toolbar as MuiToolbar } from '@material-ui/core';
import { AppBar as MuiAppBar } from '@material-ui/core';

import styled from 'styled-components';

export const AppBar = styled(MuiAppBar)`
  && {
    box-shadow: none;
  }
`;

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  && {
    padding: 10px 20px;
  }
`;

export const Link = styled(RouterLink)``;
