import gql from 'graphql-tag';

export const GET_REFFERED_BY = gql`
  query getRefferedBy($id: ID!) {
    user(id: $id) {
      id
      fullName
      positionStatus
      oweStatus
    }
    self {
      id
      oweStatus
      positionStatus
      referredBy {
        id
        fullName
        oweStatus
      }
    }
  }
`;
