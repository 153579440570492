import gql from 'graphql-tag';

export const SELF_UPDATE_PROFILE = gql`
  mutation selfUpdateProfile(
    $firstName: String
    $lastName: String
    $phone: String
    $regionId: String
    $welcomeMessage: String
  ) {
    selfUpdateProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        regionId: $regionId
        welcomeMessage: $welcomeMessage
      }
    ) {
      id
      firstName
      lastName
      region {
        id
        name
      }
    }
  }
`;
