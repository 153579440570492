import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from 'context/auth';
import axios from 'axios';
import ReactGA from 'react-ga';

import {
  ContainerSignUpForm,
  SectionHeader,
  SectionForm,
  Divider,
  WrapperInput,
  Button,
  TextError,
} from 'pages/BecomeVIP/ModalSignUpForm/styles';
import Input from 'ui/InputText';
import Modal from 'ui/Modal';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';

import StripeButton from 'pages/BecomeVIP/StripeButton';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SignUpForm = ({ isOpen, handleClose, interval }) => {
  const { loading, token } = useAuth0();
  const { history } = useReactRouter();
  const [promoCode, setPromoCode] = useState('EARLYBIRD');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchCheckoutSession = async (billPeriod, promocode) => {
    const result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/stripe-customer/create-session?interval=${billPeriod}&coupon=${promocode}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('result', result);
    return { sessionId: result.data.id };
  };

  const onSubmit = async data => {
    // await handleUpdateMemberStatuses({
    //   positionStatus: 'BUILDER',
    //   oweStatus: 'VIP',
    // });
    // history.push('/do-doterra');
    // Call your backend to create the Checkout session.

    if (!data.billPeriod) {
      data.billPeriod = 'monthly';
    }

    ReactGA.event({
      category: 'VIP Membership',
      action: 'Activate Now',
      label: data.billPeriod,
      value: data.billPeriod === 'monthly' ? 14 : 149
    });

    // if(!data.promocode){
    //   data.promocode=''
    // }

    try {
      const { sessionId } = await fetchCheckoutSession(
        interval,
        promoCode ? promoCode : ''
      );
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      if (error) {
        setErrorMessage(error.message);
      }
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    } catch (error) {
      console.log('error response', error.response);
      setErrorMessage(error.response.data.message);
      return error.response;
    }
  };

  return (
    <Modal
      openAfterSubmit={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      hideButtons>
      <ContainerSignUpForm onSubmit={onSubmit}>
        <SectionHeader>
          <h1>Promotion Code</h1>
          <h3>
            On occasion we offer special promotions to current leaders and
            teams. If you received a promotion code from your leader, enter it
            below. If not, we recommend that you DO NOT update the promotion
            code below to ensure you get the best price.
          </h3>
        </SectionHeader>
        <SectionForm>
          {errorMessage && <TextError>{errorMessage}</TextError>}
          <WrapperInput>
            <label>Promo Code</label>
            <div>
              <Input
                name="promocode"
                value={promoCode}
                onChange={e => setPromoCode(e.target.value)}
              />
            </div>
          </WrapperInput>
          <Divider />
          <Button type="button" onClick={onSubmit}>
            {promoCode ? 'Apply' : 'Continue'}
          </Button>
        </SectionForm>
      </ContainerSignUpForm>
    </Modal>
  );
};

SignUpForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SignUpForm;
