import gql from 'graphql-tag';

export const SELF_MEMBER_SETUP = gql`
  mutation selfMemberSetup(
    $oweStatus: String!
    $regionId: String! # $languageId: String! // THIS FEATURE WILL RETURN IN FUTURE
  ) {
    selfMemberSetup(
      input: {
        oweStatus: $oweStatus
        regionId: $regionId
        # languageId: $languageId
      }
    ) {
      id
      oweStatus
      region {
        id
      }
    }
  }
`;
