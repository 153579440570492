import styled, { css } from 'styled-components';

export const PageSubtitle = styled.h3`
  ${({ theme }) => css`
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: ${theme.colors.primary};
    font-family: ${theme.font.family.montserrat};
    @media (min-width: 600px) {
      font-size: 19px;
    }
  `}
`;

export default PageSubtitle;
