import React, { useCallback, useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCropImage';
import { useAuth0 } from 'context/auth';
import Modal from 'ui/Modal';
import uuid from 'uuid/v1';
import axios from 'axios';
import Loading from 'ui/Loader';

import {
  Container,
  Left,
  Right,
  WrapperCrop,
  WrapperSlider,
  Button,
  SectionHeader,
} from 'ui/ModalUploadAvatar/styles';

const ModalUploadAvatar = ({
  isOpen,
  handleClose,
  profilePicture,
  setProfileImage,
}) => {
  const { token } = useAuth0();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imgSrc, setImageSrc] = useState(profilePicture);
  const [isDisabled, setIsDisabled] = useState(false);

  const readFile = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    }
  };

  const handleSaveImage = useCallback(async () => {
    try {
      setIsDisabled(true);
      const canvas = await getCroppedImg(imgSrc, croppedAreaPixels);

      if (canvas.toBlob) {
        canvas.toBlob(function(blob) {
          var data = new FormData();
          data.append('image', blob, `${uuid()}-avatar.jpg`);

          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          };
          const newImageUrl = URL.createObjectURL(blob);
          axios
            .post(
              // 'https://staging.api.drippl.com/users-permissions/upload-avatar',
              `${process.env.REACT_APP_API_HOST}/users-permissions/upload-avatar`,
              data,
              config
            )
            .then(async () => {
              setIsDisabled(false);
              setProfileImage(newImageUrl);
              handleClose();
            })
            .catch(err => {
              console.log('error uploading', err);
              setIsDisabled(false);
            });
        }, 'image/jpeg');
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, handleClose, imgSrc, setProfileImage, token]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsNew) => {
    setCroppedAreaPixels(croppedAreaPixelsNew);
  }, []);

  useEffect(() => {
    if (profilePicture) {
      setImageSrc(profilePicture);
    }
  }, [profilePicture]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} hideButtons>
      <SectionHeader>
        <h1>Upload Picture</h1>
        <span onClick={handleClose}>X</span>
      </SectionHeader>
      <Container>
        <Left>
          <WrapperCrop>
            <Cropper
              image={imgSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
            />
          </WrapperCrop>
          <WrapperSlider>
            <Slider
              defaultValue={1}
              value={zoom}
              onChange={(e, zoomLevel) => setZoom(zoomLevel)}
              step={0.5}
              marks
              min={1}
              max={3}
              valueLabelDisplay="auto"
            />
          </WrapperSlider>
        </Left>
        {isDisabled ? (
          <Right>
            <span style={{ marginTop: 20 }}>Uploading ...</span>
            <Loading />
          </Right>
        ) : (
          <Right>
            <input type="file" onChange={onFileChange} />
            <Button
              disabled={isDisabled}
              isDisabled={isDisabled}
              onClick={handleSaveImage}>
              Save Image
            </Button>
          </Right>
        )}
      </Container>
    </Modal>
  );
};

export default ModalUploadAvatar;
