import { useLayoutEffect, useState } from 'react';

const MIN_WINDOW_SIZE = 1370;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const [isMobileView, setIsMobileView] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobileView(window.innerWidth < MIN_WINDOW_SIZE);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { windowSize: size, isMobileView };
}

export { useWindowSize };
