import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row, PageContent } from 'ui/layouts';
import {
  ProductBox,
  Product,
  ProductLabel,
  ProductButton,
  ProductImage,
  ProductDescription,
  PriceBox,
  Price,
  PriceDescount,
  ProductPriceDescount,
  Text,
  SeeDetails,
  WarningBox,
  InfoReasons,
  WrapperInput,
  CardIcon,
} from 'pages/Wholesale/styles';
import Select, { Option } from 'ui/SelectInput';
import PageTitle from 'ui/Text/PageTitle';
import PageSubtitle from 'ui/Text/PageSubtitle';
import H1 from 'ui/Text/H1';
import H3 from 'ui/Text/H3';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';
import useWholesale from 'pages/Wholesale/data/useWhosale';
import ModalProductDetails from 'pages/Wholesale/ModalProductDetails';
import useReactRouter from 'use-react-router';
import ReactPlayer from 'react-player';
import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import { useAuth0 } from 'context/auth';
import ModalRedirect from './ModalRedirect';
import { useCustomMediaQuery } from '../../hooks/useCustomMediaQuery/useCustomMediaQuery';

const Wholesale = () => {
  const { history } = useReactRouter();
  const { http } = useAuth0();
  const { isShadowUserEnabled, handleUpdateMemberStatuses } = useTheFlow();
  const {
    productList,
    oweStatus,
    loading,
    regionsOptions,
    showRegions,
    handleChangeRegion,
    regionId,
    description
  } = useWholesale();
  const isRowBased = useCustomMediaQuery('(min-width: 500px)');
  const flexDirection = isRowBased ? 'row' : 'column';

  const [isOpen, setIsOpen] = useState(false);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);

  const [product, setProduct] = useState({});
  const [buyNowProduct, setBuyNowProduct] = useState({});

  const handleCloseModal = () => setIsOpen(false);

  const handleUpdateOwePositionStatus = async () => {
    const newStatus =
      MEMBER_STATUS.PREMEMBER === oweStatus
        ? {
            oweStatus: MEMBER_STATUS.PREMEMBER,
            positionStatus: POSITION_STATUS.PENDING_CUSTOMER,
          }
        : {
            oweStatus: MEMBER_STATUS.MEMBER,
            positionStatus: POSITION_STATUS.CUSTOMER,
          };
    await handleUpdateMemberStatuses(newStatus);
  };
  const handleShadowUser = async () => {
    await handleUpdateMemberStatuses({
      oweStatus: MEMBER_STATUS.MEMBER,
      positionStatus: POSITION_STATUS.CUSTOMER,
    });
    history.push('/do-doterra');
  };

  const handleOpenDetails = productDetails => {
    setProduct(productDetails);
    setIsOpen(true);
  };

  const handleBuyNow = async itemProduct => {
    //send email
    setBuyNowProduct(itemProduct);
    setIsRedirectModalOpen(true);
  };

  const onRedirectModalConfirm = async () => {
    try {
      console.log('buyNowProduct.title', buyNowProduct.title);
      // http.post(`/send-mail`, {
      //   input: {
      //     mailType: 'kitClicked',
      //     mailParams: {
      //       kitName: buyNowProduct.title,
      //     },
      //   },
      // });
    } catch (e) {
      console.log('failed send email on buy product click');
    }
    await handleUpdateOwePositionStatus();
    history.push('/do-doterra');
    setIsRedirectModalOpen(false);
  };

  const handleAlreadyMember = async () => {
    if (isShadowUserEnabled) {
      handleShadowUser();
      return;
    }

    await handleUpdateOwePositionStatus();
    history.push('/do-doterra');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Fragment>
      <Col>
        <PageTitle>Pick Your Starter Kit</PageTitle>
        <PageSubtitle>
          Experience the potency, purity, and power of dōTERRA!
        </PageSubtitle>
      </Col>
      <PageContent>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: flexDirection,
            marginBottom: 20,
          }}>
          <div style={{ flex: 1 }}>
            {showRegions ? (
              <WrapperInput>
                <label>Select Region</label>
                <div>
                  <Select
                    defaultValue={regionId}
                    name="regionId"
                    onChange={handleChangeRegion}>
                    {regionsOptions.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.text}
                      </Option>
                    ))}
                  </Select>
                </div>
              </WrapperInput>
            ) : null}
          </div>
          <div style={{ flex: 1 }}>
            <GoBackToTheFlowPage />
          </div>
        </div>

        <Col>
          <Row isCentralized>
            <H1 primary isUppercase>
              Getting Started is Easy
            </H1>
          </Row>
          {/* <Row isCentralized>
            <WrapperVideo>
              <ReactPlayer
                height="100%"
                width="100%"
                url="https://vimeo.com/428335776"
              />
            </WrapperVideo>
          </Row> */}

          <Text center>
            {loading ? (<div>loading</div>) : (<> {description} </> )}
          </Text>
          <H3 center primary>
            After you’ve purchased your kit, return to Drippl and we’ll help
            you…
          </H3>
          <InfoReasons>
            <Text fontSize={18}>
              <strong>1 </strong>- Get started with your products
            </Text>
            <Text fontSize={18}>
              <strong>2 </strong>- Teach you how to claim free products and get
              the most bang for your buck!
            </Text>
          </InfoReasons>
        </Col>

        <ProductBox>
          {loading ? (
            <div>loading</div>
          ) : (
            productList.map(itemProduct => {
              const {
                id,
                mostPopular,
                name,
                retailPrice,
                wholesalePrice,
                savePrice,
                image,
                prefix,
                suffix,
              } = itemProduct;
              return (
                <Product key={id}>
                  {mostPopular && <ProductLabel>most popular</ProductLabel>}
                  <ProductImage src={image?.url} hasMarginTop={!mostPopular} />
                  <ProductDescription>{name}</ProductDescription>
                  <PriceBox>
                    {retailPrice ? (
                      <Price>
                        {prefix}
                        {retailPrice}
                      </Price>
                    ) : null}
                    <PriceDescount>
                      {prefix}
                      {wholesalePrice}
                      <span> {suffix}</span>
                    </PriceDescount>
                  </PriceBox>
                  {retailPrice ? (
                    <ProductPriceDescount>
                      Save {prefix}
                      {savePrice}!
                    </ProductPriceDescount>
                  ) : null}
                  <SeeDetails onClick={() => handleOpenDetails(itemProduct)}>
                    Peek Inside
                  </SeeDetails>
                  <ProductButton onClick={() => handleBuyNow(itemProduct)}>
                    Buy Now
                  </ProductButton>
                </Product>
              );
            })
          )}
        </ProductBox>
        <WarningBox>
          <CardIcon />
          <div>Already have a dōTERRA membership?</div>
          <button onClick={handleAlreadyMember}>CLICK HERE</button>
        </WarningBox>
      </PageContent>
      <ModalProductDetails
        isOpen={isOpen}
        handleClose={handleCloseModal}
        product={product}
      />
      <ModalRedirect
        isOpen={isRedirectModalOpen}
        handleClose={() => setIsRedirectModalOpen(false)}
        onConfirm={onRedirectModalConfirm}
        product={buyNowProduct}
      />
    </Fragment>
  );
};

export default Wholesale;
