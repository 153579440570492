import styled from 'styled-components';

const Text = styled.p`
  margin-bottom: 19px;
  color: #0e0d0d;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

export default Text;
