import styled, { css } from 'styled-components';

const PageContent = styled.div`
  ${({ theme }) => css`
    padding: 10px 10px;
    border: 0px solid #000;
    border-radius: 14px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px -1px 0px 0px rgb(225, 229, 232),
      0px 1px 0px 0px rgb(225, 229, 232);
    margin: 10px 0;
    @media (min-width: 600px) {
      padding: 36px 43px;
      max-width: ${theme.container.max};
      margin: 20px 0 30px;
    }
  `}
`;

export default PageContent;
