import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import useReactRouter from 'use-react-router';
import axios from 'axios';
const Auth0Context = React.createContext();

const initOptions = {
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  redirect_uri: window.location.origin,
};

const Auth0Provider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const { history } = useReactRouter();

  const onRedirectCallback = appState => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );

    if (appState) {
      history.push(appState.targetUrl);
    }
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        console.log('inside code=');
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
        // history.push(appState.targetUrl);
      }
      const isAuthenticatedAuth = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticatedAuth);
      if (isAuthenticatedAuth) {
        const tokenAuth = await auth0FromHook.getTokenSilently();
        setToken(tokenAuth);
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    headers: { Authorization: `Bearer ${token}` },
  });

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    // const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    // setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        token,
        http,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...params) =>
          auth0Client.getIdTokenClaims(...params),
        loginWithRedirect: (...params) =>
          auth0Client.loginWithRedirect(...params),
        getTokenSilently: (...params) =>
          auth0Client.getTokenSilently(...params),
        getTokenWithPopup: (...params) =>
          auth0Client.getTokenWithPopup(...params),
        logout: (...params) => auth0Client.logout(...params),
      }}
      {...props}
    />
  );
};

function useAuth0() {
  const context = useContext(Auth0Context);
  if (context === undefined) {
    throw new Error('useAuth0Provider must be used within a Auth0Context');
  }
  return context;
}

export { Auth0Provider, useAuth0 };
