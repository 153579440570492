import styled, { css } from 'styled-components';

export const ChooseLeaderContainer = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    @media (min-width: 600px) {
      width: 700px;
      overflow-y: none;
    }
  `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 30px;
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: left;
    padding: 0 20px;
    line-height: 22px;
    text-align: center;
    h2 {
      text-align: center;
      font-size: 26px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
      line-height: 32px;
    }
    @media (min-width: 600px) {
      padding: 0 40px;
      h2 {
        line-height: 22px;
        font-size: 32px;
        letter-spacing: 0.1px;
      }
    }
  `};
`;

export const SectionBody = styled.div`
  ${() => css`
    font-size: 18px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    line-height: 32px;
    @media (min-width: 600px) {
      padding: 40px 40px;
    }
  `};
`;

export const Image = styled.div`
  overflow: hidden;
  border: 5px solid #fff;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  margin-left: 20px;
`;
