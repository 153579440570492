import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 293.7 293.7" className={props.className}>
      <path
        d="M49.3,116.3c0-3,0-6,0-8.9c2.1-7.1,7-12,12.2-16.8c24.2-23,48.2-46,72.4-69c10.3-9.8,24.2-9.7,34.6,0.2
			c8.6,8.2,17.2,16.4,25.8,24.6c17.2,16.4,34.6,32.7,51.7,49.3c12.8,12.4,7.7,32.8-9.4,37.9c-9.3,2.8-17.6,0.6-24.7-6.1
			c-11.2-10.7-22.4-21.4-33.6-32.1c-0.9-0.8-1.8-1.6-3.2-2.9c0,2,0,3.3,0,4.6c0,52,0,103.9,0,155.9c0,10.8-6.3,19.3-16.6,22.5
			c-0.9,0.3-1.8,0.6-2.8,1c-3,0-5.9,0-8.9,0c-0.3-0.2-0.6-0.5-1-0.6c-12.7-3.8-18.4-11.3-18.4-24.4c0-51.4,0-102.8,0-154.3
			c0-1.4,0-2.8,0-4.8c-1.5,1.4-2.3,2.1-3.1,2.9c-11.2,10.7-22.5,21.3-33.5,32.1c-14.2,13.8-36.8,6.7-40.8-10.1
			C49.8,116.8,49.5,116.6,49.3,116.3z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
