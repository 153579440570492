import styled, { css } from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import ButtonClean from 'ui/Button/Clean';
import LinkButtonDefault from 'ui/Button/LinkButtonDefault';

export const Modal = styled(MuiModal)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: none;
    margin: 0 20px;
  }
  @media (min-width: 600px) {
    && {
    }
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    max-height: 100vh;
    padding: 30px 20px;
    @media (min-width: 600px) {
      width: 600px;
      overflow-y: auto;
      max-height: 90vh;
      padding: 30px 20px;
    }
  `}
`;

export const Description = styled.div`
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

export const CloseButton = styled(ButtonClean)`
  width: 45%;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  @media (min-width: 600px) {
  }
`;

export const ConfirmButton = styled(LinkButtonDefault)`
  width: 45%;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  line-height: 52px;
  @media (min-width: 600px) {
  }
`;
