import { useEffect, useState } from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_REGIONS_LANGUAGES } from 'graphql/queries/getLanguagesAndRegions';
import { SELF_MEMBER_SETUP } from 'graphql/mutations/selfMemberSetup';
import { GET_THE_FLOW_INFO } from 'graphql/queries/getTheFlowInfo';
import { UPDATE_DOTTERA_ACCOUNT } from 'graphql/mutations/updateDoterrAccount';
import { UPDATE_POSITION_STATUS } from 'graphql/mutations/updatePositionStatus';
import { SET_REFERRED_BY } from 'graphql/mutations/setReferredBy';

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const useSetup = () => {
  const [updateMemberSetup] = useMutation(SELF_MEMBER_SETUP);
  const [updateBackOfficeLink] = useMutation(UPDATE_DOTTERA_ACCOUNT);
  const [updateUser] = useMutation(UPDATE_POSITION_STATUS);
  const [handleSetRefMutation] = useMutation(SET_REFERRED_BY);

  const { loading, error, data } = useQuery(GET_REGIONS_LANGUAGES);

  const [regionsOptions, setRegionsOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [usaRegion, setUsaRegion] = useState('us');
  const [enLanguage, setEnLanguage] = useState('en');

  const handleSubmitSetup = async formData => {
    const { oweStatus, regionId, languageId, memberId, ownerId } = formData;
    await updateMemberSetup({
      variables: { oweStatus, regionId, languageId },
    });

    if (oweStatus === 'MEMBER') {
      await handleSetRefMutation({ variables: { id: ownerId } });
    }

    if (memberId) {
      await updateBackOfficeLink({
        variables: { memberId, backOfficeLink: '' },
      });
    }
    await updateUser({
      variables: { status: 'EXPLORING' },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  useEffect(() => {
    if (data) {
      const targetRegion = get(data, 'regions', []).find(
        item => item.name.toLowerCase() === 'united states'
      );
      const targetLanguage = get(data, 'languages', []).find(
        item => item.name.toLowerCase() === 'english'
      );

      const regOptions = get(data, 'regions', [])
        .filter(region => region.isActive && region.id !== targetRegion)
        .map(({ id, name }) => ({
          value: id,
          text: name,
        }));

      const lanOptions = get(data, 'languages', [])
        .map(({ id, name }) => ({
          value: id,
          text: name,
        }))
        .filter(item => item.value !== targetLanguage);

      setUsaRegion(targetRegion.id);
      setEnLanguage(targetLanguage.id);

      setRegionsOptions(sortBy(regOptions, ['text']));
      setLanguageOptions(sortBy(lanOptions, ['text']));
    }
  }, [data]);

  return {
    loading,
    error,
    regionsOptions,
    languageOptions,
    usaRegion,
    enLanguage,
    handleSubmitSetup,
  };
};

export default useSetup;
