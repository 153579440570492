import styled, { css } from 'styled-components';
import { PageContent } from 'ui/layouts';
import ButtonDefault from 'ui/Button/Default';

export const ContainerProfile = styled(PageContent)``;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 9px;
    padding-right: 0px;
    font-size: 36px;
    line-height: 36px;
    font-family: ${theme.font.family.montserrat};
    color: ${theme.colors.primary};
    font-weight: 600;
    @media (min-width: 600px) {
      margin-top: 0px;
      margin-bottom: 20px;
      padding-right: 24px;
      font-size: 60px;
      line-height: 60px;
    }
  `};
`;

export const SectionForm = styled.form`
  ${({ theme }) => css`
    margin-bottom: 60px;
    border: none;
    border-radius: 8px;
    width: 100%;
    position: relative;
    padding-bottom: 2.5rem;
    h2 {
      color: black;
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      padding-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.border};
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
      margin-bottom: 60px;
      width: 600px;
      h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > label {
      flex: 1;
      padding-bottom: 10px;
    }
    @media (min-width: 600px) {
      display: flex;
      flex-direction: row;
      > label {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        padding-right: 20px;
      }
      div {
        display: flex;
        flex-direction: column;
        flex: 2.1;
        input[type='text'],
        select {
          width: 100%;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  ${() => css`
    padding: 10px 0;
    align-self: flex-end;
    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    bottom: 0;
    @media (min-width: 600px) {
      font-size: 16px;
      width: 20%;
    }
  `};
`;

export const CancelSub = styled.div`
  ${() => css`
    color: #c22620;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    font-weight: 600;
    @media (min-width: 600px) {
    }
  `};
`;

export const ManageSubscriptionButton = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: ${theme.colors.primary};
    text-align: center;
    color: white;
    font-weight: bold;
    @media (min-width: 600px) {
      width: 50%;
    }
  `};
`;

