import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MEMBER_STATUS, POSITION_STATUS } from 'config/constants';
import Select, { Option } from 'ui/SelectInput';
import {
  Container,
  Title,
  ButtonSubmit,
  WrappeInputRadio,
  WrapperInput,
  WrapperAlert,
  AlertTitle,
  AlertMessage,
  WrapperCheckbox,
  WrapperBottom,
  MemberOptionWrapper,
  WrappeInputRadioMember,
  ContainerInput,
} from './styles';
import useSetup from 'pages/TheFlow/data/useSetup';
import CancelAccountModal from 'pages/TheFlow/Steps/CancelAccountModal';
import Megafone from 'assets/img/setup/megaphone.png';
import PdfFileSource from 'assets/files/terms-conditions.pdf';
import useWellAdvocate from './data/useWellAdvocate';
import DoterraIdConfirmation from './DoterraIdConfirmation';
import WelcomeHeader from 'ui/WelcomeHeader';
import { Info } from './Info';

const Setup = ({ ownerId, welcomeMessage, profilePicture }) => {
  const { MEMBER, PREMEMBER } = MEMBER_STATUS;
  const { CUSTOMER, BUILDER } = POSITION_STATUS;

  const { memberId, handleUpdateMemberId } = useWellAdvocate();

  const {
    regionsOptions,
    usaRegion,
    enLanguage,
    handleSubmitSetup,
  } = useSetup();

  const { handleSubmit, register, watch, setValue, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showHeadesUp, setShowsHeadsUp] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isWellnessAdvocate, setIsWellnessAdvocate] = useState(false);
  const { regionId, languageId } = watch(['regionId', 'languageId']);

  const handleOpenCancelModal = () => setShowModal(true);

  const handleCloseCancelModal = () => setShowModal(false);

  const onSubmit = async data => {
    if (data) {
      const payload = { ...data, memberId, ownerId };
      setIsLoading(true);
      await handleSubmitSetup(payload);
      setIsLoading(false);
    }
  };

  const onChange = e => {
    setValue('status', e.target.value);
    if (e.target.value === MEMBER) {
      return setIsMember(true);
    }
    setIsMember(false);
    setIsWellnessAdvocate(false);
    setValue('positionStatus', null);
  };

  const onChangeMember = e => {
    setValue('positionStatus', e.target.value);
    if (e.target.value === BUILDER) {
      return setIsWellnessAdvocate(true);
    }
    setIsWellnessAdvocate(false);
  };

  const onAccepteTerms = () => setIsTermsAccepted(value => !value);

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    if (
      (regionId &&
        languageId &&
        languageId !== enLanguage &&
        regionId !== usaRegion) ||
      inputErrors.includes('regionId') ||
      inputErrors.includes('languageId')
    ) {
      setShowsHeadsUp(true);
    }

    if (
      regionId &&
      languageId &&
      languageId === enLanguage &&
      regionId === usaRegion
    ) {
      setShowsHeadsUp(false);
    }
  }, [enLanguage, inputErrors, languageId, regionId, usaRegion]);

  return (
    <>
      <WelcomeHeader />
      <Container>
        <Info profilePicture={profilePicture} welcomeMessage={welcomeMessage} />
        <ContainerInput>
          <Title>Do you already have a dōTERRA membership?</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <WrappeInputRadio hasError={inputErrors.includes('oweStatus')}>
              <input
                name="oweStatus"
                id="opt1"
                ref={register({ required: true })}
                type="radio"
                value={PREMEMBER}
                onChange={onChange}
              />
              <label htmlFor="opt1">
                <strong>No</strong>
              </label>
            </WrappeInputRadio>
            <WrappeInputRadio
              hasError={inputErrors.includes('oweStatus')}
              isLastElement>
              <input
                name="oweStatus"
                id="opt2"
                ref={register({ required: true })}
                type="radio"
                value={MEMBER}
                onChange={onChange}
              />
              <label htmlFor="opt2">
                <strong>Yes</strong>
              </label>
            </WrappeInputRadio>
            {isMember && (
              <MemberOptionWrapper>
                <WrappeInputRadioMember>
                  <input
                    name="positionStatus"
                    id="position1"
                    type="radio"
                    ref={register()}
                    value={CUSTOMER}
                    onChange={onChangeMember}
                  />
                  <label htmlFor="position1">
                    <strong>Wholesale Customer</strong> - My dōTERRA account
                    allows me to purchase products at a discount, but I cannot
                    sell products or earn commissions.
                  </label>
                </WrappeInputRadioMember>
                <WrappeInputRadioMember>
                  <input
                    name="positionStatus"
                    id="position2"
                    type="radio"
                    ref={register()}
                    value={BUILDER}
                    onChange={onChangeMember}
                  />
                  <label htmlFor="position2">
                    <strong>Wellness Advocate</strong> - I can sell dōTERRA
                    products and earn commissions
                  </label>
                </WrappeInputRadioMember>
              </MemberOptionWrapper>
            )}
            <WrapperInput>
              <label>Region</label>
              <div>
                <Select
                  name="regionId"
                  ref={register({
                    required: true,
                  })}
                  hasError={inputErrors.includes('regionId')}>
                  <Option value={usaRegion}>United States</Option>
                  {regionsOptions.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
              </div>
            </WrapperInput>
            {/* <WrapperInput>
            <label>Preferred Language</label>
            <div>
              <Select
                name="languageId"
                ref={register({
                  required: true,
                  validate: value => value === enLanguage,
                })}
                hasError={inputErrors.includes('languageId')}>
                <Option value={enLanguage}>English</Option>
                {languageOptions.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </div>
          </WrapperInput> */}
            {isWellnessAdvocate && (
              <DoterraIdConfirmation
                memberId={memberId}
                handleUpdateMemberId={handleUpdateMemberId}
              />
            )}

            <WrapperCheckbox>
              <input
                name="termsAndConditions"
                type="checkbox"
                value={isTermsAccepted}
                onChange={onAccepteTerms}
              />
              <label>
                I accept the following Terms of Use and Privacy Policy{' '}
                <a
                  href={PdfFileSource}
                  target="_blank"
                  rel="noopener noreferrer">
                  here.
                </a>
              </label>
            </WrapperCheckbox>
            {!isMember && (
              <WrapperBottom>
                <ButtonSubmit
                  type="submit"
                  disabled={isLoading || !isTermsAccepted}
                  isDisabled={isLoading || !isTermsAccepted}>
                  Confirm
                </ButtonSubmit>
                <div onClick={handleOpenCancelModal}>Cancel</div>
              </WrapperBottom>
            )}

            {isMember && (
              <WrapperBottom>
                {isWellnessAdvocate ? (
                  <ButtonSubmit
                    type="submit"
                    disabled={isLoading || !isTermsAccepted || !memberId}
                    isDisabled={isLoading || !isTermsAccepted || !memberId}>
                    Confirm Membership
                  </ButtonSubmit>
                ) : (
                  <ButtonSubmit
                    type="submit"
                    disabled={isLoading || !isTermsAccepted}
                    isDisabled={isLoading || !isTermsAccepted}>
                    Confirm Membership
                  </ButtonSubmit>
                )}
                <div onClick={handleOpenCancelModal}>Cancel</div>
              </WrapperBottom>
            )}
          </form>

          {showHeadesUp && (
            <WrapperAlert>
              <AlertTitle>
                Heads Up!{' '}
                <img alt="Logo" width="66px" height="46px" src={Megafone} />
              </AlertTitle>
              <AlertMessage>
                Because of the complexities of automation, the current version
                of Drippl supports US business-builders only. But don’t worry!
                We are refining and optimizing our system to work for an
                international audience soon!
              </AlertMessage>
            </WrapperAlert>
          )}
        </ContainerInput>
        <CancelAccountModal
          isOpen={showModal}
          handleClose={handleCloseCancelModal}
        />
      </Container>
    </>
  );
};

export default Setup;
