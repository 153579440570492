import gql from 'graphql-tag';

export const GET_SELF_STATS = gql`
  query getSelfStats {
    selfGetStats {
      referralLinkClicks
      contactsInFunnel
      wholeSaleCustomers
      wellnessAdvocates
    }
  }
`;
