import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { SET_VIDEO_COMPLETED } from 'graphql/mutations/updateCompletVideo';
import { GET_MODULE } from 'graphql/queries/getModule';
import get from 'lodash/get';

const useVideoView = ({ deepLinklessonId = '' }) => {
  const [fetchModule, { loading, error, data }] = useLazyQuery(GET_MODULE);
  const [handleSetRefMutation] = useMutation(SET_VIDEO_COMPLETED);

  const [currentLesson, setCurrentLesson] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const [labCourses, setLabCourses] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);

  const updateVideoStatus = ({ id, moduleId, isFinished }) => {
    handleSetRefMutation({
      variables: { videoId: id, isFinished },
      refetchQueries: () => [
        {
          query: GET_MODULE,
          variables: { id: moduleId },
        },
      ],
      onComplete: () => {
        setAutoScroll(true);
      },
    });
  };

  const markVideoCompleted = ({ videoId, isFinished }) => {
    setAutoScroll(false);
    updateVideoStatus({
      id: videoId,
      moduleId: data.selfLabModule.id,
      isFinished: isFinished,
    });
  };
  const handleNextLesson = lesson => {
    setIsSelected(prevId => (autoScroll ? lesson.id : prevId));
    setCurrentLesson(prevLesson =>
      autoScroll
        ? lesson
        : {
            ...prevLesson,
            isFinished: !prevLesson.isFinished,
          }
    );
  };

  const generateVideoList = () => {
    const rawList = get(data, 'selfLabModule.labCourses', []);
    const listLessons = rawList.map(course => {
      const labLessons = course.labLessons.map(lesson => {
        const isFinished = get(lesson, 'video.userVideos[0].isFinished', false);
        const videoProgress = get(lesson, 'video.userVideos[0].progress', 0);
        const videoId = get(lesson, 'video.id', '');
        const duration = Math.round(lesson.video.duration / 60);
        return {
          ...lesson,
          isFinished,
          videoId,
          duration,
          videoProgress,
          videoDurationSecs: lesson.video.duration,
        };
      });
      return { ...course, labLessons };
    });

    const list = listLessons.reduce(
      (acc, curr) => [...acc, ...curr.labLessons],
      []
    );

    if (!currentLesson && deepLinklessonId && list.length) {
      const deepLinkLesson = list.find(({ id }) => deepLinklessonId === id);
      setCurrentLesson(deepLinkLesson);
      setIsSelected(deepLinkLesson.id);
      setLabCourses(listLessons);
      return;
    }

    const nextLessson = list.find(({ isFinished }) => !isFinished);
    if (nextLessson) {
      handleNextLesson(nextLessson);
    }
    if (!nextLessson && list.length) {
      handleNextLesson(list[0]);
    }
    setLabCourses(listLessons);
  };

  useEffect(() => {
    generateVideoList();
  }, [data]); //eslint-disable-line

  return {
    loading,
    error,
    data: get(data, 'selfLabModule'),
    user: get(data, 'self'),
    fetchModule,
    labCourses,
    currentLesson,
    setCurrentLesson,
    isSelected,
    setIsSelected,
    markVideoCompleted,
    autoScroll,
  };
};

export { useVideoView };
