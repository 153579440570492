import { useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_PROFILE_INFO } from 'graphql/queries/getProfileInfo';
import { SELF_UPDATE_PROFILE } from 'graphql/mutations/updateProfile';
import { UPDATE_DOTTERA_ACCOUNT } from 'graphql/mutations/updateDoterrAccount';

import get from 'lodash/get';

const useProfile = () => {
  const [updateMemberProfile] = useMutation(SELF_UPDATE_PROFILE);
  const [updateBackOfficeLink] = useMutation(UPDATE_DOTTERA_ACCOUNT);
  const [fetchLazyProfile, { data, error, loading }] = useLazyQuery(
    GET_PROFILE_INFO,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const fetchProfile = () => {
    fetchLazyProfile();
  };

  const handleSubmitForm = async dataForm => {
    await updateMemberProfile({
      variables: dataForm,
      refetchQueries: () => [
        {
          query: GET_PROFILE_INFO,
        },
      ],
    });
  };

  const handleUpdateMemberId = async dataForm => {
    await updateBackOfficeLink({
      variables: dataForm,
      refetchQueries: () => [
        {
          query: GET_PROFILE_INFO,
        },
      ],
    });
  };

  useEffect(() => {
    fetchLazyProfile();
  }, []); // eslint-disable-line

  return {
    loading,
    error,
    data: get(data, 'self') || {},
    positionStatus: get(data, 'self.positionStatus', ''),
    oweStatus: get(data, 'self.oweStatus', ''),
    doterraId: get(data, 'self.doterraAccount.id', ''),
    handleSubmitForm,
    handleUpdateMemberId,
    fetchProfile,
  };
};

export default useProfile;
