import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_BACKOFFICE_LINK } from 'graphql/queries/getBackofficeLink';
import { UPDATE_POSITION_STATUS } from 'graphql/mutations/updatePositionStatus';
import { UPDATE_DOTTERA_ACCOUNT } from 'graphql/mutations/updateDoterrAccount';
import { GET_MEMBER_STATUS } from 'graphql/queries/getMemberStatus';

import get from 'lodash/get';

const useWellAdvocate = () => {
  const [memberId, setMemberId] = useState('');
  const [link, setLink] = useState('');
  const [
    getBackofficeLink,
    { loading, error, data },
  ] = useLazyQuery(GET_BACKOFFICE_LINK, { variables: { memberId } });
  const [updateUser] = useMutation(UPDATE_POSITION_STATUS);

  const [updateBackOfficeLink] = useMutation(UPDATE_DOTTERA_ACCOUNT);

  const handleUpdateMemberId = e => {
    setMemberId(e.target.value);
  };

  const handleUpdatePositionStatus = async () => {
    if (memberId) {
      await updateBackOfficeLink({
        variables: { memberId, backOfficeLink: '' },
      });
    }
    await updateUser({
      variables: { status: 'BUILDER' },
      refetchQueries: () => [
        {
          query: GET_MEMBER_STATUS,
        },
      ],
    });
  };

  useEffect(() => {
    if (error) {
      return;
    }
    if (data) {
      const newLink = get(data, 'selfGenerateBackOfficeLink.url', '');
      setLink(newLink);
    }
  }, [data, error]);

  return {
    loading,
    error,
    data,
    getBackofficeLink,
    memberId,
    handleUpdateMemberId,
    link,
    handleUpdatePositionStatus,
  };
};

export default useWellAdvocate;
