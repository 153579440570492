import React from 'react';
import { Container } from 'pages/CreateAccount/WelcomeSection/styles';
import { PageTitle, H3, Text } from 'pages/CreateAccount/styles';

const WelcomeSection = () => (
  <Container>
    <PageTitle>Welcome!</PageTitle>
    <H3>
      What would a life of better health, more wealth, and free- <br /> dom to
      live each day on your terms look like?
    </H3>
    <Text>
      Both [REFERRER FIRST NAME] and our team are excited to introduce you to
      Drippl – a business automation platform designed to make growing a dōTERRA
      business more fun and more efficient than ever!
    </Text>
    <Text>
      After a year of working, tweaking, vetting, and late-night obsessing, we
      created a system that we believe can deliver these results and provide
      massive value to thousands of people – just like you!
    </Text>
    <Text>
      If you’re new to dōTERRA, you probably have lots of questions. And we have
      lots of answers. In order to serve up the best experience for you, tell us
      what best describes your current relationship with dōTERRA:
    </Text>
  </Container>
);

export default WelcomeSection;
