import styled, { css } from 'styled-components';

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 13px;
    p {
      font-family: ${theme.font.family.montserrat};
      color: ${theme.colors.p};
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }
    @media (min-width: 600px) {
      padding: 0px;
      p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  `};
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 9px;
    padding-right: 0px;
    font-size: 36px;
    line-height: 36px;
    font-family: ${theme.font.family.montserrat};
    color: ${theme.colors.primary};
    font-weight: 600;
    @media (min-width: 600px) {
      margin-top: 0px;
      margin-bottom: 20px;
      padding-right: 24px;
      font-size: 60px;
      line-height: 60px;
    }
  `};
`;
