import React, { useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import Input from 'ui/InputText';
import useProfile from 'pages/Profile/data/useProfile';

import { SectionForm, WrapperInput, Button } from 'pages/Profile/styles';
import {
  Link,
  WrapperInfo,
  WrapperBottom,
} from 'pages/Profile/DoTerraForm/styles';

const DoTerraForm = ({ data }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { handleUpdateMemberId } = useProfile();

  const [inputErrors, setInputErrors] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [referredByName, setReferredByName] = useState(' - ');
  const [rank, setRank] = useState(' - ');
  const [backOfficeLink, setBackOfficeLink] = useState('');

  const onSubmit = async formData => {
    setIsSaveDisabled(true);
    await handleUpdateMemberId(formData);
  };

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    if (data) {
      const memberId = get(data, 'doterraAccount.memberId', '') || ' - ';
      const backOffLink = get(data, 'doterraAccount.backOfficeLink', '') || '';
      const rankName = get(data, 'rankGoal.currentRank.name', '') || ' - ';
      const referredName = get(data, 'referredBy.fullName', '') || ' - ';
      setReferredByName(referredName);
      setRank(rankName);
      setValue([{ memberId }]);
      setBackOfficeLink(backOffLink);
    }
  }, [backOfficeLink, data, register, setValue]);

  return (
    <Fragment>
      <SectionForm onSubmit={handleSubmit(onSubmit)}>
        <WrapperInput>
          <label>Referred By</label>
          <div>
            <Input
              disabled
              isDisabled
              name="referredByName"
              value={referredByName}
            />
          </div>
        </WrapperInput>

        <WrapperInput>
          <label>Rank</label>
          <div>
            <Input name="rank" disabled isDisabled value={rank} />
          </div>
        </WrapperInput>
        <WrapperInput>
          <label>dōTERRA ID</label>
          <div>
            <Input
              name="memberId"
              hasError={inputErrors.includes('memberId')}
              ref={register({ required: true })}
            />
          </div>
        </WrapperInput>
        <Button
          disabled={isSaveDisabled}
          isDisabled={isSaveDisabled}
          type="submit">
          {isSaveDisabled ? 'saved' : 'update'}
        </Button>
      </SectionForm>
      {backOfficeLink && (
        <WrapperBottom>
          <Link href={backOfficeLink} target="_blank" rel="noopener noreferrer">
            {backOfficeLink}
          </Link>
          <WrapperInfo>
          <p>Click on the link above to verify that it directs you to dōTERRA's US enrollment page. If you have provided the correct dōTERRA ID, and you are currently a Wellness Advocate, you should see your name in the upper left side of the screen with the title Independent Wellness Advocate below your name.</p>
          <br></br>
          <p>If you do not see your name and title on the dōTERRA page, you may have entered your dōTERRA ID incorrectly, or you may need to upgrade your account to Wellness Advocate. Call doTERRA Member Services in your area if you need assistance verifying your dōTERRA ID.</p>
          <br></br>
          <p>Send an email to <span>support@drippl.com</span> if you need further assistance.</p> 
          </WrapperInfo>
        </WrapperBottom>
      )}
    </Fragment>
  );
};

export default DoTerraForm;
