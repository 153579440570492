import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import HtmlParser from 'html-react-parser';
import {
  Modal,
  ContainerDetails,
  Img,
  Title,
  CloseButton,
  WrapperImg,
  ListItem,
  ProductButton
} from 'pages/Wholesale/ModalProductDetails/styles';
import Bowser from 'bowser';
import get from 'lodash/get';

const ModalProductDetails = ({ isOpen, handleClose, product }) => {
  const { image, name, description = '' } = product;
  const isSafari =
    get(Bowser.parse(window.navigator.userAgent), 'browser.name') === 'Safari';

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <ContainerDetails>
          <CloseButton onClick={handleClose}>X</CloseButton>
          <WrapperImg isSafari={isSafari}>
            <Img src={image?.url} />
          </WrapperImg>
          <Title>{name}</Title>
          <ListItem>{HtmlParser(description)}</ListItem>
          <ProductButton onClick={handleClose}>Close</ProductButton>
        </ContainerDetails>
      </Fade>
    </Modal>
  );
};

export default ModalProductDetails;
