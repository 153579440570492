import React from 'react';
import { PageContent } from 'ui/layouts';
import {
  Container,
  Title,
  Subtitle,
  SectionInput,
  WrapperInput,
  SectionBottom,
  ButtonSave,
  ButtonCancel,
} from 'pages/WellnessAdvocateConfirmation/styles';
import Text from 'ui/Text';
import InputText from 'ui/InputText';
import StatusProgress from 'ui/StatusProgress';
import WelcomeHeader from 'ui/WelcomeHeader';
import useWellAdvocate from 'pages/WellnessAdvocateConfirmation/data/useWellAdvocate';
import useReactRouter from 'use-react-router';

const STATIC_STATUS = {
  EXPLORE: { itemComplete: true },
  TRY: { itemComplete: true },
  PREPARE: { itemComplete: true },
  AUTOMATE: { itemPending: true },
};

const WellnessAdvocateConfirmation = () => {
  const {
    memberId,
    handleUpdateMemberId,
    handleUpdatePositionStatus,
  } = useWellAdvocate();

  const { history } = useReactRouter();

  const handleBackToTheFlow = () => history.push('/do-doterra');

  const handleConfirmeMembership = async () => {
    await handleUpdatePositionStatus();
    handleBackToTheFlow();
  };

  return (
    <Container>
      <WelcomeHeader />
      <StatusProgress statusBar={STATIC_STATUS} />
      <PageContent>
        <Title>Confirm Wellness Advocate Status</Title>
        <Subtitle>Prepare to build your booming dōTERRA business!</Subtitle>
        <Text>
          To start automating your online business, we need your dōTERRA ID.
        </Text>

        <SectionInput>
          <WrapperInput>
            <label>doTERRA ID</label>
            <InputText value={memberId} onChange={handleUpdateMemberId} />
          </WrapperInput>
        </SectionInput>

        <SectionBottom>
          <ButtonSave
            isDisabled={!memberId}
            disabled={!memberId}
            onClick={handleConfirmeMembership}>
            Confirm Membership
          </ButtonSave>
          <ButtonCancel onClick={handleBackToTheFlow}>Cancel</ButtonCancel>
        </SectionBottom>
      </PageContent>
    </Container>
  );
};

export default WellnessAdvocateConfirmation;
