import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 35px;
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 0;
    padding-left: 5px;
  }
`;

export const PageTitle = styled.h1`
  margin-bottom: 15px;
  font-size: 62px;
  line-height: 48px;
  font-weight: 800;
  letter-spacing: -3px;
  color: #5ed6d5;
`;

export const H3 = styled.h3`
  margin-bottom: 40px;
  line-height: 30px;
  font-weight: bold;
  color: #333;
  font-size: 28px;
  font-family: Visbyroundcf, sans-serif;
`;

export const Text = styled.div`
  color: rgb(121, 121, 121);
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const RadioInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? '#5ed6d5' : 'rgb(121, 121, 121)')};
  padding: 15px;
  margin-bottom: 20px;
  width: 35%;
  transition: all 200ms ease;
  label {
    padding-left: 20px;
  }
  input {
    cursor: pointer;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const AccountForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 35px;
  width: 55%;
  margin-top: 50px;
  button {
    border-radius: 10px;
    height: 60px;
    font-size: 22px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    color: #333;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }
  span {
    margin-bottom: 8px;
  }
  input {
    height: 50px;
    border: 1px solid #333;
    font-size: 18px;
    padding: 7px 15px;
  }
`;
