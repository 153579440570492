import gql from 'graphql-tag';

export const GET_MODULE = gql`
  query getModule($id: ID!) {
    selfLabModule(id: $id) {
      id
      name
      shortDescription
      labCourses(sort: "priority") {
        id
        name
        labLessons(sort: "priority") {
          id
          name
          description
          attachments {
            name
            url
            label
          }
          video {
            id
            name
            description
            videoUrl
            plays
            duration
            name
            s3path
            imagePath
            userVideos {
              id
              isFinished
              progress
            }
          }
        }
      }
    }
    self {
      id
      callToActionText
    }
  }
`;
