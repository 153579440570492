import React, { useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'ui/InputText';
import ModalCancelSub from 'pages/Profile/ModalCancelSub';

import { SectionForm, ManageSubscriptionButton, WrapperInput, CancelSub } from 'pages/Profile/styles';
import { useAuth0 } from 'context/auth';
import axios from 'axios';
import { Button } from '@material-ui/core';

// TODO: get from BE

const SubForm = () => {
  const { loading, token } = useAuth0();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      monthlySubscription: '$ 14.95',
      billingDate: '12/12/2020',
    },
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => setIsOpen(false);

  const onSubmit = async data => {
    console.log('data: ', data);
  };

  const onManageSubscription = async () => {
    console.log('Hello');
    const result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/stripe-customer/create-customer-portal-session`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('result', result);
    window.location.replace(result.data.url);
    return { result: result };
  };

  return (
    <Fragment>
      <SectionForm onSubmit={handleSubmit(onSubmit)}>
        <h2>Subscription Info</h2>
        {/* <WrapperInput>
          <label>Monthly Subscription</label>
          <div>
            <Input
              name="monthlySubscription"
              disabled
              isDisabled
              ref={register({ required: true })}
            />
          </div>
        </WrapperInput>
        <WrapperInput>
          <label>Next Billing Date</label>
          <div>
            <Input
              name="billingDate"
              disabled
              isDisabled
              ref={register({ required: true })}
            />
          </div>
        </WrapperInput> */}
        {/* <CancelSub onClick={() => setIsOpen(true)}>
          Cancel Subscription
        </CancelSub> */}

        <ManageSubscriptionButton onClick={onManageSubscription}>
          Manage Subscription
        </ManageSubscriptionButton>

      </SectionForm>
      <ModalCancelSub isOpen={isOpen} handleClose={handleCloseModal} />
    </Fragment>
  );
};

export default SubForm;
