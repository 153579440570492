import styled, { css } from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import ButtonDefault from 'ui/Button/Default';
import ButtonClean from 'ui/Button/Clean';

export const ModalStyled = styled(MuiModal)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: none;
  }
  @media (min-width: 600px) {
    && {
    }
  }
`;

export const Container = styled.div`
  ${({ theme, containerWidth }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    max-height: 80vh;
    overflow-y: scroll;
    max-width: 96vw;
    @media (min-width: 600px) {
      width: ${containerWidth};
      overflow-y: auto;
      max-height: 90vh;
    }
  `}
`;

export const SectionFooter = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    border-top: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const ButtonSave = styled(ButtonDefault)`
  ${() => css`
    margin-left: 10px;
    width: 35%;
    font-size: 18px;
    padding: 15px;
    @media (min-width: 600px) {
      width: 35%;
    }
  `}
`;

export const ButtonCancel = styled(ButtonClean)`
  margin-left: 10px;
  width: 35%;
  font-size: 18px;
  padding: 15px;
  @media (min-width: 600px) {
    width: 35%;
  }
`;
