import styled from 'styled-components';

const Input = styled.input`
  display: block;
  font-size: 16px;
  color: #444;
  line-height: 29px;
  padding: 8px 30px 8px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#d8d8d8')};
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  outline: none;
  background-color: ${({ isDisabled }) =>
    isDisabled ? 'rgba(225, 229, 232, 0.3)' : null};
  transition: border 200ms ease-in;
`;

export default Input;
