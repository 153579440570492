import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 8px;
  margin-top: 30px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.11);
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 600px) {
  }
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  padding: 20px 30px;
  span {
    font-weight: 600;
  }
`;
