import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const WrapperTitle = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (min-width: 600px) {
      justify-content: center;
      align-items: flex-start;
    }
  `};
`;

export const WrapperImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 600px) {
    flex: 0;
  }
`;

export const LogoImage = styled.div`
  ${({ src }) => css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${`url(${src})`};
    border: none;
    width: 82px;
    height: 82px;
    margin-right: 20px;
    @media (min-width: 600px) {
      width: 105px;
      height: 105px;
      justify-content: center;
      align-items: center;
    }
  `};
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 3px;
    font-size: 19px;
    line-height: 27px;
    font-weight: 600;
    color: #333;
    text-transform: capitalize;
    font-family: ${theme.font.family.montserrat};
    @media (min-width: 600px) {
      font-size: 32px;
      line-height: 36px;
    }
  `};
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: ${theme.colors.primary};
    font-family: ${theme.font.family.montserrat};
    @media (min-width: 600px) {
      font-size: 19px;
    }
  `};
`;
