export const POSITION_STATUS = {
  EXPLORING: 'EXPLORING',
  PENDING_CUSTOMER: 'PENDING_CUSTOMER',
  CUSTOMER: 'CUSTOMER',
  PENDING_BUILDER: 'PENDING_BUILDER',
  BUILDER: 'BUILDER',
};

export const MEMBER_STATUS = {
  SETUP: 'SETUP',
  PREMEMBER: 'PREMEMBER',
  MEMBER: 'MEMBER',
  VIP: 'VIP',
};
