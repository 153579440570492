const PREMEMBER_STATUS = {
  EXPLORING: {
    EXPLORE: {
      itemComplete: true,
    },
    TRY: {
      itemPending: true,
    },
    PREPARE: {},
    AUTOMATE: {},
  },
  PENDING_CUSTOMER: {
    EXPLORE: {
      itemComplete: true,
    },
    TRY: {
      itemPending: true,
    },
    PREPARE: {},
    AUTOMATE: {},
  },
};

export const PREMEMBER_PAGE = {
  EXPLORING: {
    header: {
      title: 'Meet dōTERRA',
      subtitle: 'Start your journey to holistic, self-directed healthcare!',
      text: '',
      videoUrl: '',
      stepStatus: PREMEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text:
        'Discover how dōTERRA can help you care for the whole you while enhancing your physical, mental, and emotional health. \n Why essential oils? Why dōTERRA? Why now? Get answers to all of these questions and more – on your own time and at your own pace – by clicking below and exploring the first module: Meet dōTERRA. \n When you’re ready to see what’s possible for yourself and your family’s health, pick the starter kit that suits you best. Then, once you receive your products, we’ll help you learn how to put them to use in the next step: Live dōTERRA!',
    },
    highlight:
      'Dicover how the pursuit of purity \n blesses lives everywhere!',
  },
  PENDING_CUSTOMER: {
    header: {
      title: 'Are You In?',
      subtitle:
        'After you’ve enrolled with dōTERRA, your journey to holistic, self-directed healthcare begins!',
      text:
        'In order to best support you as you move forward, we need to validate your current dōTERRA membership.',
      videoUrl: '',
      stepStatus: PREMEMBER_STATUS.PENDING_CUSTOMER,
    },
    body: {
      title: 'Still exploring?',
      text:
        'If you haven’t yet enrolled and want to keep exploring, click “Go back!” below. When you’re ready to open your account to purchase dōTERRA products at 25% off retail price, just follow the steps and we’ll see you here again!',
    },
    highlight: '',
  },
  EMPTY: {
    header: {
      title: '',
      subtitle: '',
      text: '',
      videoUrl: '',
      stepStatus: PREMEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text: '',
    },
    highlight: '',
  },
};
