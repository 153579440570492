import React from 'react';
import { Col, Row, PageContent } from 'ui/layouts';
import PageTitle from 'ui/Text/PageTitle';
import PageSubtitle from 'ui/Text/PageSubtitle';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';

import {
  ImageFamily,
  Section,
  SetBoxLabel,
  StepRow,
  StepBox,
  ChooseText,
  StyledLink,
  ButtonPrimary,
} from 'pages/LoyaltyRewardsProgram/styles';
import img from 'assets/img/lrp.jpg';
import H1 from 'ui/Text/H1';
import H3 from 'ui/Text/H3';
import CustomText from 'ui/Text/CustomText';

const LoyaltyRewardsProgram = () => (
  <Col>
    <PageTitle>LRP - Loyalty Rewards Program</PageTitle>
    <PageSubtitle>
      Love free stuff? Never want to miss out on your favorite daily must haves?
    </PageSubtitle>
    <PageContent>
      <GoBackToTheFlowPage />
      <Row isCentralized>
        <ImageFamily src={img} />
      </Row>
      <Section isCentralized>
        <H3 primary center>
          The next thing you’re going to want to do is opt INTO the Loyalty
          Rewards Program (LRP) – the smartest way to place your orders.
        </H3>
        <H1 primary>How it works</H1>
        <CustomText center>
          LRP offers you points (a lot like frequent flyer miles) for placing
          regular orders. As you continue to purchase products, you accrue
          points that can be used to pay for future orders. Essentially, your
          loyalty earns you free products! Just hop in and update your order
          each month with the products you want.
        </CustomText>
        <H3 primary center>
          Some of our daily must-haves to consider:
        </H3>
        <StepRow>
          <StepBox>
            <SetBoxLabel>LEMON</SetBoxLabel>
            <ChooseText>
              Perfect for uplifting the mood and cleansing everything from your
              countertops to your entire system!
            </ChooseText>
          </StepBox>
          <StepBox>
            <SetBoxLabel>LIFELONG VITALITY PACK</SetBoxLabel>

            <ChooseText>
              You’ll quickly discover why people call this a “happy oil!”
            </ChooseText>
          </StepBox>
          <StepBox>
            <SetBoxLabel>Frankincense</SetBoxLabel>

            <ChooseText>
              The “King of Oils,” Frankincense used aromatically promotes
              feelings of peace and overall wellness, while internal and topical
              uses include healthy immune function, central nervous system, and
              skin support – among much, much more!
            </ChooseText>
          </StepBox>
          <StepBox>
            <SetBoxLabel>ONGUARD™</SetBoxLabel>

            <ChooseText>
              A popular essential oil blend with an aroma that helps create
              feelings of clear, easy breathing.
            </ChooseText>
          </StepBox>
        </StepRow>
        <H1 primary>Ready to set up your LRP?</H1>
        <ButtonPrimary>Yep! Send me to mydoTERRA.com</ButtonPrimary>
        <CustomText>
          Want a walk-thru of setting up your LRP first?{' '}
          <StyledLink>Click here</StyledLink> to walk me through it
        </CustomText>
      </Section>
    </PageContent>
  </Col>
);

export default LoyaltyRewardsProgram;
