import gql from 'graphql-tag';

export const GET_REGIONS_LANGUAGES = gql`
  query getRegionsLanguages {
    regions {
      id
      regionCode
      name
      isActive
    }
    languages {
      id
      name
      languageCode
    }
  }
`;
