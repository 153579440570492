import { useMemo } from 'react';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useBreakpoints = () => {
  const theme = useTheme();
  const options = { noSsr: true };

  const isUpMedium = useMediaQuery(theme.breakpoints.up('sm'), options);
  const isDesktop = useMemo(() => isUpMedium, [isUpMedium]);
  const isMobile = useMemo(() => !isUpMedium, [isUpMedium]);

  return { isDesktop, isMobile };
};

export default useBreakpoints;
