import styled, { css } from 'styled-components';

export const SectionInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 32rem;
    button {
      margin-top: 20px;
      width: 100%;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      button {
        margin-top: 20px;
        width: 220px;
      }
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    label {
      font-weight: bolder;
    }
    input {
      margin-top: 10px;
      width: 100%;
    }
    @media (min-width: 600px) {
      input {
        margin-top: 10px;
        width: 250px;
      }
    }
  `};
`;
