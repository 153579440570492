import gql from 'graphql-tag';

export const GET_COURSES_PROGRESS = gql`
  query getCourseProgress($idExplore: ID!, $idLive: ID!, $idBuild: ID!) {
    explore: selfGetNextModuleLesson(id: $idExplore) {
      id
      name
      description
      video {
        id
        imagePath
        videoUrl
      }
    }
    live: selfGetNextModuleLesson(id: $idLive) {
      id
      name
      description
      video {
        id
        imagePath
        videoUrl
      }
    }
    build: selfGetNextModuleLesson(id: $idBuild) {
      id
      name
      description
      video {
        id
        imagePath
        videoUrl
      }
    }
  }
`;
