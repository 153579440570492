import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import YouTubeIcon from '@material-ui/icons/YouTube';
import useReactRouter from 'use-react-router';

const ContainerVideoThumb = styled.div`
  ${({ src }) => css`
    position: relative;
    width: 100%;
    height: 200px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${`url(${src})`};
    @media (min-width: 600px) {
      width: 360px;
    }
  `};
`;

const Label = styled.div`
  ${() => css`
    position: absolute;
    bottom: 0;
    height: 60px;
    background-color: transparent;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    span {
      white-space: wrap;
      max-width: 190px;
      text-align: center;
      font-size: 14px;
    }
    @media (min-width: 600px) {
      span {
        max-width: 270px;
        font-size: 18px;
      }
    }
  `};
`;

const Backdrop = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: ${theme.colors.primary};
    opacity: 0.75;
  `};
`;

const VideoOutlineIcon = styled(YouTubeIcon)`
  && {
    font-size: 50px;
    padding-left: 10px;
  }
`;

const VideoThumb = ({ info }) => {
  const { history } = useReactRouter();
  const { name, coverImage, id, currentVideoInfo } = info;
  const handleClick = () => {
    history.push({
      pathname: '/video',
      state: { moduleId: id, moduleName: name, backToTheFlow: true },
    });
  };

  if (currentVideoInfo) {
    const { name: nameCurrentVideo, video } = currentVideoInfo;
    return (
      <ContainerVideoThumb src={video?.imagePath}>
        <Backdrop />
        <Label onClick={handleClick}>
          <span>{nameCurrentVideo}</span>
          <VideoOutlineIcon />
        </Label>
      </ContainerVideoThumb>
    );
  }
  return (
    <ContainerVideoThumb src={coverImage?.url}>
      <Backdrop />
      <Label onClick={handleClick}>
        <span>{name}</span>
        <VideoOutlineIcon />
      </Label>
    </ContainerVideoThumb>
  );
};

VideoThumb.propTypes = {
  info: PropTypes.object,
};

export default VideoThumb;
