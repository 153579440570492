import styled, { css } from 'styled-components';
import Button from 'ui/Button/Default';
import RadioSet from 'ui/RadioSet';
import { PageContent } from 'ui/layouts';

export const Container = styled(PageContent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
  @media (min-width: 600px) {
    align-items: flex-start;
    padding: 10px 0px;
  }
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    text-align: left;
    color: ${theme.colors.primary};
    @media (min-width: 600px) {
      line-height: 38px;
    }
  `};
`;

export const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  span {
    margin-right: 8px;
  }
  @media (min-width: 600px) {
    width: 45%;
  }
`;

export const WrappeInputRadio = styled(RadioSet)`
  max-width: 450px;
  margin-bottom: ${({ isLastElement }) => (isLastElement ? '15px' : '30px')};
  border: 1px solid
    ${({ hasError }) => (hasError ? 'red' : 'rgba(0, 0, 0, 0.3)')};
  border-radius: 15px;
  padding: 20px;
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    padding-left: 35px;
    line-height: 21px;
    width: 100%;
  }
  label {
    font-size: 20px;
  }
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > label {
      flex: 1;
      padding-bottom: 10px;
      font-weight: bold;
    }
    @media (min-width: 600px) {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      > label {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        padding-right: 20px;
      }
      div {
        display: flex;
        flex-direction: column;
        flex: 2.1;
        input[type='text'],
        select {
          width: 100%;
          height: 60px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  `};
`;

export const MemberOptionWrapper = styled.div`
  ${() => css`
    padding: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
    width: 90%;
    background-color: rgba(225, 229, 232, 0.6);
    border-radius: 5px;
    @media (min-width: 600px) {
      width: 70%;
      margin-left: 20px;
      max-width: 600px;
    }
  `};
`;

export const WrappeInputRadioMember = styled(RadioSet)`
  border-radius: 5px;
  padding: 10px;
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    padding-left: 35px;
    line-height: 21px;
  }
`;

export const WrapperCheckbox = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-left: 5px;
    input[type='checkbox'] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
    }
    label {
      margin-left: 15px;
      font-weight: bolder;
      font-size: 14px;
    }
    @media (min-width: 600px) {
      label {
        margin-left: 10px;
        padding-top: 3px;
      }
    }
  `};
`;

export const WrapperBottom = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    div {
      color: red;
      cursor: pointer;
      margin-top: 30px;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      div {
        margin-top: 0px;
        margin-left: 20px;
        padding-top: 10px;
      }
    }
  `};
`;

export const WrapperAlert = styled.div`
  ${() => css`
    margin-top: 35px;
    margin-bottom: 41px;
    padding: 20px 25px 25px;
    border: 1px solid #dde075;
    border-radius: 13px;
    background-color: #feffd9;
    @media (min-width: 600px) {
    }
  `};
`;

export const AlertTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    font-family: ${theme.font.family.montserrat};
    color: #000;
    font-size: 32px;
    font-weight: 800;
    img {
      margin-left: 20px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const AlertMessage = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family.montserrat};
    color: #0e0d0d;
    line-height: 24px;
    font-weight: 300;
    font-style: italic;
    @media (min-width: 600px) {
    }
  `};
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    padding: 1.5rem 4.4rem;
  }
`;
