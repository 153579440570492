import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import Player from 'ui/Player';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Loading from 'ui/Loader';
import { useWindowSize } from 'hooks/useWindowSize';
import { useVideoView } from 'pages/VideoView/data/useVideoView';
import {
  SupportFiles,
  useShowSupportFiles,
} from 'pages/VideoView/SupportFiles';

import {
  ContainerVideoView,
  SectionTitle,
  Title,
  SectionVideo,
  VideoList,
  VideoItem,
  VideoItemThumb,
  VideoItemDescription,
  VideoCategory,
  VideoDisplay,
  ButtonTheFlow,
  WrapperVideo,
  WrapperVideoDescription,
  WrapperVideoTitle,
  WrapperPlayer,
  Description,
  WrapperDescription,
  ToggleCompleteButton,
  IconCheck,
  IconCheckCompleted,
  IconCheckDescription,
  IconCheckDescriptionCompleted,
  DescriptionFooter,
  CtaButton,
} from 'pages/VideoView/styles';

const defaultGoBack = {
  path: '/video-library',
  label: 'Go Back',
};

const VideoView = () => {
  const { isMobileView } = useWindowSize();
  const { history, location, match } = useReactRouter();
  const {
    data,
    user,
    fetchModule,
    labCourses,
    currentLesson,
    setCurrentLesson,
    isSelected,
    setIsSelected,
    markVideoCompleted,
    autoScroll,
  } = useVideoView({
    deepLinklessonId: match.params.lessonId,
  });
  const { showSupportFiles, setShowSupportFiles } = useShowSupportFiles();

  const [goBackTo, setGoBackTo] = useState(defaultGoBack);
  const [refList, setRefLest] = useState([]);

  const handleGoBack = () => history.push(goBackTo.path);

  const handleDescriptionIcon = ({ isFinished }) =>
    isFinished ? <IconCheckDescriptionCompleted /> : <IconCheckDescription />;

  const scrollToSelectedItem = React.useCallback(
    id => {
      if (autoScroll && refList[id]) {
        refList[id].current.scrollIntoView({
          behavior: 'smooth',
          block: isMobileView ? 'end' : 'start',
        });
      }
    },
    [autoScroll, refList, isMobileView]
  );

  useEffect(() => {
    scrollToSelectedItem(isSelected);
  }, [isSelected, scrollToSelectedItem, isMobileView]);

  useEffect(() => {
    let moduleId = '';
    if (match.params.moduleId) {
      moduleId = match.params.moduleId;
    } else {
      moduleId = location?.state?.moduleId;
    }
    if (moduleId) {
      return fetchModule({ variables: { id: moduleId } });
    }
    history.push('/do-doterra');
  }, []); // eslint-disable-line

  useEffect(() => {
    if (labCourses) {
      const refs = [];
      labCourses.map(element => {
        element.labLessons.map(l => {
          refs[l.id] = React.createRef();
        });
      });
      setRefLest(refs);
    }
  }, [labCourses]);

  const handleLessonSelected = item => {
    setIsSelected(item.id);
    setCurrentLesson(item);
  };

  useEffect(() => {
    if (location.state?.backToTheFlow) {
      setGoBackTo({ path: '/do-doterra', label: 'Go Back' });
    }
  }, []); // eslint-disable-line

  const goToTheFlowPage = () => history.push('/do-doterra');

  return data ? (
    <ContainerVideoView isMobileView={isMobileView}>
      <SectionTitle>
        <Title>{data.name}</Title>
        {!isMobileView ? (
          <ButtonTheFlow onClick={handleGoBack}>
            <ArrowLeftIcon size="small" /> <span>{goBackTo.label}</span>
          </ButtonTheFlow>
        ) : null}
      </SectionTitle>
      <SectionVideo isMobileView={isMobileView}>
        <VideoList isMobileView={isMobileView}>
          {labCourses.map(({ id, name, labLessons }) => (
            <div key={id}>
              <VideoCategory>{name}</VideoCategory>
              {labLessons.map(item => (
                <VideoItem
                  key={item.videoId}
                  ref={refList[item.id]}
                  isSelected={isSelected === item.id}
                  onClick={() => handleLessonSelected(item)}>
                  <VideoItemThumb src={item.video.imagePath} />
                  <VideoItemDescription>{item.name}</VideoItemDescription>
                  {handleDescriptionIcon(item)}
                </VideoItem>
              ))}
            </div>
          ))}
        </VideoList>
        <VideoDisplay isMobileView={isMobileView}>
          {currentLesson ? (
            <WrapperVideo>
              <WrapperPlayer isMobileView={isMobileView}>
                <Player
                  url={currentLesson.video.videoUrl}
                  videoId={currentLesson.video.id}
                  videoDurationSecs={currentLesson.videoDurationSecs}
                  markVideoCompleted={markVideoCompleted}
                />
              </WrapperPlayer>
              <WrapperVideoDescription>
                <WrapperVideoTitle>
                  <h3>{currentLesson.name}</h3>
                  <span>{currentLesson.duration} min</span>
                </WrapperVideoTitle>
                <WrapperDescription>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: currentLesson.description,
                    }}></Description>
                  <DescriptionFooter>
                    {Boolean(user.callToActionText) && (
                      <CtaButton onClick={goToTheFlowPage}>
                        {user.callToActionText}
                      </CtaButton>
                    )}
                    {currentLesson.isFinished ? (
                      <ToggleCompleteButton
                        isMobileView={isMobileView}
                        onClick={() =>
                          markVideoCompleted({
                            videoId: currentLesson.video.id,
                            isFinished: !currentLesson.isFinished,
                          })
                        }>
                        Mark Incomplete
                        <IconCheck />
                      </ToggleCompleteButton>
                    ) : (
                      <ToggleCompleteButton
                        isMobileView={isMobileView}
                        onClick={() =>
                          markVideoCompleted({
                            videoId: currentLesson.video.id,
                            isFinished: !currentLesson.isFinished,
                          })
                        }>
                        Mark Complete
                        <IconCheckCompleted />
                      </ToggleCompleteButton>
                    )}
                  </DescriptionFooter>
                </WrapperDescription>
              </WrapperVideoDescription>
            </WrapperVideo>
          ) : null}
        </VideoDisplay>
      </SectionVideo>
      <SupportFiles
        currentLesson={currentLesson}
        showSupportFiles={showSupportFiles}
        setShowSupportFiles={setShowSupportFiles}
      />
    </ContainerVideoView>
  ) : (
    <ContainerVideoView>
      <Loading />
    </ContainerVideoView>
  );
};

export default VideoView;
