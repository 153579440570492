import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  padding: 10px 0 0;
  width: 240px;
`;

export const Link = styled(RouterLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
