import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useAuth0 } from 'context/auth';

const Player = ({
  className,
  url,
  videoId,
  videoDurationSecs,
  markVideoCompleted = () => null,
}) => {
  const ref = React.createRef();
  const { http } = useAuth0();

  const onReady = readyVideoId => {
    // load user progress
    if (ref) {
      http.get(`/get-user-video/${readyVideoId}`).then(res => {
        if (ref && ref.current && ref.current.seekTo) {
          ref.current.seekTo(res.data.progress || 0);
        }
      });
    }
  };

  const onProgress = progress => {
    const { playedSeconds } = progress;
    const completedUpdate = playedSeconds > videoDurationSecs - 4;
    if (completedUpdate) {
      markVideoCompleted({ videoId, isFinished: true });
    }
    //update user video data after every 10 secs of video play
    http.post('/update-user-video', {
      videoId: videoId,
      progress: Math.ceil(progress.playedSeconds),
    });
  };

  return (
    <ReactPlayer
      ref={ref}
      url={url}
      className={className}
      width="100%"
      height="100%"
      onReady={onReady(videoId)}
      onProgress={onProgress}
      progressInterval={10000}
    />
  );
};

const AbsolutelyPositionedPlayer = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
`;

const RelativePositionWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ResponsiveStyledPlayer = props => (
  <RelativePositionWrapper>
    <AbsolutelyPositionedPlayer {...props} />
  </RelativePositionWrapper>
);

ResponsiveStyledPlayer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  videoId: PropTypes.string,
};

Player.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  videoId: PropTypes.string,
};

export default ResponsiveStyledPlayer;
