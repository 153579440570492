import gql from 'graphql-tag';

export const SET_REFERRED_BY = gql`
  mutation SET_REFERRED_BY($id: String) {
    selfSetRef(id: $id) {
      id
      referredBy {
        id
        fullName
        email
        profilePicture
      }
    }
  }
`;
