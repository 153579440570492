import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 64px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 20px; */
  /* box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2); */
  display: flex;
  @media (min-width: 600px) {
    margin-bottom: 30px;
    padding-right: 0px;
  }
`;

export const Wrapper = styled.div`
  ${({ theme, isCentralized }) => css`
    display: flex;
    justify-content: ${isCentralized ? 'flex-start' : 'center'};
    align-items: center;
    max-width: ${theme.container.max};
    justify-self: center;
    width: 100%;
    @media (min-width: 600px) {
      justify-content: space-between;
    }
  `};
`;

export const ContactWrapper = styled.div`
  ${({ shouldHide }) => css`
    display: ${shouldHide ? 'none' : 'flex'};
    justify-content: ${shouldHide ? 'flex-end' : 'flex-start'};
    align-items: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const Avatar = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  @media (min-width: 600px) {
  }
`;

export const Text = styled.div`
  ${({ theme }) => css`
    margin-left: 20px;
    font-size: 14px;
    span {
      color: ${theme.colors.primary};
    }
    @media (min-width: 600px) {
    }
  `}
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: solid 1px #d1d1d1;
  border-radius: 4px;
  background-color: #fff;
  transition: all 200ms ease;
  font-family: Visbyroundcf, sans-serif;
  color: #414141;
  font-size: 14px;
  margin-left: 20px;
  &:hover {
    background-color: #eee;
    transform: translate(0px, -2px);
  }

  @media (min-width: 600px) {
  }
`;

export const ButtonUpgrade = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 10px 20px;
    border: solid 1px #d1d1d1;
    border-radius: 25px;
    background-color: ${theme.colors.primary};
    transition: all 200ms ease;
    font-family: Visbyroundcf, sans-serif;
    color: white;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      background-color: ${theme.colors.buttons.primary.buttonshover};
      transform: translate(0px, -2px);
    }
    width: 100%;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    @media (min-width: 600px) {
      min-width: 180px;
      max-width: 230px;
    }
  `};
`;
