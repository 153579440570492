import React from 'react';
import TabsInfo from 'pages/Profile/TabsInfo';
import { ContainerProfile, Title } from 'pages/Profile/styles';

const Profile = () => (
  <ContainerProfile>
    <Title isPrimary>Your Account</Title>
    <TabsInfo />
  </ContainerProfile>
);

export default Profile;
