import styled, { css } from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import LinkButton from 'ui/Button/LinkButtonDefault';

export const Modal = styled(MuiModal)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: none;
    margin-top: 20px;
  }
  @media (min-width: 600px) {
    && {
    }
  }
`;

export const ContainerDetails = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    max-height: 100vh;
    padding: 45px 10px;
    @media (min-width: 600px) {
      width: 600px;
      overflow-y: auto;
      max-height: 90vh;
      padding: 30px 20px;
    }
  `}
`;

export const Img = styled.img`
  ${() => css`
    height: 139px;
    width: 218px;
    align-self: center;
  `};
`;

export const Description = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 22px;
    height: 40vh;
    overflow: scroll;
    div:nth-child(1) {
      margin-top: 10px;
    }
    div {
      margin-bottom: 10px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const ListItem = styled.div`
  ${() => css`
    margin: 10px 0;
    white-space: pre-line;
    @media (min-width: 600px) {
    }
  `};
`;

export const Title = styled.div`
  ${() => css`
    align-self: center;
    font-size: 28px;
    line-height: 22px;
    font-weight: 600;
    margin-top: 10px;
    @media (min-width: 600px) {
      margin-top: 20px;
    }
  `};
`;

export const CloseButton = styled.div`
  ${() => css`
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperImg = styled.div`
  ${({ isSafari }) => css`
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${isSafari ? '45px 0' : null};
    @media (min-width: 600px) {
    }
  `};
`;

export const ProductButton = styled(LinkButton)`
  margin: 15px 0px;
  font-size: 19px;
  text-transform: uppercase;
  width: 100%;
  height: 52px;
  text-align: center;
  align-self: center;
  line-height: 52px;
  font-weight: 500;
`;
