import gql from 'graphql-tag';

export const GET_THE_FLOW_INFO = gql`
  query getTheFlowInfo {
    self {
      id
      firstName
      lastName
      authId
      positionStatus
      oweStatus
      email
      profilePicture
      fullName
      shadowUser {
        id
        oweStatus
        positionStatus
      }
      userCustomization {
        id
        welcomeMessage
      }
      referredBy {
        id
        profilePicture
        doterraAccount {
          id
          memberId
        }
        userCustomization {
          id
          welcomeMessage
        }
      }
      region {
        id
        name
        doterraLoginUrl
        kitInformation
      }
      doterraAccount {
        id
        backOfficeLink
        memberId
      }
      referralLinks {
        id
        link
        label
      }
    }
    labModules(sort: "priority") {
      id
      name
      coverImage {
        url
      }
    }
  }
`;
