import styled, { css } from 'styled-components';
import ButtonClean from 'ui/Button/Clean';
import LinkButton from 'ui/Button/LinkButtonDefault';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    @media (min-width: 600px) {
      width: 700px;
      overflow-y: none;
    }
  `}
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: center;
    padding: 0 20px;
    line-height: 22px;
    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 32px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
      line-height: 32px;
    }
    @media (min-width: 600px) {
      padding: 0 40px;
      h2 {
        line-height: 22px;
        font-size: 26px;
        letter-spacing: 0.1px;
      }
    }
  `};
`;

export const SectionBody = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 20px;
    line-height: 22px;
    @media (min-width: 600px) {
      padding: 0 40px;
    }
  `};
`;

export const SectionFooter = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    border-top: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 30px 20px;
    }
  `};
`;

export const ButtonSave = styled(LinkButton)`
  ${() => css`
    margin-left: 10px;
    width: 80%;
    font-size: 18px;
    text-align: center;
    line-height: 48px;
    order: 1;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      width: 35%;
      order: 2;
      margin-bottom: 0;
    }
  `}
`;

export const ButtonCancel = styled(ButtonClean)`
  margin-left: 10px;
  width: 80%;
  font-size: 18px;
  padding: 15px;
  order: 2;
  @media (min-width: 600px) {
    width: 35%;
    order: 1;
  }
`;
