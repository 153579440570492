import styled, { css } from 'styled-components';
import { Col } from 'ui/layouts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 600px) {
  }
`;

export const SectionTop = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const WrapperTitle = styled.div`
  flex: 1;
  margin-bottom: 0;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 26px;
    line-height: 25px;
    font-weight: 800;
    color: ${theme.colors.primary};
    margin-bottom: 10px;
    @media (min-width: 600px) {
      padding: 0;
      font-size: 51px;
      line-height: 48px;
    }
  `}
`;

export const SectiontMain = styled(Col)`
  flex: 1;
  padding-bottom: 10px;
  @media (min-width: 600px) {
    flex: 3;
    padding-right: 20px;
    padding-bottom: 0;
  }
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.visby};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    font-weight: 700;
    @media (min-width: 600px) {
      font-size: ${theme.font.size.subTitle};
    }
  `}
`;

export const TitleMiddle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.montserrat};
    font-size: ${theme.font.size.subTitle};
    line-height: ${theme.font.lineHeight.subTitle};
    color: ${theme.colors.primary};
    margin-bottom: 10px;
    @media (min-width: 600px) {
      font-weight: 700;
    }
  `}
`;

export const HighlightText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -1px;
    font-weight: 800;
    border-left: 8px solid rgba(0, 0, 0, 0.15);
    padding-left: 20px;
    @media (min-width: 600px) {
      font-size: 26px;
      line-height: 29px;
    }
  `}
`;

export const WrapperTitleImageBackground = styled.div`
  position: relative;
  display: inline-block;
  @media (min-width: 600px) {
    margin-bottom: 12px;
  }

  & ${Title} {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 12px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 10%;
    width: 85%;
    @media (min-width: 600px) {
      font-size: 4vw;
      line-height: 4vw;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 51px;
    }
  }
  & img {
    width: calc(100% + 20px);
    margin-left: -10px;
    @media (min-width: 600px) {
      width: calc(100% + 86px);
      margin-left: -43px;
      clip-path: inset(20% 0px 0px 0px);
      margin-top: -5rem;
    }
    @media (min-width: 1024px) {
      margin-top: -8rem;
    }
  }
`;
