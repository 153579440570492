import styled from 'styled-components';

const CustomText = styled.div`
  height: 100%;
  margin-top: auto;
  margin-bottom: 10px;
  color: #0e0d0d;
  font-size: 17px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
`;

export default CustomText;
