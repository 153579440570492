import styled, { css } from 'styled-components';

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    word-wrap: break-word;
  `};
`;

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    margin: 30px 0;
    color: ${theme.colors.p};
    span {
      color: ${theme.colors.primary};
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperBottom = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-left: 208px;
      padding-right: 215px;
    }
  `};
`;
