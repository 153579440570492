import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from 'context/auth';
import {
  Button
} from 'pages/BecomeVIP/styles';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_ragvin3S7SRJA86lMpLSl2d1');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const StripeButton = (props) => {
  const { loading, token } = useAuth0();

  const fetchCheckoutSession = async () => {
    const axios = require('axios');
    const result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/stripe-customer/create-session?interval=${props.interval}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {sessionId: result.data.id}
  }

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    const { sessionId } = await fetchCheckoutSession();
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };
  return (
    <Button role="link" onClick={handleClick}>
      {props.children}
    </Button>
  );
}

export default StripeButton
