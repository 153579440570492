import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 600px) {
  }
`;

export const WrapperTitle = styled.div`
  flex: 1;
  margin-bottom: 0;
  position: relative;
  padding: 0px;
  @media (min-width: 600px) {
    margin-bottom: 0;
    padding-left: 10px;
    padding-top: 15px;
    padding-right: 10px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 26px;
    line-height: 25px;
    font-weight: 800;
    color: ${theme.colors.primary};
    margin-bottom: 10px;
    @media (min-width: 600px) {
      padding: 0;
      font-size: 51px;
      line-height: 48px;
    }
  `}
`;

export const SubTitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.visby};
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    font-weight: 600;
    @media (min-width: 600px) {
      font-size: 18px;
    }
  `}
`;

export const SubTitle2 = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.font.family.visby};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: 700;
    @media (min-width: 600px) {
      font-size: ${theme.font.size.subTitle};
    }
  `}
`;

export const Avatar = styled.div`
  min-height: 150px;
  min-width: 150px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  margin-bottom: 30px;
  align-self: center;
  @media (min-width: 600px) {
    margin-bottom: 0px;
    align-self: left;
    margin-right: 10px;
  }
`;

export const WrapperWelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const WelcomeMessage = styled.div`
  align-self: center;
  background-color: white;
  border: 0;
  width: 100%;
  min-height: 150px;
  padding: 20px 15px;
  border-radius: 8px;
  align-self: right;
  position: relative;
  word-break: break-word;
  @media (min-width: 600px) {
    width: 90%;
    max-width: 750px;
    &:after {
      content: '';
      position: absolute;
      color: white;
      top: 44%;
      left: -23px;
      border-top: 20px solid white;
      border-top-color: inherit;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      transform: rotate(90deg);
    }
  }
`;

export const WrapperTitleImageBackground = styled.div`
  position: relative;
  border-radius: 10px;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  width: 100%;
  height: 180px;
  @media (min-width: 600px) {
    margin-bottom: 12px;
    height: 340px;
  }

  & ${Title} {
    color: white;
    position: absolute;
    z-index: 1;
    left: 6%;
    right: 0;
    bottom: 20%;
    width: 55%;
    @media (min-width: 600px) {
      font-size: 4vw;
      line-height: 4vw;
      bottom: 30%;
      width: 45%;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 51px;
      bottom: 30%;
      width: 45%;
    }
  }
`;

export const WrapperText = styled.div`
  ${() => css`
    margin-top: 10px;
    margin-bottom: 25px;
    border-radius: 14px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.4rem;
    @media (min-width: 600px) {
      padding: 1.5rem 4rem;
    }
  `}
`;
