import React from 'react';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  ContainerCourses,
  CourseCard,
  WrapperImage,
  WrapperInfo,
  Title,
  Description,
  WrapperInfoTime,
  ImageCard,
  LockedLayer,
  ImageLocker,
  ErrorMessage,
  IconCheck,
  ThumbUpIcon,
  WrapperTag,
} from 'pages/TheLab/Courses/styles';
import lockerImgSrc from 'assets/img/thelab/lockicon.png';
import TextTag from 'ui/Tag/TextTag';

const Courses = ({ courses }) => {
  const { isMobileView } = useWindowSize();
  const { history } = useReactRouter();

  const handleOnClick = ({ id, name, locked }) => {
    if (locked) {
      return null;
    }
    history.push({
      pathname: '/video',
      state: { moduleId: id, moduleName: name },
    });
  };
  return (
    <ContainerCourses isMobileView={isMobileView}>
      {courses.map(
        ({
          id,
          name,
          labCourses,
          shortDescription,
          coverImage,
          countLessons,
          locked,
          lockedText,
          recommended,
          countFinishedVideos,
        }) => (
          <CourseCard
            key={id}
            onClick={() => handleOnClick({ name, id, locked })}
            isLocked={locked}
            isMobileView={isMobileView}>
            <WrapperImage>
              {locked && (
                <LockedLayer>
                  <ErrorMessage className="video-error">
                    {lockedText}
                  </ErrorMessage>
                  <ImageLocker src={lockerImgSrc} className="locker-img" />
                </LockedLayer>
              )}
              <ImageCard src={coverImage?.url} />
            </WrapperImage>
            <WrapperInfoTime>
              {recommended ? (
                <>
                  <span>
                    {countFinishedVideos} of {countLessons}
                  </span>
                  <IconCheck />
                </>
              ) : (
                <span>
                  {countLessons} VIDEO{countLessons > 1 ? 'S' : ''}
                </span>
              )}
            </WrapperInfoTime>
            <WrapperInfo isLocked={locked}>
              <Title>{name}</Title>
              <Description>{shortDescription}</Description>
              {recommended && (
                <WrapperTag>
                  <TextTag>
                    RECOMMENDED LEARNING
                    <ThumbUpIcon />
                  </TextTag>
                </WrapperTag>
              )}
            </WrapperInfo>
          </CourseCard>
        )
      )}
    </ContainerCourses>
  );
};

Courses.propTypes = {
  courses: PropTypes.array,
};
export default Courses;
