import gql from 'graphql-tag';

export const UPDATE_DOTTERA_ACCOUNT = gql`
  mutation selfUpdateDoterraAccount(
    $backOfficeLink: String
    $memberId: String!
  ) {
    selfCreateDoterraAccount(
      input: { backOfficeLink: $backOfficeLink, memberId: $memberId }
    ) {
      id
      memberId
    }
  }
`;
