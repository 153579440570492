import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from 'context/auth';
import Loading from 'ui/Loading';
import useReactRouter from 'use-react-router';

const RouteWithLayout = props => {
  const {
    layout: Layout,
    component: Component,
    isPrivate,
    path,
    ...rest
  } = props;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { location } = useReactRouter();

  useEffect(() => {
    const validateUser = async () => {
      if (!isAuthenticated) {
        try {
          await loginWithRedirect({
            appState: { targetUrl: location.pathname },
          });
        } catch (e) {
          console.log('Login failed', e);
        }
      }
    };
    if (isPrivate) {
      validateUser();
    }
  }, [
    isAuthenticated,
    loginWithRedirect,
    path,
    isPrivate,
    rest.location.search,
  ]);

  if (isPrivate && !isAuthenticated) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  role: PropTypes.string,
};

export default RouteWithLayout;
