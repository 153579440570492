import { useState, useEffect } from 'react';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';
import {
  MEET_DOTERRA,
  LIVE_DOTERRA,
  SHARE_DOTERRA,
  BUILD_DOTERRA,
} from './files';

const useAdditionalResources = () => {
  const { oweStatus, positionStatus } = useTheFlow();
  const [fileList, setFileList] = useState([]);

  const { CUSTOMER, EXPLORING, BUILDER } = POSITION_STATUS;
  const { MEMBER, PREMEMBER, VIP } = MEMBER_STATUS;

  const preMemberContent = status => {
    if (status === EXPLORING) {
      return setFileList(MEET_DOTERRA);
    }
    setFileList([]);
  };

  const memberContent = status => {
    if (status === CUSTOMER) {
      return setFileList(LIVE_DOTERRA);
    }
    if (status === BUILDER) {
      return setFileList(SHARE_DOTERRA);
    }
    if(status === EXPLORING){
      return setFileList(SHARE_DOTERRA)
    }
    setFileList([]);
  };

  const vipContent = status => {
    if (status === BUILDER) {
      return setFileList(BUILD_DOTERRA);
    }
    setFileList([]);
  };

  useEffect(() => {
    switch (oweStatus) {
      case PREMEMBER:
        return preMemberContent(positionStatus);
      case MEMBER:
        return memberContent(positionStatus);
      case VIP:
        return vipContent(positionStatus);
      default:
        return setFileList([]);
    }
  }, [oweStatus, positionStatus]); // eslint-disable-line

  return {
    fileList,
    hasContentToShow: !!fileList.length,
  };
};

export { useAdditionalResources };
