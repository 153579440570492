function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const refChecker = token => {
  if (token) {
    console.log('RefChecker token Bearer', token);
    const ref = getCookie('drippl_ref_id');
    if (ref) {
      console.log('ref', ref);
      const axios = require('axios');
      axios({
        method: 'get',
        // url: `https://staging.api.drippl.com/referrals/selfcheckref/${ref}`,
        url: `${process.env.REACT_APP_API_HOST}/referrals/selfcheckref/${ref}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }
};

module.exports = refChecker;
