import styled from 'styled-components';
import ButtonReverse from 'ui/Button/Reverse';

const ButtonClean = styled(ButtonReverse)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.7);
  }
`;

export default ButtonClean;
