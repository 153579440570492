import styled, { css } from 'styled-components';

export const AdditionalContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 40px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 15px;
    @media (min-width: 600px) {
    }
  `};
`;

export const Title = styled.div`
  ${() => css`
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    @media (min-width: 600px) {
      font-size: 42px;
    }
  `};
`;

export const FilesWrapper = styled.div`
  ${() => css`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0;
    @media (min-width: 600px) {
      padding: 0 10rem;
    }
  `};
`;

export const FileWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 160px;
    cursor: pointer;
    margin-bottom: 20px;
    > span {
      margin-top: 10px;
      text-align: center;
    }
    margin-right: 20px;

    @media (min-width: 600px) {
      margin-right: 20px;
    }
  `};
`;

export const File = styled.div`
  ${() => css`
    width: 160px;
    height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.1rem;
    @media (min-width: 600px) {
    }
  `};
`;

export const FileImage = styled.div`
  ${({ src }) => css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${`url(${src})`};
    border: 1px solid rgba(0, 0, 0, 0.09);
    width: 100%;
    height: 100%;
    position: relative;
    @media (min-width: 600px) {
    }
  `};
`;

export const IconBox = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: -5px;
    top: -10px;
    background-color: ${theme.colors.primary};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    @media (min-width: 600px) {
    }
  `};
`;
