import styled, { css } from 'styled-components';
import TabMUI from '@material-ui/core/Tab';
import MUiTabs from '@material-ui/core/Tabs'; // TODO: style the tabs

export const Tabs = styled(MUiTabs)`
  && {
  }
`;

export const Container = styled.div`
  border: 0px solid #000;
  border-radius: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 600px) {
    max-width: 1027px;
  }
`;

export const TabHeader = styled.div`
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.11);
  @media (min-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const TabPanel = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  @media (min-width: 600px) {
    min-height: 300px;
  }
`;

export const Picture = styled.div`
  border: 5px solid #fff;
  width: 130px;
  height: 130px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
`;

export const Tab = styled(TabMUI)`
  ${() => css`
    && {
      span:first-child {
        font-weight: 800;
        text-transform: capitalize;
        font-size: 16px;
      }
    }
  `};
`;

export const WrapperLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
