import styled from 'styled-components';

export const Option = styled.option``;

export const Select = styled.select`
  display: block;
  font-size: 16px;
  color: #444;
  line-height: 29px;
  padding: 8px 30px 8px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#d8d8d8')};
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  appearance: none;
  outline: none;
  background-color: rgba(216, 216, 216, 0.2);
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, blue 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
`;

export default Select;
