import React from 'react';
import Modal from 'ui/Modal';
import {
  ContainerModalCancelSub,
  Title,
  SubTitle,
  Text,
  TextLeft,
  WrapperInput,
  TextAreaInput,
  ButtonCancel,
  GaveUpLink,
} from 'pages/Profile/ModalCancelSub/styles';
import RadioSet from 'ui/RadioSet';
import { useForm } from 'react-hook-form';

const ModalCancelSub = ({ isOpen, handleClose }) => {
  const { handleSubmit, register, setValue } = useForm();

  const onChange = e => setValue('reason', e.target.value);

  const onSubmit = async data => {
    console.log('data: ', data);
    // TODO: refecth
    handleClose();
  };

  return (
    <Modal
      openAfterSubmit={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      hideButtons>
      <ContainerModalCancelSub onSubmit={handleSubmit(onSubmit)}>
        <Title>Cancel Your Drippl Subscription</Title>
        {/* <Text>
          We get it – your needs can change! But before you say goodbye forever,
          did you know you can place your subscription on pause?
        </Text>
        <Text>
          With a paused subscription, you can still see the progress of anybody
          you have previously referred to the system and you will continue to
          receive any notifications of their progress. Then, when you’re ready
          to get back into the game, you’ll find everything exactly as you left
          it!
        </Text> */}
        <Text>
          Before you go we would love to find some way to meet your needs.
          If there is anything we can do to make sure Drippl delivers the value 
          you need, you can send an email to support@drippl.com and we will bend
          over backwards to meet your needs.
        </Text>
        <Text>
          We are sorry to see you go, but we get that circumstances change. Here are few things that will change if
          you choose to cancel your subscription:
        </Text>
        {/* <SubTitle>Pause Subscription Until:</SubTitle>
        <CancelLink>No Thanks, I would like to cancel</CancelLink> */}
        <TextLeft>
          1) Your custom referral link will be deactivated. <br />
          2) Drippl will no longer send referrals to your enrollment page.
          <br />
          3) You will lose visibility of your referrals’ progress within the
          system. <br />
          4) You will lose access to any video models in the system that require
          a VIP membership.
        </TextLeft>
        <SubTitle>
          What best describes the reason for your cancellation?
        </SubTitle>
        <WrapperInput>
          <RadioSet>
            <input
              name="reason"
              id="opt1"
              ref={register}
              type="radio"
              value="I’m no longer working on the business"
              onChange={onChange}
            />
            <label htmlFor="opt1">I’m no longer working on the business.</label>
          </RadioSet>
          <RadioSet>
            <input
              name="reason"
              id="opt2"
              ref={register}
              type="radio"
              value="I found a product that fits my needs better for attracting and
              enrolling prospective builders"
              onChange={onChange}
            />
            <label htmlFor="opt2">
              I found a product that fits my needs better for attracting and
              enrolling prospective builders.
            </label>
          </RadioSet>
          <RadioSet>
            <input
              name="reason"
              id="opt3"
              ref={register}
              type="radio"
              value="Too frequent communications from Drippl"
              onChange={onChange}
            />
            <label htmlFor="opt3">
              Too frequent communications from Drippl.
            </label>
          </RadioSet>
          <RadioSet>
            <input
              name="reason"
              id="opt4"
              ref={register}
              type="radio"
              value="The system didn’t deliver results as expected"
              onChange={onChange}
            />
            <label htmlFor="opt4">
              The system didn’t deliver results as expected.
            </label>
          </RadioSet>
          <RadioSet>
            <input
              name="reason"
              type="radio"
              value="Other"
              id="opt5"
              ref={register({ require: true })}
              onChange={onChange}
            />
            <label htmlFor="opt5">Other: </label>
          </RadioSet>
          <TextAreaInput name="otherReason" ref={register} />
        </WrapperInput>
        <ButtonCancel type="submit">Please Cancel My Subscription</ButtonCancel>
        <GaveUpLink onClick={handleClose}>
          Hold up! I changed my mind
        </GaveUpLink>
      </ContainerModalCancelSub>
    </Modal>
  );
};

export default ModalCancelSub;
