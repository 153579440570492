import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 293.7 293.7" className={props.className}>
      <g>
        <path
          d="M151.7,96.6c2.5-2.2,5-4,7.1-6.2c5.3-5.5,5.2-13.3,0.2-19.1c-14.9-17.1-34.2-26-56-30.2c-2.4-0.5-4.1-1.5-4.4-3.9
			c-0.4-2.5,0.8-4.2,3.1-5c25.3-8.9,51-12.7,77.4-5.1c13.5,3.9,24.5,11.7,34.1,21.9c6.8,7.3,13.9,14.2,20.9,21.3
			c2.9,2.9,2.9,4.7,0.4,7.8c-3.8,4.6-4.5,10.7-1.9,15.7c2.6,4.9,7.8,7.2,14.1,6.8c4.9-0.3,9.9-0.2,14.8,0.3c1.9,0.2,3.8,1.5,5.3,2.8
			c3.2,2.9,6.2,6,9.1,9.2c3.8,4,3.9,9.2,0.1,13.1c-12.6,12.9-25.2,25.8-37.9,38.5c-3.6,3.6-9,3.6-12.7,0.1c-3.3-3.2-6.7-6.4-9.6-9.9
			c-3.7-4.3-3.5-9.7-3.3-15c0.1-3.1,0.5-6.2,0.3-9.2c-0.3-5.3-3.1-9-8-10.8c-5.1-1.9-9.8-1-13.9,2.6c-2.3,2.1-4.4,4.4-6.1,6.1
			C173.9,118.1,163.1,107.7,151.7,96.6z"
        />
        <path
          d="M139.4,110.2c10.8,10.5,21.4,20.9,32.2,31.4c-0.9,1-1.7,1.8-2.5,2.6c-36.9,37.6-73.9,75.2-110.8,112.8
			c-6.4,6.5-12.5,6.5-18.9,0c-4.6-4.7-9.2-9.4-13.8-14.1c-5.2-5.4-5.2-11.9,0-17.2c37.5-38.1,75-76.3,112.5-114.4
			C138.6,110.8,139.2,110.4,139.4,110.2z"
        />
      </g>
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
