import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Topbar from 'ui/layouts/Minimal/Topbar';
import Sidebar from 'ui/layouts/Minimal/Sidebar';
import ContactBar from 'ui/ContactBar';
import TestEnrollmentBar from 'ui/TestEnrollmentBar';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import useBreakPoints from 'hooks/useBreakPoints';

import { Container, Content } from 'ui/layouts/Minimal/styles';

const Minimal = ({ children }) => {
  const { isDesktop, isMobile } = useBreakPoints();
  const { isShadowUserEnabled } = useTheFlow();

  const [openSidebar, setOpenSidebar] = useState(isDesktop);

  const handleSidebarOpen = useCallback(() => setOpenSidebar(true), []);
  const handleSidebarClose = useCallback(() => setOpenSidebar(false), []);
  const variant = useMemo(() => (isDesktop ? 'persistent' : 'temporary'), [
    isDesktop,
  ]);
  return (
    <Container>
      {isMobile && <Topbar handleSidebarOpen={handleSidebarOpen} />}
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={variant}
      />
      {isShadowUserEnabled && <TestEnrollmentBar />}
      <ContactBar />
      <Content>{children}</Content>
    </Container>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
