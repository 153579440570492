import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Left,
  Right,
  Title,
  Description,
  Button,
  ButtonInverted,
  PlayOutlineIcon,
  NumberWrapper,
} from 'pages/TheFlow/Steps/StepBox/styles';
import ContainerVideoThumb from 'pages/TheFlow/Steps/StepBox/VideoThumb';

const StepBox = ({
  title,
  description,
  buttonLabel,
  handleButton,
  isSemiActive,
  isVideoCard,
  videoInfo,
  isInvertedColour,
  cardNumber,
}) => {
  const renderButton = () => {
    if (isVideoCard) {
      return <ContainerVideoThumb info={videoInfo} />;
    }
    if (isInvertedColour) {
      return (
        <ButtonInverted onClick={handleButton}>{buttonLabel}</ButtonInverted>
      );
    }
    return (
      <Button
        isSemiActive={isSemiActive}
        onClick={handleButton}
        isInvertedColour={isInvertedColour}>
        {buttonLabel}
      </Button>
    );
  };

  const renderLeftContent = () => {
    if (videoInfo && videoInfo.currentVideoInfo) {
      const {
        name: nameCurrentVideo,
        description: descVideo,
      } = videoInfo.currentVideoInfo;

      return (
        <Left>
          <Title>
            {nameCurrentVideo}
            <PlayOutlineIcon />
          </Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: descVideo,
            }}
          />
        </Left>
      );
    }
    return (
      <Left>
        <Title>
          {title}
          {isVideoCard && <PlayOutlineIcon />}
        </Title>
        <Description>{description}</Description>
      </Left>
    );
  };

  return (
    <Container isVideoCard={isVideoCard}>
      {cardNumber ? (
        <NumberWrapper>
          <span>{cardNumber}</span>
        </NumberWrapper>
      ) : null}

      {renderLeftContent()}
      <Right>{renderButton()}</Right>
    </Container>
  );
};

StepBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleButton: PropTypes.func.isRequired,
  isSemiActive: PropTypes.bool,
  isVideoCard: PropTypes.bool,
  videoInfo: PropTypes.object,
  isInvertedColour: PropTypes.bool,
};

export default StepBox;
