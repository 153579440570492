import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  ModalStyled,
  Container,
  SectionFooter,
  ButtonSave,
  ButtonCancel,
} from 'ui/Modal/styles';

const Modal = ({
  isOpen,
  handleClose,
  children,
  onSubmit,
  isDisableSubmit = false,
  submitLabel,
  cancelLabel,
  openAfterSubmit = true,
  hideButtons = false,
  containerWidth = '700px',
}) => {
  const handleSubmit = () => {
    onSubmit();
    if (openAfterSubmit) {
      handleClose();
    }
  };

  return (
    <ModalStyled
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <Container containerWidth={containerWidth}>
          {children}
          {!hideButtons && (
            <SectionFooter>
              <ButtonCancel onClick={handleClose}>
                {cancelLabel || 'Cancel'}
              </ButtonCancel>
              {submitLabel && (
                <ButtonSave
                  disabled={isDisableSubmit}
                  isDisabled={isDisableSubmit}
                  onClick={handleSubmit}>
                  {submitLabel}
                </ButtonSave>
              )}
            </SectionFooter>
          )}
        </Container>
      </Fade>
    </ModalStyled>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isDisableSubmit: PropTypes.bool,
  openAfterSubmit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};
