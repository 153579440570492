import FileMeet1 from 'assets/addResources/meetDoterra/essential-oil-chemistry-handbook.pdf';
import FileMeet2 from 'assets/addResources/meetDoterra/product-guide-2020.pdf';
import FileMeet3 from 'assets/addResources/meetDoterra/healthy-can-be-simple.pdf';
import FileMeet4 from 'assets/addResources/meetDoterra/essential-oils-made-easy.pdf';

import FileLive1 from 'assets/addResources/liveDoterra/doTERRA-live-guide.pdf';
import FileLive2 from 'assets/addResources/liveDoterra/loyalty-rewards-program.pdf';
import FileLive3 from 'assets/addResources/liveDoterra/doTERRA-share-guide.pdf';
import FileLive4 from 'assets/addResources/liveDoterra/doTERRA-build-guide.pdf';

import FileShare1 from 'assets/addResources/liveDoterra/doTERRA-share-guide.pdf';
import FileShare2 from 'assets/addResources/liveDoterra/doTERRA-build-guide.pdf';
import FileShare3 from 'assets/addResources/shareDoterra/doTERRA-launch-guide.pdf';
import FileShare4 from 'assets/addResources/shareDoterra/compensation-plan.pdf';

import FileBuild1 from 'assets/addResources/liveDoterra/doTERRA-share-guide.pdf';
import FileBuild2 from 'assets/addResources/liveDoterra/doTERRA-build-guide.pdf';
import FileBuild3 from 'assets/addResources/shareDoterra/doTERRA-launch-guide.pdf';
import FileBuild4 from 'assets/addResources/buildDoterra/us-founders-club-2.0.pdf';

import CompenstionPlanImg from 'assets/addResources/thumbImg/compensation-plan.png';
import BuildGuideImg from 'assets/addResources/thumbImg/doTERRA-build-guide.png';
import LaunchGuideImg from 'assets/addResources/thumbImg/doTERRA-launch-guide.png';
import LiveGuideImg from 'assets/addResources/thumbImg/doTERRA-live-guide.png';
import ShareGuideImg from 'assets/addResources/thumbImg/doTERRA-share-guide.png';
import HandbookImg from 'assets/addResources/thumbImg/essential-oils-chemistry-handbook.png';
import MadeEasyImg from 'assets/addResources/thumbImg/essential-oils-made-easy.png';
import CanBeSimpleImg from 'assets/addResources/thumbImg/healthy-can-be-simple.png';
import RewardsProgramImg from 'assets/addResources/thumbImg/loyalty-rewards-program.png';
import ProductGuideImg from 'assets/addResources/thumbImg/product-guide-2020.png';
import UsFoundersImg from 'assets/addResources/thumbImg/us-founders-club.png';

// PREMEMBER/EXPLORING
const MEET_DOTERRA = [
  {
    fileName: 'Healthy Can Be Simple.pdf',
    fileSource: FileMeet3,
    fileThumbUrl: CanBeSimpleImg,
  },
  {
    fileName: 'Essential Oils Made Easy.pdf',
    fileSource: FileMeet4,
    fileThumbUrl: MadeEasyImg,
  },
  {
    fileName: 'Product Guide 2020.pdf',
    fileSource: FileMeet2,
    fileThumbUrl: ProductGuideImg,
  },
  {
    fileName: 'Essential Oil Chemistry Handbook.pdf',
    fileSource: FileMeet1,
    fileThumbUrl: HandbookImg,
  },
];
// MEMBER/CUSTOMER
const LIVE_DOTERRA = [
  {
    fileName: 'doTERRA Live Guide.pdf',
    fileSource: FileLive1,
    fileThumbUrl: LiveGuideImg,
  },
  {
    fileName: 'Loyalty Rewards Program.pdf',
    fileSource: FileLive2,
    fileThumbUrl: RewardsProgramImg,
  },
  {
    fileName: 'doTERRA Share Guide.pdf',
    fileSource: FileLive3,
    fileThumbUrl: ShareGuideImg,
  },
  {
    fileName: 'doTERRA Build Guide.pdf',
    fileSource: FileLive4,
    fileThumbUrl: BuildGuideImg,
  },
];
// MEMBER/BUILDER
const SHARE_DOTERRA = [
  {
    fileName: 'doTERRA Live Guide.pdf',
    fileSource: FileLive1,
    fileThumbUrl: LiveGuideImg,
  },
  {
    fileName: 'doTERRA Share Guide.pdf',
    fileSource: FileShare1,
    fileThumbUrl: ShareGuideImg,
  },
  {
    fileName: 'doTERRA Build Guide.pdf',
    fileSource: FileShare2,
    fileThumbUrl: BuildGuideImg,
  },
  {
    fileName: 'doTERRA Launch Guide.pdf',
    fileSource: FileShare3,
    fileThumbUrl: LaunchGuideImg,
  },
  {
    fileName: 'Compensation Plan.pdf',
    fileSource: FileShare4,
    fileThumbUrl: CompenstionPlanImg,
  },
];
// VIP/BUILDER
const BUILD_DOTERRA = [
  {
    fileName: 'doTERRA Live Guide.pdf',
    fileSource: FileLive1,
    fileThumbUrl: LiveGuideImg,
  },
  {
    fileName: 'doTERRA Share Guide.pdf',
    fileSource: FileBuild1,
    fileThumbUrl: ShareGuideImg,
  },
  {
    fileName: 'doTERRA Build Guide.pdf',
    fileSource: FileBuild2,
    fileThumbUrl: BuildGuideImg,
  },
  {
    fileName: 'doTERRA Launch Guide.pdf',
    fileSource: FileBuild3,
    fileThumbUrl: LaunchGuideImg,
  },
  {
    fileName: 'US Founders Club 2.0.pdf',
    fileSource: FileBuild4,
    fileThumbUrl: UsFoundersImg,
  },
];

export { MEET_DOTERRA, LIVE_DOTERRA, SHARE_DOTERRA, BUILD_DOTERRA };
