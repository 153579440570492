import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import {
  Container,
  SectionHeader,
  SectionBody,
  ButtonContinue,
  ButtonClose,
  SectionBottom,
  CloseModal,
} from 'pages/TheFlow/Steps/ShadowUserVipModal/styles';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';

const ShadowUserVipModal = ({ isOpen, handleClose }) => {
  const { handleSetShawdowUser } = useTheFlow();

  const onFinishExperience = async () => {
    await handleSetShawdowUser('off');
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} hideButtons>
      <Container>
        <CloseModal onClick={handleClose}>
          <span>X</span>
        </CloseModal>
        <SectionHeader>
          <h2>Congratulations! Your Referral is a VIP!</h2>
        </SectionHeader>
        <SectionBody>
          <p>
            This enrollment funnel has been designed to automate growing your
            business and to make it easier for your team members to get started
            quickly.
          </p>
          <br />
        </SectionBody>
        <SectionBottom>
          <ButtonClose onClick={handleClose}>Close</ButtonClose>
          <ButtonContinue onClick={onFinishExperience}>
            Finish Experience
          </ButtonContinue>
        </SectionBottom>
      </Container>
    </Modal>
  );
};

export default ShadowUserVipModal;

ShadowUserVipModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
