import {useEffect, useState} from 'react';

export const useCustomMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    // mediaMatch.addListener(handler);
    mediaMatch.addEventListener("change", handler)
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};