import gql from 'graphql-tag';

export const GET_RANKS = gql`
  query getAllRefferedBy {
    self {
      id
      rankGoal {
        id
        goalDate
        goalRank {
          id
          name
        }
        currentRank {
          id
          name
        }
      }
    }
    ranks(sort: "level") {
      id
      name
      qualification
      growthStrategy
      nextRank {
        id
        name
        qualification
      }
    }
  }
`;
