import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_MODULES } from 'graphql/queries/getModules';
import get from 'lodash/get';

const useTheLab = () => {
  const [modules, setModules] = useState([]);
  const { loading, error, data } = useQuery(GET_MODULES);

  useEffect(() => {
    if (data) {
      const res = get(data, 'selfLabModules', []);

      const modulesWithCount = res.map(module => {
        const countLessons = module.labCourses.reduce((acc, curr) => {
          const count = curr.labLessons.length;
          return acc + count;
        }, 0);

        const countFinishedVideos = module.labCourses.reduce(
          (finishedVideosCount, course) => {
            const courseVideosFinishedTotal = course.labLessons.reduce(
              (courseVideosFinished, lesson) => {
                const isFinished = get(
                  lesson,
                  'video.userVideos[0].isFinished',
                  false
                );
                return isFinished
                  ? courseVideosFinished + 1
                  : courseVideosFinished;
              }, 0);
            return finishedVideosCount + courseVideosFinishedTotal;
          }, 0);

        return {
          ...module,
          countLessons,
          countFinishedVideos,
        };
      });
      setModules(modulesWithCount);
    }
  }, [data]); // eslint-disable-line

  return {
    loading,
    error,
    data: modules,
  };
};

export default useTheLab;
