import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

export const ContainerVideoView = styled.div`
  ${({ theme, isMobileView }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 14px;
    border: 0px solid #000;
    @media (min-width: 600px) {
      width: ${theme.container.max};
      margin-bottom: 33px;
      padding: 28px 43px;
      ${isMobileView &&
        `
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 712px;
        min-width: 565px;
        background-color: ${theme.colors.white};
        border-radius: 14px;
        border: 0px solid #000;
      `}
    }
  `};
`;

export const SectionTitle = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0 10px;
    @media (min-width: 600px) {
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const Title = styled.h1`
  ${() => css`
    font-size: 22px;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 10px;
    @media (min-width: 600px) {
      font-size: 32px;
      line-height: 41px;
      margin-bottom: 0px;
    }
  `};
`;

export const SectionVideo = styled.div`
  ${({ isMobileView }) => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: flex-start;
      ${isMobileView &&
        `
        flex-direction: column;
        margin-top: 25px;
      `}
    }
  `};
`;

export const VideoList = styled.div`
  ${({ isMobileView }) => css`
    padding-bottom: 5px;
    order: 2;
    height: 340px;
    overflow-y: auto;
    @media (min-width: 600px) {
      height: 600px;
      width: 35%;
      order: 1;
      ${isMobileView &&
        `
        padding-bottom: 5px;
        order: 2;
        overflow-y: auto;
        width: 100%;
        height: 380px;
      `}
    }
  `};
`;

export const VideoCategory = styled.div`
  ${() => css`
    display: flex;
    height: auto;
    padding: 13px 17px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #4b4b4b;
    color: #fff;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItem = styled.div`
  ${({ isSelected }) => css`
    position: relative;
    display: flex;
    padding: 5px;
    background-color: ${isSelected ? '#c1e9e9' : '#e9e9e9'};
    transition: padding 200ms cubic-bezier(0.25, 0.1, 0.608, 2.354),
      background-color 300ms ease;
    height: 80px;
    margin-top: 5px;
    cursor: pointer;
    :hover {
      padding-left: 6px;
      background-color: #c1e9e9;
    }
    > svg {
      position: absolute;
      bottom: 10px;
      right: 7px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItemThumb = styled.div`
  ${() => css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    border: none;
    width: 86px;
    height: 70px;
    margin-right: 10px;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoItemDescription = styled.div`
  ${() => css`
    padding-top: 5px;
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 220px;
    @media (min-width: 600px) {
    }
  `};
`;

export const VideoDisplay = styled.div`
  ${({ isMobileView }) => css`
    background-color: #f0f0f0;
    order: 1;
    margin-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    @media (min-width: 600px) {
      position: inherit;
      width: 73%;
      height: 600px;
      order: 2;
      margin-bottom: 0px;
      margin-left: 5px;
      ${isMobileView &&
        `
        order: 1;
        margin-bottom: 10px;
        width: 100%;
        min-width: 465px;
        height: 100%;
        position: static;
      `}
    }
  `};
`;

export const ButtonTheFlow = styled(ButtonDefault)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  font-weight: 500;
  width: 150px;
  font-size: 14px;
  padding: 5px;
  position: relative;
  @media (min-width: 600px) {
    padding: 8px;
    letter-spacing: 1px;
    width: 130px;
  }
`;

export const WrapperVideo = styled.div`
  ${() => css`
    padding: 0;
    @media (min-width: 600px) {
      padding: 20px;
    }
  `};
`;

export const WrapperVideoDescription = styled.div`
  ${() => css`
    background-color: white;
    padding: 17px 25px 10px;
    p {
      color: #1f1f1f;
      line-height: 18px;
      font-weight: 300;
      font-size: 14px;
    }
    @media (min-width: 600px) {
      padding: 17px 25px 25px;
    }
  `};
`;

export const WrapperVideoTitle = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    h3 {
      color: #1f1f1f;
      font-weight: 600;
      max-width: 100%;
      font-size: 18px;
      line-height: 17px;
    }
    span {
      text-transform: uppercase;
      color: #a7a7a7;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;
      display: none;
    }
    @media (min-width: 600px) {
      h3 {
        font-size: 19px;
        line-height: 18px;
        max-width: 90%;
      }
      span {
        display: block;
      }
    }
  `};
`;

export const Description = styled.div`
  ${() => css`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    margin-bottom: 10px;
    display: none;

    @media (min-width: 600px) {
      -webkit-line-clamp: 4;
      display: inherit;
    }
  `};
`;

export const WrapperDescription = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    span {
      align-self: flex-end;
      font-size: 12px;
      cursor: pointer;
    }
    @media (min-width: 600px) {
      span {
        display: none;
      }
    }
  `};
`;

export const DescriptionFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
  }
`;

export const CtaButton = styled(ButtonDefault)`
  width: auto;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0 5px;
  @media (min-width: 600px) {
    padding: 5px 20px;
    margin: 10px 0px;
  }
`;

export const WrapperPlayer = styled.div`
  ${({ isMobileView }) => css`
    height: 100%;
    @media (min-width: 600px) {
      height: 397px;
      ${isMobileView &&
        `
        height: 100%;
      `}
    }
  `};
`;

export const ToggleCompleteButton = styled.button`
  ${({ isMobileView }) => css`
    padding: 5px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    margin: 7px 0 5px;
    > svg {
      margin-left: 7px;
    }
    ${isMobileView &&
      `
        display: none;
    `}
    @media (min-width: 600px) {
      width: 185px;
      align-self: flex-end;
      margin: 0;
      padding: 5px 20px;
      margin: 10px 0;
    }
  `};
`;

export const IconCheck = styled(CheckCircleRoundedIcon)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.border};
      @media (min-width: 600px) {
        font-size: 18px;
      }
    }
  `}
`;

export const IconCheckCompleted = styled(CheckCircleRoundedIcon)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.primary};
      @media (min-width: 600px) {
        font-size: 18px;
      }
    }
  `}
`;

export const IconCheckDescriptionCompleted = styled(CheckCircleRoundedIcon)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.primary};
      @media (min-width: 600px) {
        font-size: 28px;
      }
    }
  `}
`;

export const IconCheckDescription = styled(CheckCircleRoundedIcon)`
  ${() => css`
    && {
      fill: white;
      @media (min-width: 600px) {
        font-size: 28px;
      }
    }
  `}
`;
