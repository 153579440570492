import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { AppBar, Toolbar, Link } from 'ui/layouts/Minimal/Topbar/styles';
import DripplLogo from 'assets/img/logos/drippl_logo.png';

const Topbar = ({ handleSidebarOpen }) => (
  <AppBar position="fixed">
    <Toolbar>
      <Link to="/">
        <img alt="Logo" height="40px" src={DripplLogo} />
      </Link>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleSidebarOpen}
        edge="start">
        <MenuIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
);

Topbar.propTypes = {
  handleSidebarOpen: PropTypes.func.isRequired,
};

export default Topbar;
