import gql from 'graphql-tag';

export const UPDATE_POSITION_STATUS = gql`
  mutation updatePositionStatus($status: String!) {
    updatePositionStatus(input: { positionStatus: $status }) {
      id
      positionStatus
      oweStatus
    }
  }
`;
