import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 360 360" className={props.className}>
      <path
        d="M239.1,129.9c12.7-4.7,24.6-9.2,36.6-13.7c13-4.9,26-9.8,39-14.7c9.6-3.6,17,1.5,17,11.7
			c0,45.4-0.2,90.8,0.2,136.2c0.1,8.4-8.6,14.9-16.8,11.6c-23.9-9.5-48.2-18.2-72.4-27.2c-1.1-0.4-2.2-0.8-3.8-1.3c0,4.7,0,9,0,13.3
			c0,9.2,0,18.3,0,27.5c0,7-4.5,11.5-11.4,11.6c-6.6,0.1-11.1-4.4-11.1-11.5c0-40.9,0-81.8,0-122.8c0-12.3-0.2-24.6,0-36.9
			c0.2-8.5-6.4-14-13.9-13.9c-45.1,0.3-90.3,0.1-135.4,0.1c-8.4,0-13.5,5-13.5,13.4c0,45.1,0,90.3,0,135.4c0,8.6,4.8,13.4,13.4,13.4
			c35,0,70,0,105,0c4.4,0,8.4,0.8,11.4,4.3c3,3.5,3.6,7.5,1.6,11.8c-2.2,4.6-5.9,6.9-10.9,7c-19.1,0-38.1,0-57.2,0
			c-16.4,0-32.8,0-49.2,0c-18.3,0-32.3-11.1-36.2-29c-0.5-2.2-0.4-4.5-0.4-6.8c0-45.4,0.3-90.8-0.2-136.2
			C30.8,96.6,44.4,77.1,66.2,77c46-0.1,92-0.2,138,0.1c17.8,0.1,31.9,12.5,34.6,29.8c1,6,0.3,12.3,0.3,18.4
			C239.1,126.7,239.1,128,239.1,129.9z M309,128.5c-0.9,0.2-1.6,0.2-2.2,0.5c-21.8,8.2-43.5,16.4-65.3,24.5
			c-2.3,0.8-2.4,2.2-2.4,4.1c0,15.7,0.1,31.4,0,47.1c0,2.6,0.8,3.8,3.2,4.7c18,6.6,35.9,13.4,53.9,20.2c4.2,1.6,8.5,3.1,12.9,4.7
			C309,198.6,309,163.6,309,128.5z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
