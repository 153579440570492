import styled, { css } from 'styled-components';

const Button = styled.button`
  ${({ theme, isDisabled }) => css`
    color: white;
    font-size: 16px;
    font-weight: bold;
    background-color: ${
      isDisabled ? theme.colors.buttons.primary.disabled : theme.colors.primary
    };
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'}
    outline: none;
    transition: all 0.2s ease-out;
    ${!isDisabled &&
      `
      &:hover {
        background-color: ${theme.colors.buttons.primary.buttonshover};
        transform: translate(0px, -2px);
      }
   `}
  `}
`;

export default Button;
