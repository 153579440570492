import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'ui/layouts/Home/Navbar';
import { Container } from 'ui/layouts/Home/styles';

const Home = ({ children }) => (
  <Container>
    <NavBar />
    {children}
  </Container>
);

Home.propTypes = {
  children: PropTypes.node,
};

export default Home;
