import React from 'react';
import {
  SectionInput,
  WrapperInput,
  ButtonSubmit,
  WrapperButtons,
} from './styles';
import Text from 'ui/Text';
import InputText from 'ui/InputText';

const WellnessAdvocateConfirmation = ({
  memberId,
  handleUpdateMemberId,
  handleOpenCancelModal,
  onSubmit,
}) => (
  <div style={{ maxWidth: '100%' }}>
    <Text>
      To start automating your online business, we need your dōTERRA ID.
    </Text>
    <SectionInput>
      <WrapperInput>
        <label>doTERRA ID</label>
        <InputText value={memberId} onChange={handleUpdateMemberId} />
      </WrapperInput>
    </SectionInput>

    <WrapperButtons>
      <ButtonSubmit
        onClick={onSubmit}
        disabled={!memberId}
        isDisabled={!memberId}>
        Confirm Membership
      </ButtonSubmit>

      <div onClick={handleOpenCancelModal}>Cancel</div>
    </WrapperButtons>
  </div>
);

export default WellnessAdvocateConfirmation;
