import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';

export const ContainerSignUpForm = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    text-align: center;
    margin: 30px 0;
    h1 {
      color: ${theme.colors.primary};
      margin-bottom: 30px;
      font-size: 42px;
      line-height: 42px;
      font-weight: 700;
    }
    h3 {
      color: ${theme.colors.body};
      font-weight: 500;
      padding: 0 40px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionForm = styled.form`
  ${({ theme }) => css`
    margin-bottom: 60px;
    padding: 40px 30px;
    background-color: #f9f9f9;
    border: 1px solid ${theme.colors.border};
    border-radius: 8px;
    width: 90%;
    h2 {
      color: ${theme.colors.primary};
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      padding-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.border};
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
      margin-bottom: 60px;
      padding: 40px 60px;
      width: 600px;
      h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > label {
      flex: 1;
      padding-bottom: 10px;
    }
    @media (min-width: 600px) {
      display: flex;
      flex-direction: row;
      > label {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
      }
      div {
        display: flex;
        flex-direction: column;
        flex: 2.1;
        input[type='text'],
        select {
          width: 100%;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  `};
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    @media (min-width: 600px) {
      height: 1px;
      border-bottom: 1px solid ${theme.colors.border};
      margin: 20px 0;
    }
  `};
`;
export const Button = styled(ButtonDefault)`
  ${() => css`
    padding: 15px 0;

    @media (min-width: 600px) {
      font-size: 28px;
      border-radius: 45px;
      padding: 15px 0;
    }
  `};
`;

export const Select = styled.select`
  display: block;
  font-size: 16px;
  color: #444;
  line-height: 29px;
  padding: 8px 30px 8px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#d8d8d8')};
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  appearance: none;
  outline: none;
  background-color: rgba(216, 216, 216, 0.2);
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, blue 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
`;

export const Option = styled.option``;

export const TextError = styled.div`
  text-align: left;
  color: red;
  padding: 20px 0;
  ${() => css`
    @media (min-width: 600px) {
    }
  `};
`;
