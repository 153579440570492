import styled, { css } from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const SectionAttachment = styled.div`
  ${() => css`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 22px;
      font-size: 20px;
      font-weight: 600;
      padding-left: 10px;
    }
    @media (min-width: 600px) {
      h3 {
        padding-left: 0;
      }
    }
  `};
`;

export const WrapperTitle = styled.div`
  ${() => css`
    display: flex;
    position: relative;
    > h3 {
      padding-left: 25px;
    }
    @media (min-width: 600px) {
      > h3 {
        padding-left: 0px;
      }
    }
  `};
`;

export const WrapperAttachment = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background-color: #f0f0f0;

    padding: 30px 0;

    @media (min-width: 600px) {
      padding: 30px 20px;
      min-height: 220px;
      flex-direction: row;
    }
  `};
`;

export const Attachment = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 93%;
    margin: 12px 0;
    padding: 10px 13px;
    align-items: center;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    background-color: ${theme.colors.white};
    transform: translate(0px, 0px);
    transition: border-color 200ms ease,
      transform 200ms cubic-bezier(0.25, 0.1, 0.632, 2.096);
    text-decoration: none;
    :hover {
      outline: 0;
      border-color: 1px solid ${theme.colors.primary};
      background-color: #e7ffff;
      transform: translate(0px, -3px);
    }
    span {
      font-size: 14px;
      color: #1f1f1f;
      line-height: 18px;
      font-weight: 300;
      text-align: center;
      text-decoration: none;
      margin-left: 5px;
    }
    @media (min-width: 600px) {
      width: 20%;
      flex-direction: column;
      margin: 0 16px;
      padding: 22px;
      align-self: stretch;
      margin-bottom: 30px;
      span {
        margin-top: 12px;
        font-size: 16px;
      }
    }
  `};
`;

export const File = styled.div`
  ${() => css`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    @media (min-width: 600px) {
      width: 50px;
      height: 50px;
      margin-left: 0;
    }
  `};
`;

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: auto;
    top: 16%;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 30px;
    height: 30px;
    margin-top: -6px;
    margin-right: -6px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: ${theme.colors.primary};
    color: #fff;
    font-size: 12px;
    @media (min-width: 600px) {
      top: 0%;
      right: 0%;
    }
  `};
`;

export const WrapperHide = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 6%;
    top: 18%;
    bottom: auto;
    display: flex;
    width: 85px;
    margin-top: -6px;
    margin-right: -6px;
    padding: 0;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    background-color: ${theme.colors.primary};
    padding: 3px;
    border-radius: 25px;
    font-weight: bolder;
    @media (min-width: 600px) {
      display: none;
    }
  `};
`;

export const MobileSupportFiles = styled.div`
  ${() => css`
    margin-top: 10px;
    @media (min-width: 600px) {
      display: none;
    }
  `};
`;

export const SectionAttachmentMobile = styled(AnchorLink)`
  ${() => css`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    :visited {
      color: black;
    }
  `};
`;
