import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    @media (min-width: 600px) {
      width: 700px;
      overflow-y: none;
    }
  `}
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: center;
    padding: 0 40px;
    line-height: 29px;
    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
    }
    @media (min-width: 600px) {
      h2 {
        font-size: 32px;
        line-height: 38px;
      }
    }
  `};
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  margin: 25px 0;
  p {
    text-align: center;
    font-size: 18px;
  }
  @media (min-width: 600px) {
    padding: 0 45px;
    p {
      padding: 0px 80px;
    }
  }
`;
