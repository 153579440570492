import React from 'react';
import {
  Container,
  WrapperImage,
  LogoImage,
  WrapperTitle,
  Title,
  Subtitle,
} from 'ui/WelcomeHeader/styles';
import dropImage from 'assets/img/theflow/drop.png';

const WelcomeHeader = () => (
  <Container>
    <WrapperImage>
      <LogoImage src={dropImage} />
    </WrapperImage>
    <WrapperTitle>
      <Title>Welcome to Drippl!</Title>
      <Subtitle>Meet, Live & Share dōTERRA... Simply</Subtitle>
    </WrapperTitle>
  </Container>
);

export default WelcomeHeader;
