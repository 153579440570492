import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import Input from 'ui/InputText';
import Select, { Option } from 'ui/SelectInput';
import { WrapperInput } from 'pages/Profile/styles';
import useProfile from 'pages/Profile/data/useProfile';
import ModalUploadAvatar from 'ui/ModalUploadAvatar';
import useSetup from 'pages/TheFlow/data/useSetup';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  SectionForm,
  UploadFormWrapper,
  SectionPicture,
  Button,
  Avatar,
  UploadAvatarButton,
  SectionInputFields,
  Left,
  Right,
  TextAreaInput,
} from 'pages/Profile/ProfileForm/styles';
import { ModalPreviewGreeting } from '../ModalPreviewGreeting';

const ProfileForm = ({ data }) => {
  const { firstName, lastName, phone } = data;

  const {
    register,
    handleSubmit,
    errors,
    formState,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName,
      lastName,
      phone,
      welcomeMessage: get(data, 'userCustomization.welcomeMessage', '') || '',
    },
  });

  const { handleSubmitForm } = useProfile();

  const [tel, setTel] = useState('');
  const [regionId, setRegionId] = useState('');
  const [inputErrors, setInputErrors] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [userAvatar, setUserAvatar] = useState('');
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const { regionsOptions } = useSetup();

  const [regionText, setRegionText] = useState('')

  const handleOpenPreview = () => setIsOpenPreview(true);

  const handleClosePreview = () => setIsOpenPreview(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => setIsOpen(true);

  const handlePhoneChange = e => {
    setTel(e.target.value);
    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }
  };

  const onSubmit = async formData => {
    reset(formData, {
      dirtyFields: false,
      errors: false,
      dirty: true,
      isSubmitted: true,
      touched: true,
      isValid: true,
      submitCount: true,
    });
    setIsSaveDisabled(true);
    const payload = { ...formData, phone: tel, regionId };
    await handleSubmitForm(payload);
  };

  useEffect(()=>{
    const region = regionsOptions.filter(r=>r.value === regionId)
    console.log(region)
    if(region.length > 0){
      setRegionText(region[0].text)
    }
    
  }, [regionsOptions])

  useEffect(() => {
    if (errors) {
      const fieldErros = Object.entries(errors).map(([key]) => key);
      setInputErrors(fieldErros);
    }
  }, [errors]);

  useEffect(() => {
    const { dirtyFields } = formState;

    if (isSaveDisabled && !isEmpty(dirtyFields)) {
      setIsSaveDisabled(false);
    }
  }, [formState]);

  useEffect(() => {
    if (data) {
      const { region } = data;
      setTel(phone);
      setRegionId(region?.id);
      if (data.profilePicture) {
        setUserAvatar(data.profilePicture);
      }
    }
  }, [data]);

  return (
    <SectionForm onSubmit={handleSubmit(onSubmit)}>
      <SectionPicture>
        <UploadFormWrapper>
          {userAvatar && <Avatar src={userAvatar} />}
          <UploadAvatarButton onClick={handleOpen}>
            Update Picture
          </UploadAvatarButton>
        </UploadFormWrapper>
      </SectionPicture>

      <SectionInputFields>
        <Left>
          <h2>Personal Info</h2>
          <WrapperInput>
            <label>First Name</label>
            <div>
              <Input
                name="firstName"
                hasError={inputErrors.includes('firstName')}
                ref={register({ required: true })}
              />
            </div>
          </WrapperInput>
          <WrapperInput>
            <label>Last Name</label>
            <div>
              <Input
                name="lastName"
                hasError={inputErrors.includes('lastName')}
                ref={register({ required: true })}
              />
            </div>
          </WrapperInput>
          <WrapperInput>
            <label>Email</label>
            <div>
              <Input name="email" isDisabled disabled value={data.email} />
            </div>
          </WrapperInput>
          <WrapperInput>
            <label>Phone Number</label>
            <div>
              <MaskedInput
                value={tel}
                mask="(999) 999-9999"
                alwaysShowMask
                onChange={handlePhoneChange}>
                {() => (
                  <Input
                    name="phone"
                    inputRef={register()}
                    hasError={inputErrors.includes('phone')}
                  />
                )}
              </MaskedInput>
            </div>
          </WrapperInput>
          <WrapperInput>
            <label>Region</label>
            <div>
              {/* {regionId ? (
                <Select
                  ref={register({
                    required: true,
                  })}
                  defaultValue={regionId}
                  onChange={e => setRegionId(e.target.value)}
                  name="regionId">
                  {regionsOptions.map(item => (
                    <Option
                      key={item.value}
                      value={item.value}
                      defaultValue={item.value === regionId}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
              ) : null} */}
              {regionText}
            </div>
          </WrapperInput>
        </Left>
        <Right>
          <h2>Personal Greeting</h2>
          <WrapperInput>
            <TextAreaInput
              name="welcomeMessage"
              hasError={inputErrors.includes('welcomeMessage')}
              ref={register({
                maxLength: { value: 1000 },
              })}
            />
          </WrapperInput>
          <Button type="button" onClick={handleOpenPreview}>
            Preview Greeting
          </Button>
        </Right>
      </SectionInputFields>

      <Button
        disabled={isSaveDisabled}
        isDisabled={isSaveDisabled}
        type="submit">
        Save Changes
      </Button>
      <ModalUploadAvatar
        isOpen={isOpen}
        handleClose={handleClose}
        profilePicture={userAvatar}
        setProfileImage={setUserAvatar}
      />
      <ModalPreviewGreeting
        isOpen={isOpenPreview}
        handleClose={handleClosePreview}
        welcomeMessage={getValues('welcomeMessage')}
        profilePicture={userAvatar}
      />
    </SectionForm>
  );
};

export default ProfileForm;
