import gql from 'graphql-tag';

export const GET_PROFILE_INFO = gql`
  query getProfileInfo {
    self {
      id
      firstName
      lastName
      email
      profilePicture
      positionStatus
      oweStatus
      phone
      userCustomization {
        id
        welcomeMessage
      }
      rankGoal {
        id
        currentRank {
          id
          name
        }
      }
      referredBy {
        id
        fullName
      }
      doterraAccount {
        id
        rank
        backOfficeLink
        memberId
      }
      region {
        id
        name
        monthSubscription
        yearSubscription
        monthSubscriptionDiscount
        yearSubscriptionDiscount
        prefix
        suffix
      }
    }
  }
`;
