import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';

export const ButtonSubmit = styled(ButtonDefault)`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  span {
    margin-right: 8px;
  }
  @media (min-width: 600px) {
    margin-top: 0;
    width: 45%;
  }
`;

export const SectionInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 32rem;
    button {
      margin-top: 20px;
      width: 100%;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      button {
        margin-top: 20px;
        width: 220px;
      }
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    label {
      font-weight: bolder;
    }
    input {
      margin-top: 10px;
      width: 100%;
    }
    @media (min-width: 600px) {
      input {
        margin-top: 10px;
        width: 250px;
      }
    }
  `};
`;

export const WrapperButtons = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > div {
      margin-top: 20px;
      cursor: pointer;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: center;
      > div {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  `};
`;
