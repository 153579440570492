import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    height: 60px;
    display: flex;
    width: 100%;
    background-color: #f9cac7;
    position: sticky;
    top: 0;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 20px;
    z-index: 999;
    span {
      text-transform: capitalize;
      margin-left: 10px;
      font-weight: 800;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
    @media (min-width: 600px) {
      span {
        font-size: 16px;
      }
    }
  `};
`;

export const Button = styled.button`
  ${() => css`
    padding: 10px 10px;
    margin-left: 2px;
    border-radius: 25px;
    border: 0;
    min-width: 90px;
    font-weight: 600;
    font-size: 12px;
    outline: none;
    transition: background-color 300ms ease-in;
    background-color: white;
    cursor: pointer;
    &:hover {
      background-color: #eff5f3;
    }
    @media (min-width: 600px) {
      margin-left: 20px;
      font-size: 14px;
    }
  `};
`;
