import gql from 'graphql-tag';

export const UPDATE_MEMBER_STATUSES = gql`
  mutation updateMemberStatuses($oweStatus: String!, $positionStatus: String!) {
    updateMemberStatuses(
      input: { oweStatus: $oweStatus, positionStatus: $positionStatus }
    ) {
      id
      oweStatus
      positionStatus
      doterraAccount {
        id
        memberId
      }
    }
  }
`;
