import styled, { css } from 'styled-components';
import { Col, Row } from 'ui/layouts';
import IdCard from 'ui/icons/IdCard';
import LinkButton from 'ui/Button/LinkButtonDefault';

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > label {
      flex: 1;
      padding-bottom: 10px;
      font-weight: bold;
    }
    @media (min-width: 600px) {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      > label {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        padding-right: 20px;
      }
      div {
        display: flex;
        flex-direction: column;
        flex: 2.1;
        input[type='text'],
        select {
          width: 100%;
          height: 45px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  `};
`;

export const ContactButton = styled.button`
  font-size: 14px;
  cursor: pointer;
  width: 210px;
  height: 40px;
  border: solid 1px #e4e4e4;
  border-radius: 2px;
  background-color: #fff;
  transform: translate(0px, 0px);
  transition: all 200ms ease;
  color: #585858;
  font-weight: 300;
  text-align: center;
  &:hover {
    background-color: #f0f0f0;
    transform: translate(0px, -3px);
    transition: all 200ms ease;
  }
`;

export const InfoReasons = styled.div`
  ${() => css`
    margin-left: 0;
    @media (min-width: 600px) {
      margin-left: 3rem;
    }
  `};
`;

export const Subtitle = styled.h3`
  margin-bottom: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #5ed6d5;
  font-size: 23px;
`;

export const Text = styled.p`
  margin-bottom: 19px;
  color: ${({ color }) => (color ? `${color}` : '#0e0d0d')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  line-height: 25px;
  font-weight: 500;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const ProductBox = styled(Row)`
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const Product = styled(Col)`
  padding: 0 20px 10px;
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 0.8);
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
  margin-right: 20px;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
`;

export const ProductLabel = styled.div`
  background-color: #f3cb64;
  font-size: 15px;
  line-height: 30px;
  border-radius: 200px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  padding: 1px 18px;
  transform: translateY(-50%);
`;

export const ProductImage = styled(Row)`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  border: none;
  height: 139px;
  width: 218px;
  background-image: ${({ src }) => `url(${src})`};
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '32px' : null)};
`;

export const PriceBox = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const Price = styled.span`
  font-size: 20px;
  text-decoration: line-through;
`;

export const PriceDescount = styled.div`
  padding-left: 10px;
  font-size: 2.1vw;
  font-weight: 700;
  span {
    font-size: 1.2vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6.1vw;
    span {
      font-size: 6.2vw;
    }
  }
`;

export const ProductDescription = styled.span`
  font-size: 20px;
  margin-top: 20px;
`;

export const ProductPrice = styled.div``;

export const ProductPriceDescount = styled.span`
  font-size: 14px;
  color: #0e0d0d;
  margin-top: 15px;
`;

export const ProductButton = styled(LinkButton)`
  margin: 15px 0px;
  font-size: 19px;
  text-transform: uppercase;
  width: 100%;
  height: 52px;
  text-align: center;
  align-self: center;
  line-height: 52px;
  font-weight: 500;
`;

export const EnrollSection = styled(Col)`
  p {
    padding: 0 10%;
  }
  button {
    width: 60%;
  }
  > div {
    display: block;
    margin: 15px 0;
  }
`;

export const StepSection = styled(Col)`
  margin: 40px 0px 30px 0px;
`;

export const StepRow = styled(Row)`
  justify-content: space-between;
  margin: 30px 0 20px;
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const ChooseBox = styled.div`
  position: relative;
  display: flex;
  width: 30%;
  margin-right: 27px;
  margin-left: 27px;
  padding: 50px 27px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px #d1d1d1;
  border-radius: 14px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StepBox = styled(ChooseBox)`
  width: 23%;
  margin: 0px;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const SeeDetails = styled(Text)`
  ${() => css`
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    color: #2d65d1;
    text-decoration: underline;
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperVideo = styled.div`
  ${() => css`
    padding: 0;
    height: 200px;
    flex: 1;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      height: 350px;
      padding: 0 15%;
      margin-bottom: 40px;
    }
  `};
`;

export const WarningBox = styled.div`
  margin: 0px;
  align-self: center;
  background-color: #f15c6e;
  padding: 10px;
  display: flex;
  flex-direction: column;

  div {
    font-size: 12px;
    line-height: 22px;
    color: white;
    font-weight: bolder;
    margin: 0 20px;
  }

  button {
    width: 40%;
    padding: 7px;
    align-self: center;
    margin: 8px;
    background-color: #ff8c9e;
    cursor: pointer;
    border: 0;
    color: white;
    font-weight: bold;
  }
  @media (min-width: 600px) {
    div {
      font-size: 16px;
      line-height: 18px;
      flex: 1;
    }

    button {
      width: 20%;
      padding: 7px;
      align-self: center;
      margin: 8px;
    }

    font-size: 16px;
    margin: 15px;
    background-color: #f15c6e;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 550px;
  }
`;

export const CardIcon = styled(IdCard)`
  ${() => css`
    && {
      top: 11px;
      font-size: 32px;
      fill: white;
      display: none;
      opacity: 0.7;
      transform: rotate(333deg);
      font-weight: bold;
      @media (min-width: 600px) {
        display: block;
      }
    }
  `}
`;
