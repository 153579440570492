import React from 'react';
import ReactDOM from 'react-dom';
import AppProviders from './context';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import App from './App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const browserHistory = createBrowserHistory();

const trackingId = `${process.env.REACT_APP_GA_ID || 'UA-176568043-1'}`; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
// ReactGA.set({
//   // userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

// Initialize google analytics page view tracking
browserHistory.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


Sentry.init({
  dsn:
    'https://3dbf0bc2e9904ee3b487c9af4d58e9df@o391803.ingest.sentry.io/5241539',
  environment: process.env.NODE_ENV,
});
console.log(process.env.NODE_ENV);
ReactDOM.render(
  <Router history={browserHistory}>
    <AppProviders>
      <App />
    </AppProviders>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
