import React, { useState, useEffect } from 'react';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import { useWindowSize } from 'hooks/useWindowSize';

import {
  SectionAttachment,
  WrapperAttachment,
  WrapperTitle,
  Attachment,
  WrapperIcon,
  File,
  MobileSupportFiles,
  SectionAttachmentMobile,
  WrapperHide,
} from './styles';

import fileImgSrc from 'assets/img/thelab/file.png';

const useShowSupportFiles = () => {
  const { isMobileView } = useWindowSize();
  const [showSupportFiles, setShowSupportFiles] = useState(true);

  useEffect(() => {
    setShowSupportFiles(!isMobileView);
  }, [isMobileView]);

  return { showSupportFiles, setShowSupportFiles };
};

const SupportFiles = ({
  currentLesson,
  showSupportFiles,
  setShowSupportFiles,
}) => {
  const showFiles =
    currentLesson && currentLesson.attachments.length && showSupportFiles;

  const hideSupportFilesMobile = () => setShowSupportFiles(false);

  return (
    <div id="support-section">
      {Boolean(showFiles) && (
        <SectionAttachment>
          <WrapperTitle>
            <h3>Supporting Files</h3>
            <WrapperHide onClick={hideSupportFilesMobile}>
              Hide Files
            </WrapperHide>
          </WrapperTitle>
          <WrapperAttachment>
            {currentLesson.attachments.map(attachment => (
              <Attachment
                key={attachment.name}
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer">
                <WrapperIcon>
                  <ArrowDownwardRoundedIcon size="small" />
                </WrapperIcon>
                <File src={fileImgSrc} />
                <span>{attachment.label}</span>
              </Attachment>
            ))}
          </WrapperAttachment>
        </SectionAttachment>
      )}
    </div>
  );
};

const SupportFilesMobile = ({ currentLesson, setShowSupportFiles }) => {
  const handleShowSupportFilesMobile = () => {
    setShowSupportFiles(true);
  };

  return (
    <MobileSupportFiles
      isHidden={currentLesson && currentLesson.attachments.length}>
      {currentLesson && currentLesson.attachments.length ? (
        <SectionAttachmentMobile
          href="#support-section"
          onClick={handleShowSupportFilesMobile}>
          <strong>
            {`Supporting Files (${currentLesson.attachments.length})`}
          </strong>
          <WrapperIcon>
            <ArrowDownwardRoundedIcon size="small" />
          </WrapperIcon>
        </SectionAttachmentMobile>
      ) : null}
    </MobileSupportFiles>
  );
};
export { SupportFiles, SupportFilesMobile, useShowSupportFiles };
