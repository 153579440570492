import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  Container,
  InfoRow,
} from 'pages/TheFlow/UserUtils/ProFusionStats/styles';
import { Header } from 'pages/TheFlow/UserUtils/styles';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import useDripplStats from 'pages/TheFlow/UserUtils/data/useDripplStats';
import Loading from 'ui/Loader';

const DEFAULT_STATS = {
  referralLinkClicks: 0,
  contactsInFunnel: 0,
  wholeSaleCustomers: 0,
  wellnessAdvocates: 0,
};
const ProFusionStats = () => {
  const { data, loading } = useDripplStats();
  const [stats, setStats] = useState(DEFAULT_STATS);

  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <Header>
        <span>Drippl Stats</span> <EqualizerRoundedIcon fontSize="small" />
      </Header>
      <Container>
        <InfoRow>
          <span>Referral Link Clicks:</span>
          <span>{stats.referralLinkClicks}</span>
        </InfoRow>
        <InfoRow>
          <span>Contacts in my Funnel:</span>
          <span>{stats.contactsInFunnel}</span>
        </InfoRow>
        <InfoRow>
          <span>Wholesale Customers Enrolled:</span>
          <span>{stats.wholeSaleCustomers}</span>
        </InfoRow>
        <InfoRow>
          <span>Wellness Advocates Enrolled:</span>
          <span>{stats.wellnessAdvocates}</span>
        </InfoRow>
      </Container>
    </Wrapper>
  );
};

export default ProFusionStats;
