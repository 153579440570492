import gql from 'graphql-tag';

export const GET_MEMBER_REFER = gql`
  query getMemberRefer {
    self {
      id
      oweStatus
      firstName
      doterraAccount {
        id
        memberId
      }
      shadowUser {
        id
      }
      referralLinks {
        id
        link
        label
      }
      referredBy {
        id
        fullName
        email
        profilePicture
      }
    }
  }
`;
