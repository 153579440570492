import gql from 'graphql-tag';

export const UPDATE_OWE_STATUS = gql`
  mutation updateOWEStatus($status: String!) {
    updateOWEStatus(input: { oweStatus: $status }) {
      id
      oweStatus
    }
  }
`;
