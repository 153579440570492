import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 0;
    padding-left: 5px;
  }
`;

export const NumberedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
  div {
    flex: 0.06;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: white;
      border: 1px solid #5ed6d5;
      border-radius: 50%;
      color: #5ed6d5;
      font-weight: 800;
    }
  }
  p {
    flex: 0.94;
    color: rgb(121, 121, 121);
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    width: 70%;
  }
`;
