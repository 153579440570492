import React, { useState, Fragment } from 'react';
import {
  Container,
  TabHeader,
  TabPanel,
  Tabs,
  Tab,
  WrapperLoading,
} from 'pages/Profile/TabsInfo/styles';
import ProfileForm from 'pages/Profile/ProfileForm';
import DoTerraForm from 'pages/Profile/DoTerraForm';
import SubForm from 'pages/Profile/SubForm';
import useProfie from 'pages/Profile/data/useProfile';
import Loading from 'ui/Loader';
import { MEMBER_STATUS } from 'config/constants';

const TabsInfo = () => {
  const { data, loading, oweStatus, doterraId } = useProfie();
  const [tab, setTab] = useState(0);

  const handleChange = (_, value) => setTab(value);

  const showDoterraTab = !!doterraId;
  const showSubForm = MEMBER_STATUS.VIP === oweStatus;

  return loading ? (
    <WrapperLoading>
      <Loading />
    </WrapperLoading>
  ) : (
    <Container>
      <TabHeader>
        <Tabs
          value={tab}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00d1d0',
              height: 3,
            },
          }}>
          <Tab value={0} label="Account Info" />
          {showDoterraTab && <Tab value={1} label="dōTERRA Info" />}
        </Tabs>
      </TabHeader>
      <TabPanel isVisible={tab === 0}>
        <Fragment>
          <ProfileForm data={data} />
          {showSubForm && <SubForm data={data} />}
        </Fragment>
      </TabPanel>
      <TabPanel isVisible={tab === 1}>
        <DoTerraForm data={data} />
      </TabPanel>
    </Container>
  );
};

export default TabsInfo;
