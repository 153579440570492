const MEMBER_STATUS = {
  CUSTOMER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemPending: true },
    AUTOMATE: {},
  },
  PENDING_BUILDER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemComplete: true },
    AUTOMATE: { itemPending: true },
  },
  BUILDER: {
    EXPLORE: { itemComplete: true },
    TRY: { itemComplete: true },
    PREPARE: { itemComplete: true },
    AUTOMATE: { itemPending: true },
  },
  EXPLORING: {
    EXPLORE: { itemComplete: true },
    TRY: { itemPending: true },
    PREPARE: {},
    AUTOMATE: {},
  },
};

export const MEMBER_PAGE = {
  CUSTOMER: {
    header: {
      title: 'Live dōTERRA',
      subtitle:
        'With so many great options, \n where do you start?',
      text: '',
      videoUrl: '',
      stepStatus: MEMBER_STATUS.CUSTOMER,
    },
    body: {
      title: '',
      text:
        ' In the Live dōTERRA series, we help you discover easy and powerful ways to incorporate oils into your daily life as you address your primary health concerns. If you haven’t already, you’ll also learn how to claim free products and never run short on your favorites through the Loyalty Rewards Program (LRP). \n Once you gain a passion for the products, you may find yourself naturally sharing with others. If you haven’t already, upgrade your dōTERRA membership to Wellness Advocate to earn well-deserved compensation for helping others discover holistic, self-directed healthcare. It’s free and does not require any additional commitments. \n Need assistance upgrading your account? Contact your upline leader or doTERRA Member Services in your area for support. ',
    },
    highlight: '',
  },
  PENDING_BUILDER: {
    header: {
      title: 'Confirm Your Status',
      subtitle: '',
      text: '',
      videoUrl: '',
      stepStatus: MEMBER_STATUS.PENDING_BUILDER,
    },
    body: {
      title: '',
      text:
        'In order to best support you as you share dōTERRA with others, we need to confirm your Wellness Advocate status. We’ll do this by generating a link using your dōTERRA ID. \n If you’ve forgotten your dōTERRA ID, you can contact doTERRA Member Services in your area for support.',
    },
    highlight: '',
  },
  BUILDER: {
    header: {
      title: 'Share dōTERRA',
      subtitle:
        'Ready to live life on your terms?',
      text: '',
      videoUrl: '',
      stepStatus: MEMBER_STATUS.BUILDER,
    },
    body: {
      title: '',
      text:
        'With Drippl, inviting, presenting, enrolling, and supporting others has never been simpler! \n Take a few minutes to watch the videos below to learn about the compensation plan and how to place new members on your team.  It’s a good idea to work with your up-line leader to come up with a winning game plan. \n Test your personal enrollment funnel by clicking the Test Enrollment Funnel button below to see how Drippl guides your referrals from learning about dōTERRA, to purchasing their first enrollment kit from you, and ultimately working with you to build your business. \n After putting your enrollment funnel to the test, become a VIP Member to activate your personal referral link and start sharing all of the health, wealth, and goodness that dōTERRA has to offer.',
    },
    highlight: '',
  },
  EXPLORING: {
    header: {
      title: 'Automate Your Business',
      subtitle: 'Invite, educate, and enroll with the dōTERRA Online Enrollment Funnel!',
      text: '',
      videoUrl: '',
      stepStatus: MEMBER_STATUS.EXPLORING,
    },
    body: {
      title: '',
      text:
        'Whether as a supplement to in-person classes or a principal part of your online business-building strategy, Drippl is designed to help educate your referrals how to live, share, and build dōTERRA! \n Explore the information below to begin the same series of done-for-you online video modules, relevant reading materials, enrollment invitations, and email nurture sequences your referrals will follow. Then, when you’re ready to start building your business with Drippl, activate your VIP Membership!',
    },
    highlight: '',
  },
};
