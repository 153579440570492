import styled from 'styled-components';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isCentralized }) => (isCentralized ? 'center' : '')};
  align-items: ${({ isCentralized }) => (isCentralized ? 'center' : '')};
`;

export default Col;
