import styled from 'styled-components';

const H1 = styled.h1`
  margin-bottom: 35px;
  font-size: 32px;
  line-height: 30px;
  font-weight: 600;
  font-family: Visbyroundcf, sans-serif;
  color: ${({ primary }) => (primary ? `#00d1cf` : '#333')};
  text-transform: ${({ isUppercase }) =>
    isUppercase ? 'uppercase' : 'capitalize'};
  padding: 0;
  text-align: left;
  @media (min-width: 600px) {
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 52px;
    line-height: 37px;
  }
`;

export default H1;
