import { useQuery } from '@apollo/react-hooks';
import { GET_MEMBER_REFER } from 'graphql/queries/getMemberRefer';
import get from 'lodash/get';
import { useWindowSize } from 'hooks/useWindowSize';

const useContactBar = () => {
  const { isMobileView } = useWindowSize();

  const { loading, error, data } = useQuery(GET_MEMBER_REFER);
  const oweStatus = get(data, 'self.oweStatus') || '';
  const isVip = oweStatus === 'VIP';
  const memberId = get(data, 'self.doterraAccount.memberId');
  const shadowUser = get(data, 'self.shadowUser', '');
  const showUpgradeVip = !isVip && !shadowUser && memberId;
  const shouldHide = isMobileView && (isVip || showUpgradeVip);

  return {
    loading,
    error,
    data: get(data, 'self.referredBy') || {},
    isVip,
    name: get(data, 'self.firstName') || '',
    refLink: get(data, 'self.referralLinks[1].link') || '',
    showUpgradeVip,
    shouldHide,
  };
};

export default useContactBar;
