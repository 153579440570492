import styled, { css } from 'styled-components';
import ButtonDefault from 'ui/Button/Default';

export const SectionPicture = styled.div`
  ${() => css`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `};
`;

export const UploadFormWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    order: 2;
    @media (min-width: 600px) {
      order: 1;
    }
  `};
`;

export const Button = styled(ButtonDefault)`
  ${() => css`
    padding: 10px;
    align-self: flex-end;
    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    order: 1;
    @media (min-width: 600px) {
      order: 2;
      font-size: 16px;
      width: auto;
    }
  `};
`;

export const Avatar = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  margin-bottom: 30px;
  align-self: center;
  @media (min-width: 600px) {
    align-self: left;
  }
`;

export const UploadAvatarButton = styled.div`
  ${({ theme }) => css`
    padding: 10px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: ${theme.colors.primary};
    text-align: center;
    color: white;
    font-weight: bold;
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionInputFields = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
      flex-direction: row;
    }
  `};
`;

export const Left = styled.div`
  ${() => css`
    flex: 1;
    @media (min-width: 600px) {
    }
  `};
`;

export const Right = styled.div`
  ${() => css`
    flex: 1;
    button {
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
      margin-left: 20px;
      button {
        margin-bottom: 0;
      }
    }
  `};
`;

export const SectionForm = styled.form`
  ${({ theme }) => css`
    margin-bottom: 60px;
    border: none;
    border-radius: 8px;
    width: 100%;
    position: relative;
    padding-bottom: 2.5rem;
    h2 {
      color: black;
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      padding-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.border};
      margin-bottom: 20px;
    }
    @media (min-width: 600px) {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  `};
`;

export const TextAreaInput = styled.textarea`
  ${({ hasError, theme }) => css`
    margin-bottom: 10px;
    min-height: 330px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${hasError ? 'red' : '#d8d8d8'};
    padding: 8px 30px 8px 20px;
    color: #444;
    font-size: 16px;
    line-height: 29px;
    box-sizing: border-box;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    outline: none;
    font-family: ${theme.font.family.montserrat};
    @media (min-width: 600px) {
      min-height: 320px;
    }
  `};
`;
