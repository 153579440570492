import gql from 'graphql-tag';

export const UPDATE_SELF_RANK_GOAL = gql`
  mutation selfUpdateRankGoal(
    $goalDate: String
    $currentRankId: String!
    $goalRankId: String!
  ) {
    selfUpdateRankGoal(
      input: {
        goalDate: $goalDate
        currentRankId: $currentRankId
        goalRankId: $goalRankId
      }
    ) {
      currentRank {
        id
        name
      }
    }
  }
`;
