import styled from 'styled-components';

const H3 = styled.h3`
  margin-bottom: 27px;
  font-family: Visbyroundcf, sans-serif;
  font-size: 21px;
  line-height: 27px;
  font-weight: 600;
  color: ${({ primary }) => (primary ? `#00d1cf` : '#333')};
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : '')};
  padding: 0;
  @media (min-width: 600px) {
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 31px;
    line-height: 37px;
  }
`;

export default H3;
