import React, { memo } from 'react';
import {
  Container,
  ContactWrapper,
  Wrapper,
  Avatar,
  Button,
  Text,
  ButtonUpgrade,
} from 'ui/ContactBar/styles';
import useContactBar from 'ui/ContactBar/data/useContactBar';
import { ShareLinkBar } from 'ui/ShareLinkBar';
import useReactRouter from 'use-react-router';

const ContactBar = () => {
  const { history } = useReactRouter();

  const {
    data: { fullName, email, profilePicture, phoneNumber },
    isVip,
    name,
    refLink,
    showUpgradeVip,
    shouldHide,
  } = useContactBar();

  const onClick = () => window.open(`mailto:${email}`, '_blank');

  const handleUpgradeButton = () => history.push('/become-vip');

  return (
    <Container>
      <Wrapper isCentralized={shouldHide}>
        <ContactWrapper shouldHide={shouldHide}>
          {profilePicture && <Avatar src={profilePicture} />}

          {fullName && (
            <Text>
              Referred by <span>{fullName}</span>
            </Text>
          )}
          {phoneNumber && (
            <Text>
              Text or Call: <span>{phoneNumber}</span>
            </Text>
          )}
          {email && <Button onClick={onClick}>Send Me a Message</Button>}
        </ContactWrapper>
        {isVip && <ShareLinkBar name={name} refLink={refLink} />}
        {showUpgradeVip && (
          <ButtonUpgrade onClick={handleUpgradeButton}>
            Start Your Free Trial
          </ButtonUpgrade>
        )}
      </Wrapper>
    </Container>
  );
};

export default memo(ContactBar);
