import styled from 'styled-components';

const mainColor = '#5ed6d5';

const Button = styled.button`
  color: ${mainColor};
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  border: 1px solid ${mainColor};
  border-radius: 4px;
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: #02d6d4;
    color: #fff;
    transform: translate(0px, -2px);
  }
`;

export default Button;
