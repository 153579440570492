import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  List,
  Item,
  ArrowUpIcon,
  HammerIcon,
  ExploreIcon,
  TryIcon,
} from 'ui/StatusProgress/styles';

const StatusProgress = ({ statusBar }) => {
  const { EXPLORE, TRY, PREPARE, AUTOMATE } = statusBar;
  const renderExplore = () => {
    const { itemComplete, itemPending } = EXPLORE;
    return (
      <Item isActive={itemComplete} isDashed={itemPending}>
        <ExploreIcon isActive={itemComplete} />
        Explore the Opportunity
      </Item>
    );
  };
  const renderTry = () => {
    const { itemComplete, itemPending } = TRY;

    return (
      <Item isActive={itemComplete} isDashed={itemPending}>
        <TryIcon isActive={itemComplete} />
        Try the Products
      </Item>
    );
  };
  const renderPrepare = () => {
    const { itemComplete, itemPending } = PREPARE;

    return (
      <Item isActive={itemComplete} isDashed={itemPending}>
        <HammerIcon isActive={itemComplete} />
        Prepare to Build
      </Item>
    );
  };
  const renderAutomate = () => {
    const { itemComplete, itemPending } = AUTOMATE;

    return (
      <Item isActive={itemComplete} isDashed={itemPending}>
        <ArrowUpIcon isActive={itemComplete} />
        Automate Growth
      </Item>
    );
  };
  return (
    <Container>
      <List>
        {renderExplore()}
        {renderTry()}
        {renderPrepare()}
        {renderAutomate()}
      </List>
    </Container>
  );
};

StatusProgress.propTypes = {
  statusBar: PropTypes.object,
};

export default StatusProgress;
