import React from 'react';
import PropTypes from 'prop-types';
import { Container, Left, Right } from 'pages/TheFlow/UserUtils/styles';
import PersonalLink from 'pages/TheFlow/UserUtils/PersonalLink';
import ProFusionStats from 'pages/TheFlow/UserUtils/ProFusionStats';
import RankInformation from 'pages/TheFlow/UserUtils/RankInformation';
import get from 'lodash/get';
import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';

const UserUtils = ({ data }) => {
  const checkVisibility = () => {
    const { BUILDER } = POSITION_STATUS;
    const { MEMBER, VIP } = MEMBER_STATUS;

    const oweStatus = get(data, 'self.oweStatus', '');
    const positionStatus = get(data, 'self.positionStatus', '');

    return [MEMBER, VIP].includes(oweStatus) && BUILDER === positionStatus;
  };

  return checkVisibility() ? (
    <Container>
      <Left>
        <RankInformation />
      </Left>
      <Right>
        <PersonalLink />
        <ProFusionStats />
      </Right>
    </Container>
  ) : null;
};

UserUtils.propTypes = {
  data: PropTypes.object,
};

export default UserUtils;
