import gql from 'graphql-tag';

export const SET_VIDEO_COMPLETED = gql`
  mutation SET_VIDEO_COMPLETED($videoId: String, $isFinished: Boolean) {
    selfSetUserVideoFinished(videoId: $videoId, isFinished: $isFinished) {
      id
      isFinished
    }
  }
`;
