import gql from 'graphql-tag';

export const GET_MODULES = gql`
  query getModules {
    self {
      id
      positionStatus
    }
    selfLabModules(sort: "priority") {
      id
      name
      shortDescription
      locked
      lockedText
      recommended
      coverImage {
        url
      }
      labCourses {
        name
        labLessons {
          id
          video {
            id
            userVideos {
              id
              isFinished
            }
          }
        }
      }
    }
  }
`;
