import { useState, useEffect, useCallback } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_REFFERED_BY } from 'graphql/queries/getRefferedBy';
import { SET_REFERRED_BY } from 'graphql/mutations/setReferredBy';
import { GET_MEMBER_REFER } from 'graphql/queries/getMemberRefer';

import get from 'lodash/get';

const dataOptions = [
  {
    id: 'default',
    text:
      'My preferred business partner or upline leader has not referred me to this site',
  },
];

const useChooseLeader = () => {
  const [options, setOptions] = useState(dataOptions);
  const [hasSaved, setHasSaved] = useState(false);
  const [referralId, setReferralId] = useState('');

  const [handleSetRefMutation] = useMutation(SET_REFERRED_BY);

  const [getReferralUser, { data, loading, error }] = useLazyQuery(
    GET_REFFERED_BY
  );

  const handleSetRef = useCallback(
    async id => {
      setHasSaved(true);
      await handleSetRefMutation({ variables: { id } });
    },
    [handleSetRefMutation]
  );

  useEffect(() => {
    if (referralId) {
      getReferralUser({
        variables: { id: referralId },
        refetchQueries: () => [
          {
            query: GET_MEMBER_REFER,
          },
        ],
      });
    }
  }, [getReferralUser, referralId]);

  useEffect(() => {
    const updateRef = async idData => {
      await handleSetRef(idData);
    };

    if (data && !hasSaved) {
      const user = get(data, 'self') || {};
      const currentRef = get(data, 'self.referredBy') || {};
      const newRef = get(data, 'user') || {};

      // Set user itself as refferedBy
      if (
        user.oweStatus === 'VIP' &&
        newRef.id === user.id &&
        newRef.id !== currentRef.id
      ) {
        updateRef(newRef.id);
        return;
      }
      // VIP === (valid subscription)
      if (newRef.oweStatus === 'VIP' && user.oweStatus === 'PREMEMBER') {
        // No previous refferedBy or RefferedBy is no longer VIP, set new refferal as vip
        if (!currentRef.oweStatus || currentRef.oweStatus !== 'VIP') {
          updateRef(newRef.id);
          return;
        }

        if (currentRef.oweStatus && currentRef.id === newRef.id) {
          return;
        }

        const updatedOptions = [];

        if (newRef.id) {
          updatedOptions.push({
            id: newRef.id,
            text: newRef.fullName,
          });
        }

        if (currentRef.id) {
          updatedOptions.push({
            id: currentRef.id,
            text: currentRef.fullName,
          });
        }

        updatedOptions.push(dataOptions[0]);
        setOptions(updatedOptions);
      }
    }
  }, [data, handleSetRef, hasSaved]);

  return {
    loading,
    error,
    data: options,
    oweStatus: get(data, 'self.oweStatus', ''),
    getReferralUser,
    handleSetRef,
    setReferralId,
    setOptions,
  };
};

export default useChooseLeader;
