import styled, { css } from 'styled-components';
import RadioSet from 'ui/RadioSet';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow-y: scroll;
    @media (min-width: 600px) {
      width: 700px;
      overflow-y: none;
    }
  `}
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: center;
    padding: 0 40px;
    line-height: 29px;
    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 32px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
      line-height: 38px;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionMemberOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  margin: 25px 0;
  @media (min-width: 600px) {
    padding: 0 45px;
  }
`;

export const SectionInfo = styled.div`
  ${({ theme }) => css`
    background-color: rgba(94, 214, 213, 0.1);
    padding: 20px;
    margin: 10px 20px;
    border-radius: ${theme.borderRadius.large};
    text-align: center;
    line-height: 29px;
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperInput = styled(RadioSet)`
  margin-bottom: 10px;
`;
