import styled, { css } from 'styled-components';
import ButtonClean from 'ui/Button/Clean';
import DefaultButton from 'ui/Button/Default';

export const Container = styled.div`
  ${({ theme, isVisible }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    padding-bottom: 40px;
    display: ${isVisible ? 'block' : 'none'};
    position: relative;
    @media (min-width: 600px) {
      overflow-y: scroll;
      width: 700px;
      padding-bottom: 40px;
    }
  `}
`;

export const CloseModal = styled.div`
  ${() => css`
    position: absolute;
    top: -25px;
    right: 20px;
    cursor: pointer;
    padding: 5px;
    span {
      font-weight: bolder;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: center;
    padding: 0 20px;
    line-height: 22px;
    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 32px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
      line-height: 32px;
    }
    @media (min-width: 600px) {
      padding: 0 40px;
      h2 {
        line-height: 22px;
        font-size: 26px;
        letter-spacing: 0.1px;
      }
    }
  `};
`;

export const SectionBody = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 20px;
    line-height: 22px;
    @media (min-width: 600px) {
      padding: 0 40px;
    }
  `};
`;

export const SectionClose = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 40px;
    line-height: 38px;
    #email-click {
      color: blue;
      cursor: pointer;
    }
    @media (min-width: 600px) {
      padding: 0 80px;
    }
  `};
`;

export const WrapperInput = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px;
    justify-content: space-between;
    button {
      width: 100%
      margin: 20px 0;
    }
    @media (min-width: 600px) {
      flex-direction: row;
    input {
      width: 50%
    }
    button {
      width: 40%
      margin:  0;
    }
  `};
`;

export const ButtonSave = styled(DefaultButton)`
  ${() => css`
    width: 40%;
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    @media (min-width: 600px) {
    }
  `}
`;

export const ButtonHelp = styled(ButtonClean)`
  margin-left: 10px;
  width: 80%;
  font-size: 18px;
  padding: 15px;
  align-self: center;
  margin-top: 20px;
  @media (min-width: 600px) {
  }
`;
