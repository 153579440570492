import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 293.7 293.7" className={props.className}>
      <path
        d="M130.4,81.1c12.3,0,24,0,36,0c0.6-2.6,1-5.6,2-8.3c4.2-11.7,12.7-18.7,24.8-20.5c13.3-2.1,24.8,2.1,33.3,12.7
			c3.9,4.8,6.5,10.7,9.2,16.4c10.8,22.4,21.5,44.9,32,67.5c5.4,11.7,9,23.9,8.7,37c-0.8,31.1-28.8,56.5-58.8,53.4
			c-29-3-49.6-25.7-50.8-55.8c-0.5-13.6-12.7-21.7-25.2-16.7c-7.6,3-10.9,9-11.2,17.2c-0.7,21-9.9,37.4-28.1,48
			c-32.6,19.1-73.5-0.3-80.4-37.6c-2.8-15.1,0.4-29.2,6.4-42.6c12.1-26.6,24.6-53,37.5-79.2c10.1-20.7,35.2-27,52.6-13.9
			C125.8,64.2,129.5,71.9,130.4,81.1z M221.3,221.6c20.5,0,37.1-16.5,37.1-36.9c0.1-20.9-16.3-37.4-37.1-37.4
			c-20.7,0-37.1,16.3-37.1,36.9C184.1,205.1,200.4,221.5,221.3,221.6z M112.9,184c0-20.2-16.2-36.6-36.2-36.7
			c-21.3-0.1-37.9,16.1-37.9,37.2c0,20.3,16.8,37.1,37.1,37.1C96.6,221.6,112.9,205,112.9,184z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
