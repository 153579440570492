import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useClipboard from 'react-use-clipboard';
import Modal from 'ui/Modal';
import {
  Wrapper,
  Container,
  Content,
  ButtonClean,
  ButtonMain,
  TextLink,
  ContainerModal,
  Icon,
  ModalTitle,
  Line,
  Line2,
  WrapperLink,
} from 'pages/TheFlow/UserUtils/PersonalLink/styles';
import { Header } from 'pages/TheFlow/UserUtils/styles';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import useReactRouter from 'use-react-router';
import usePersonalLink from 'pages/TheFlow/UserUtils/data/usePersonalLink';
import get from 'lodash/get';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import ShadowUserVipModal from 'pages/TheFlow/Steps/ShadowUserVipModal';

const ERROR_LINK =
  'Looks like we ran into an issue creating your referral link. Please contact support for assistance.';

const NotificationModal = ({ isOpen, handleClose }) => (
  <Modal isOpen={isOpen} handleClose={handleClose} cancelLabel="Got it">
    <ContainerModal>
      <Icon />
      <ModalTitle>How Does Your Personal Referral Link Work?</ModalTitle>
      <p>
        Your personalized link makes it possible for Drippl to track any
        visitors you refer. When your referrals decide to learn more, we begin
        the process of educating them about doTERRA and invite them to try the
        products. YOUR referrals will see YOUR picture as we interact with them.
        You can share this link on social media posts, texts, emails, and any
        other creative way you can think to share it. When your referrals are
        ready to purchase products or start building their business, they will
        be taken to your doTERRA enrollment site where they will buy products
        and enroll as part of your team!
      </p>
    </ContainerModal>
  </Modal>
);

NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

const PersonalLink = () => {
  const { isShadowUserEnabled } = useTheFlow();
  const { history } = useReactRouter();
  const { data } = usePersonalLink();
  const [isOpen, setIsOpen] = useState(false);

  const [vipAndBuilder, setVpAndBuilder] = useState(false);

  const [dripplRef, setDripplRef] = useState('');
  const [dripplRefBuilder, setDripplRefBuilder] = useState('');

  const [isLinkRefLinkCopied, setRefLinkCopied] = useClipboard(dripplRef);
  const [isLinkRefBuilderCopied, setRefBuilderCopied] = useClipboard(
    dripplRefBuilder
  );

  const handleTogglePopUp = () => setIsOpen(prevState => !prevState);
  const [isOpenShadowUserVipModal, setIsOpenShadowUserVipModal] = useState(
    false
  );

  const handleGetVip = () => {
    if (isShadowUserEnabled) {
      return setIsOpenShadowUserVipModal(true);
    }
    history.push('/become-vip');
  };

  useEffect(() => {
    if (data) {
      const positionStatus = get(data, 'self.positionStatus', '');
      const oweStatus = get(data, 'self.oweStatus', '');

      const links = get(data, 'self.referralLinks', []);
      links.forEach(({ label = '', link = ERROR_LINK }) => {
        if (label === 'drippl_ref') {
          setDripplRef(link);
        }
        if (label === 'drippl_ref_builder') {
          setDripplRefBuilder(link);
        }
      });

      const isVip = positionStatus === 'BUILDER' && oweStatus === 'VIP';
      setVpAndBuilder(isVip);
    }
  }, [data]);

  return vipAndBuilder ? (
    <Wrapper>
      <Header>
        <span>Personal Referral Links</span> <LinkOutlinedIcon />
      </Header>
      <Container>
        <Content>Two separate landing pages for you and your team!</Content>

        <WrapperLink>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Line>1.</Line>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Line>
              Share this link to invite, educate, and enroll new members;
            </Line>
            <TextLink id="referralLink">{dripplRef}</TextLink>
            <ButtonMain onClick={setRefLinkCopied}>
              <span>
                {isLinkRefLinkCopied
                  ? 'Link Copied'
                  : 'Click to Share with Someone New'}
              </span>
              <FileCopyOutlinedIcon fontSize="small" />
            </ButtonMain>
          </div>
        </WrapperLink>

        <WrapperLink>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Line2>2.</Line2>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Line2>
              Share this link with current team members and potential builders:
            </Line2>
            <TextLink id="referralLink">{dripplRefBuilder}</TextLink>
            <ButtonMain onClick={setRefBuilderCopied}>
              <span>
                {isLinkRefBuilderCopied
                  ? 'Link Copied'
                  : 'Click to Share with Team Members'}
              </span>
              <FileCopyOutlinedIcon fontSize="small" />
            </ButtonMain>
          </div>
        </WrapperLink>
        <ButtonClean onClick={handleTogglePopUp}>
          How do these work?
        </ButtonClean>
      </Container>
      <NotificationModal isOpen={isOpen} handleClose={handleTogglePopUp} />
      <ShadowUserVipModal
        isOpen={isOpenShadowUserVipModal}
        handleClose={() => setIsOpenShadowUserVipModal(false)}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      <Header>
        <span>Personal Referral Link</span> <LinkOutlinedIcon />
      </Header>
      <Container>
        <Content>
          Put us to work for you! Take advantage of everything Drippl has to
          offer with this simple but powerful link.
        </Content>
        <ButtonClean onClick={handleTogglePopUp}>
          How do these work?
        </ButtonClean>
        <ButtonMain onClick={handleGetVip}>
          <span>Get VIP</span>
        </ButtonMain>
      </Container>
      <NotificationModal isOpen={isOpen} handleClose={handleTogglePopUp} />
      <ShadowUserVipModal
        isOpen={isOpenShadowUserVipModal}
        handleClose={() => setIsOpenShadowUserVipModal(false)}
      />
    </Wrapper>
  );
};

export default PersonalLink;
