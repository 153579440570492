import styled, { css } from 'styled-components';

export const ContainerSectionPrice = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0px;
    @media (min-width: 600px) {
      flex-direction: row;
      margin: 45px 60px;
    }
  `};
`;

const Box = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 45px 0 30px;
    border-radius: 8px;
    border: 2px solid grey;
    margin-bottom: 40px;
    h2 {
      color: white;
      font-size: 2em;
    }
    p {
      margin: 20px;
      font-weight: bolder;
      font-size: 1.2em;
      text-align: center;
      span {
        text-decoration: line-through;
      }
    }
    button {
      background-color: white;
      border-radius: 40px;
      padding: 15px 60px;
      font-size: 1.3em;
      border: none;
      cursor: pointer;
      transition: translate 0.2s ease-out;
      font-weight: bold;
      outline: none;
      &:hover {
        transform: translate(0px, -2px);
      }
    }
    @media (min-width: 600px) {
      width: 47%;
      margin-bottom: 0;
      p {
        margin-bottom: 22px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
    }
  `};
`;

export const WrapperPriceMonth = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border-color: #09afae;
    button {
      color: ${theme.colors.primary};
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperPriceYear = styled(Box)`
  ${() => css`
    position: relative;
    background-color: #ff6d97;
    border-color: #d13086;
    button {
      color: #ff6d97;
    }
    > span {
      position: absolute;
      top: -18px;
      left: 17%;
      padding: 5px 30px;
      border: none;
      border-radius: 25px;
      background-color: white;
      color: black;
      background-color: #ffe347;
      font-size: 14px;
      font-weight: 600;
    }
    @media (min-width: 600px) {
      > span {
        left: 26%;
        font-size: 16px;
      }
    }
  `};
`;
