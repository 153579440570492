import { useQuery } from '@apollo/react-hooks';
import { GET_SELF_STATS } from 'graphql/queries/getSelfStats';
import get from 'lodash/get';

const useDripplStats = () => {
  const { loading, error, data } = useQuery(GET_SELF_STATS);

  return {
    loading,
    error,
    data: get(data, 'selfGetStats') || undefined,
  };
};

export default useDripplStats;
