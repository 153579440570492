import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  background-color: white;
`;

export const Nav = styled.nav`
  display: flex;
  flex: 1;
  max-width: 1200px;
  padding: 0px 22px;
  justify-content: space-between;
  align-items: center;
`;

export const Link = styled.div`
  flex: 1;
`;

export const EnterButton = styled(RouterLink)`
  padding: 11px 25px;
  border-radius: 50px;
  background-color: #1ad5ca;
  transition: all 200ms ease;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  transform: translate(0px, 0px);
  border: none;
  display: inline-block;

  &:hover {
    background-color: #10aaa1;
    transform: translate(0px, -3px);
  }
`;

export const RighNav = styled.div`
  * {
    padding-left: 15px;
  }
`;
