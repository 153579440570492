import React, {useEffect} from 'react';
import {
  Container,
  CenterContent,
  Wrapper,
  Left,
  Right,
  Button,
} from 'pages/SetupEmail/styles';
import ladyLaptop from 'assets/img/home/lady-laptop.jpeg';
import smileFace from 'assets/img/home/emoji-face.png';
import { useAuth0 } from 'context/auth';

const SetupEmail = () =>{
  const { logout, isAuthenticated } = useAuth0();

  const loginUrl = window.location.origin;

  useEffect(()=>{

    if(isAuthenticated){
      logout({
        returnTo: `${loginUrl}/error-email`,
      });
    }

  },[])

  return (
    <Container>
      <CenterContent>
        <Wrapper>
          <Left>
            <div>
              We just need an email from you !{' '}
              <img alt="smile-face" width="80px" height="80px" src={smileFace} />
            </div>
            <span>
              Looks like your Facebook account has no email! <br />
              Drippl needs to have an email to work properly. Please add an email to your Facebook account then try again.
            </span>
            <Button to="/login-check">Back to Drippl</Button>
          </Left>
          <Right>
            <img alt="Logo" width="518px" height="390px" src={ladyLaptop} />
          </Right>
        </Wrapper>
      </CenterContent>
    </Container>
  );

} 

export default SetupEmail;
