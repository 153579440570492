import React, { useState, useEffect } from 'react';
import { PageContent } from 'ui/layouts';
import GoBackToTheFlowPage from 'ui/GoBackToTheFlowPage';
import WelcomeHeader from 'ui/WelcomeHeader';
import {
  SectionHeader,
  WrapperTitle,
  Title,
  Subtitle,
  SectionBenefits,
  Benefit,
  SectionDisclaimer,
  IconCheck,
  SectionDisclaimerTwo,
  SectionBoxInfo,
  BoxInfo,
  HighlightBox,
  TextMiddle,
} from 'pages/BecomeVIP/styles';
import SignUpFormModal from 'pages/BecomeVIP/ModalSignUpForm';
// import StripeButton from './StripeButton';
import SectionPrice from 'pages/BecomeVIP/SectionPrice';
import ExcitedImg from 'assets/img/becomeVip/excited.jpg';
import HeaderImg from 'assets/img/becomeVip/become-vip.png';
import useProfile from 'pages/Profile/data/useProfile';
import axios from 'axios';
import ReactGA from 'react-ga';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from 'context/auth';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const BecomeVIP = () => {
  const { loading, token } = useAuth0();
  const { data } = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interval, setInterval] = useState('monthly');


  const handleClosedMoldal = () => setIsModalOpen(false);

  const fetchCheckoutSession = async (billPeriod, promocode) => {
    ReactGA.event({
      category: 'VIP Membership',
      action: 'Activate Now',
      label: billPeriod,
      value: billPeriod === 'monthly' ? 14 : 149
    });

    const result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/stripe-customer/create-session?interval=${billPeriod}&coupon=${promocode}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('result', result);
    return { sessionId: result.data.id };
  };

  const handleOpenMoldal = async newInterval => {
    setInterval(newInterval);
    // setIsModalOpen(true);
    try {
      const { sessionId } = await fetchCheckoutSession(
        newInterval,
        ''
      );
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      if (error) {
        console.error(error.message)
        // setErrorMessage(error.message);
      }
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    } catch (error) {
      console.log('error response', error.response);
      // setErrorMessage(error.response.data.message);
      return error.response;
    }

  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <WelcomeHeader />
      <PageContent>
        <GoBackToTheFlowPage />

        <SectionHeader>
          <img src={HeaderImg} alt="header" width="150px" />
          <WrapperTitle>
            <Title>Become a VIP Member</Title>
            <Subtitle>Try it FREE for 14 days!</Subtitle>
          </WrapperTitle>
        </SectionHeader>
        <HighlightBox>
          LIMITED TIME ONLY! The next 500 people to activate their VIP account receive an additional life time 50% discount!
        </HighlightBox>
        <SectionPrice region={data?.region} handleOpenMoldal={handleOpenMoldal} />
        <TextMiddle>
          As a VIP Member, you can start building your business online TODAY.
          Activate your membership to get immediate access your personalized Drippl Enrollment Funnel!
        </TextMiddle>
        <SectionBenefits>
          <div>
            <Benefit>
              <IconCheck />
              <div>The power and support of the Drippl platform</div>
              <p>
                No need to design your own website, marketing videos, education
                content, etc. We’ve done it for you!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>Unique referral links for sharing</div>
              <p>
                Confidently refer new contacts and existing team members to Drippl. Let our professionally-designed and personalized marketing funnel help invite, educate, and enroll new members and rekindle hopes and light a fire with members of your team!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>Complete, done-for-you email nurture sequences</div>
              <p>
                Your contacts can explore the opportunity at their own pace, but
                our follow-up email nurture sequence keeps them engaged,
                learning, and evaluating the opportunity.
              </p>
            </Benefit>
          </div>
          <div>
            <Benefit>
              <IconCheck />
              <div>A system that promotes enrollment kits for you</div>
              <p>
                Let Drippl introduce your contacts to the enrollment kits, invite them to put the products to the test, and
                encourage them to get started with dōTERRA!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>Online video education and training</div>
              <p>
                An immersive, distraction-free video library presents dōTERRA and its amazing products in their best light.  Remove the risk of your contacts being distracted or seeing critical and inaccurate information about dōTERRA in noisy platforms like YouTube and Facebook!
              </p>
            </Benefit>
            <Benefit>
              <IconCheck />
              <div>The most cost-effective and simple system for online duplication anywhere</div>
              <p>
                The future of network marketing is going online.  Business leaders that can offer simple and effective tools for builind an online business to their team will have a significant advantage.
              </p>
            </Benefit>
          </div>
        </SectionBenefits>
        <SectionDisclaimer src={ExcitedImg}>
          <div>
            <div />
          </div>
          <div>
            <h2>
              Oh, yeah… and you get all of this at our early-adopter, discounted
              rate of only {data?.region?.prefix}{data?.region?.monthSubscriptionDiscount} {data?.region?.suffix} /month!
            </h2>
            <span>
              (NOTE: This discount is available for a super limited time before
              we raise the price forever! You can cancel your billing
              at any time)
            </span>
          </div>
        </SectionDisclaimer>
        <SectionBoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>NO need to hire a marketing team</div>
          </BoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>NO need to build your own website</div>
          </BoxInfo>
          <BoxInfo>
            <span>X</span>
            <div>
              NO need to pay upwards of $100/month for a funnel that may or may
              not work ;)
            </div>
          </BoxInfo>
        </SectionBoxInfo>
        <SectionDisclaimerTwo>
          <div>
            And get excited! To accommodate worldwide growth and international
            scalability, we are working on launching Drippl in various languages
            while enhancing functionality, features, and support services for
            the best, most effective universal experience.
          </div>
          <div>Become a Drippl VIP and keep your low rate forever!</div>
        </SectionDisclaimerTwo>
        <SectionPrice region={data?.region} handleOpenMoldal={handleOpenMoldal} />
        {/* <div>
          <StripeButton interval="monthly">Stripe Test Monthly</StripeButton>
          <StripeButton interval="yearly">Stripe Test Yearly</StripeButton>
        </div> */}
        <SignUpFormModal
          isOpen={isModalOpen}
          interval={interval}
          handleClose={handleClosedMoldal}
        />
      </PageContent>
    </>
  );
};

export default BecomeVIP;
