import React from 'react';
import { Container, Label, Button } from './styles';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import { UPDATE_DOTTERA_ACCOUNT } from 'graphql/mutations/updateDoterrAccount';
import { useMutation } from '@apollo/react-hooks';

const StatusChange = () => {
  const [updateBackOfficeLink] = useMutation(UPDATE_DOTTERA_ACCOUNT);

  const handleResetExperience = async () => {
    await updateBackOfficeLink({
      variables: { memberId: '', backOfficeLink: '' },
    });
    await handleUpdateMemberStatuses({
      positionStatus: 'EXPLORING',
      oweStatus: 'SETUP',
    });
  };

  const {
    handleUpdatePositionStatus,
    handleUpdateMemberStatus,
    handleUpdateMemberStatuses,
    handleSetShawdowUser,
    data,
    isShadowUserEnabled,
  } = useTheFlow();

  const developmetOptions = (
    <Container>
      <span>Demo Mode Only</span>
      <Button onClick={handleResetExperience}>Reset Experience</Button>
      <span>Shadow User Experience</span>

      {isShadowUserEnabled ? (
        <Button onClick={() => handleSetShawdowUser('off')}>OFF</Button>
      ) : (
        <Button onClick={() => handleSetShawdowUser('on')}>ON</Button>
      )}

      <Label>OWE: {data ? data.self.oweStatus : ''}</Label>
      <Button onClick={() => handleUpdateMemberStatus('SETUP')}>SETUP</Button>
      <Button onClick={() => handleUpdateMemberStatus('PREMEMBER')}>
        PREMEMBER
      </Button>
      <Button onClick={() => handleUpdateMemberStatus('MEMBER')}>MEMBER</Button>
      <Button onClick={() => handleUpdateMemberStatus('VIP')}>VIP</Button>
      <Label>positionStatus: {data ? data.self.positionStatus : ''}</Label>
      <Button onClick={() => handleUpdatePositionStatus('EXPLORING')}>
        EXPLORING
      </Button>
      <Button onClick={() => handleUpdatePositionStatus('PENDING_CUSTOMER')}>
        PENDING_CUSTOMER
      </Button>
      <Button onClick={() => handleUpdatePositionStatus('CUSTOMER')}>
        CUSTOMER
      </Button>
      <Button onClick={() => handleUpdatePositionStatus('PENDING_BUILDER')}>
        PENDING_BUILDER
      </Button>
      <Button onClick={() => handleUpdatePositionStatus('BUILDER')}>
        BUILDER
      </Button>
    </Container>
  );

  const stagingOptions = (
    <Container>
      <span>Demo Mode Only</span>
      <Button onClick={handleResetExperience}>Reset Experience</Button>
    </Container>
  );

  return (
    <div>
      {
        {
          development: developmetOptions,
          staging: stagingOptions,
          production: <div></div>,
        }[process.env.REACT_APP_DEV_TOOLS]
      }
    </div>
  );

  // return process.env.NODE_ENV === 'development' ? (
  // <Container>
  //   <Label>OWE: {data ? data.self.oweStatus : ''}</Label>
  //   <Button onClick={() => handleUpdateMemberStatus('SETUP')}>SETUP</Button>
  //   <Button onClick={() => handleUpdateMemberStatus('PREMEMBER')}>
  //     PREMEMBER
  //   </Button>
  //   <Button onClick={() => handleUpdateMemberStatus('MEMBER')}>MEMBER</Button>
  //   <Button onClick={() => handleUpdateMemberStatus('VIP')}>VIP</Button>
  //   <Label>positionStatus: {data ? data.self.positionStatus : ''}</Label>
  //   <Button onClick={() => handleUpdatePositionStatus('EXPLORING')}>
  //     EXPLORING
  //   </Button>
  //   <Button onClick={() => handleUpdatePositionStatus('PENDING_CUSTOMER')}>
  //     PENDING_CUSTOMER
  //   </Button>
  //   <Button onClick={() => handleUpdatePositionStatus('CUSTOMER')}>
  //     CUSTOMER
  //   </Button>
  //   <Button onClick={() => handleUpdatePositionStatus('PENDING_BUILDER')}>
  //     PENDING_BUILDER
  //   </Button>
  //   <Button onClick={() => handleUpdatePositionStatus('BUILDER')}>
  //     BUILDER
  //   </Button>
  // </Container>
  // ) : (
  //   <Container>
  //     <span>Demo Mode Only</span>
  //     <Button
  //       onClick={() =>
  //         handleUpdateMemberStatuses({
  //           positionStatus: 'EXPLORING',
  //           oweStatus: 'SETUP',
  //         })
  //       }>
  //       Reset Experience
  //     </Button>
  //   </Container>
  // );
};

export default StatusChange;
