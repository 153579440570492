import React from 'react';
import { SectionInput, WrapperInput } from './styles';
import Text from 'ui/Text';
import InputText from 'ui/InputText';

const DoterraIdConfirmation = ({ memberId, handleUpdateMemberId }) => (
  <div style={{ maxWidth: '100%' }}>
    <Text>
      To start automating your online business, we need your dōTERRA ID.
    </Text>
    <SectionInput>
      <WrapperInput>
        <label>doTERRA ID</label>
        <InputText value={memberId} onChange={handleUpdateMemberId} />
      </WrapperInput>
    </SectionInput>
  </div>
);

export default DoterraIdConfirmation;
