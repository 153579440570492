import React from 'react';
import useReactRouter from 'use-react-router';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import bottleImg from 'assets/img/testEnrollmentBar/bottle.png';
import { Container, Button } from 'ui/TestEnrollmentBar/styles';

const TestEnrollmentBar = () => {
  const { history } = useReactRouter();
  const { handleSetShawdowUser } = useTheFlow();

  const onFinishExperience = async () => {
    await handleSetShawdowUser('off');
    history.push('/do-doterra');
  };

  return (
    <Container>
      <img src={bottleImg} alt="bottle-img" height="40px" />
      <span>Testing Personal Enrollment Funnel</span>
      <Button onClick={onFinishExperience}>End Test</Button>
    </Container>
  );
};

export default TestEnrollmentBar;
