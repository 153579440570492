import styled, { css } from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import ButtonDefault from 'ui/Button/Default';

export const ModalStyled = styled(Snackbar)`
  && {
    border-color: none;
    margin-bottom: 20px;
  }
  @media (min-width: 600px) {
    && {
    }
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    overflow: none;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
    border-radius: 15px;
    border: 5px white solid;
    @media (min-width: 600px) {
      width: 75vw;
      overflow: none;
      max-width: 1300px;
    }
  `}
`;

export const Button = styled(ButtonDefault)`
  ${() => css`
    margin: 15px 0;
    width: 60%;
    font-size: 16px;
    padding: 15px;
    border-radius: 25px;
    @media (min-width: 600px) {
      width: 230px;
      padding: 22px 0;
      border-radius: 55px;
      margin-left: 30px;
      margin-right: 30px;
      font-size: 18px;
    }
  `}
`;

export const WrapperImage = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 600px) {
      flex: 0.3;
    }
  `};
`;

export const Image = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  background-image: ${({ src }) => `url(${src})`};
  border: none;
  border-radius: 50%;
  @media (min-width: 600px) {
    width: 68px;
    height: 67px;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    font-weight: 800;
    font-family: ${theme.font.family.visby};
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    @media (min-width: 600px) {
      flex: 2;
      line-height: 28px;
      font-size: 18px;
    }
  `};
`;

export const Section = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-radius: 15px;
    background-color: rgba(0, 209, 207, 0.35);
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 30px 40px;
    }
  `};
`;
