import styled from 'styled-components';
import ButtonClean from 'ui/Button/Clean';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const Button = styled(ButtonClean)`
  width: 30%;
  margin-top: 5px;
  @media (min-width: 600px) {
    width: 60%;
    font-size: 14px;
  }
`;

export const Label = styled.div`
  display: flex;
  text-align: center;
  font-weight: bolder;
  padding: 5px 10px;
`;
