import imageTitleBackground from 'assets/img/setup/setup-title-background.jpg';

const SETUP_STATUS = {
  EXPLORE: {
    itemComplete: true,
  },
  TRY: {
    itemPending: true,
  },
  PREPARE: {},
  AUTOMATE: {},
};

export const CONTENT = {
  header: {
    title: 'Ready to embrace a life of better health?',
    imageTitleBackground,
    subtitle:
      'We’ll guide you step-by-step as you learn about essential oils, self-directed healthcare, and all of the new healthy possibilies that dōTERRA provides!',
    text: '',
    videoUrl: '',
    stepStatus: SETUP_STATUS,
    showWelcomeMessage: true,
  },
  body: {
    title: '',
    text: '',
  },
  highlight: '',
};

export const SETUP_PAGE = {
  EXPLORING: CONTENT,
  PENDING_CUSTOMER: CONTENT,
  CUSTOMER: CONTENT,
  BUILDER: CONTENT,
  PENDING_BUILDER: CONTENT,
};
