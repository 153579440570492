import React from 'react';
import Modal from 'ui/Modal';
import { Info } from '../../TheFlow/Setup/Info';
import styled, { css } from 'styled-components';

const ModalPreviewGreeting = ({
  isOpen,
  handleClose,
  welcomeMessage,
  profilePicture,
}) => (
  <Modal
    openAfterSubmit={false}
    isOpen={isOpen}
    onClose={handleClose}
    handleClose={handleClose}
    containerWidth={'80vw'}
    hideButtons>
    <ContainerModal>
      <SectionHeader>
        <span onClick={handleClose}>X</span>
      </SectionHeader>
      <Info
        welcomeMessage={welcomeMessage}
        profilePicture={profilePicture}
        shouldHideText
      />
    </ContainerModal>
  </Modal>
);

export { ModalPreviewGreeting };

const ContainerModal = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (min-width: 600px) {
      padding: 20px 0;
    }
  `};
`;

const SectionHeader = styled.div`
  ${() => css`
    padding: 15px 10px;
    text-align: center;
    position: relative;
    span {
      position: absolute;
      right: 5px;
      top: 0px;
      cursor: pointer;
      font-weight: 800;
    }
    @media (min-width: 600px) {
      span {
        right: 25px;
        top: 0px;
      }
    }
  `};
`;
