import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_RANKS } from 'graphql/queries/getRanks';
import { UPDATE_SELF_RANK_GOAL } from 'graphql/mutations/updateSelfRankGoal';

const useRankInformation = () => {
  const { loading, error, data } = useQuery(GET_RANKS);
  const [updateSelfGoal] = useMutation(UPDATE_SELF_RANK_GOAL);

  const handleUpdateSelfGoal = async ({
    goalDate,
    currentRankId,
    goalRankId,
  }) => {
    await updateSelfGoal({
      variables: {
        goalDate,
        currentRankId,
        goalRankId,
      },
    });
  };

  return {
    loading,
    error,
    data,
    handleUpdateSelfGoal,
  };
};

export default useRankInformation;
