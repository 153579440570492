import styled, { css } from 'styled-components';
import ButtonReverse from 'ui/Button/Reverse';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';

export const WrapperDateInput = styled.div`
  ${() => css`
    display: flex;
    > div:first-child {
      width: 100%;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const WrapperIcon = styled.button`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    width: 50px;
    outline: none;
    cursor: pointer;
    @media (min-width: 600px) {
    }
  `};
`;

export const IconCalendar = styled(DateRangeIcon)`
  ${({ theme }) => css`
    && {
      fill: ${theme.colors.primary};
      @media (min-width: 600px) {
        font-size: 38px;
      }
    }
  `}
`;

export const ContainerRankInformation = styled.div`
  border-radius: 8px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.11);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const WrapperInputs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  @media (min-width: 600px) {
  }
`;

export const SetField = styled.div`
  width: 100%;
  padding: 0 30px;
  margin-top: 10px;
  @media (min-width: 600px) {
  }
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: 600;
    margin-bottom: 10px;
    @media (min-width: 600px) {
    }
  `}
`;

export const ButtonClean = styled(ButtonReverse)`
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  &:hover {
    background-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const WrapperPlacementStrategy = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  background-color: #fffcd6;
  width: 100%;
  flex: 1;
  padding: 30px;
  @media (min-width: 600px) {
  }
`;

export const Strong = styled.div`
  font-weight: 600;
  @media (min-width: 600px) {
  }
`;

export const InfoText = styled.div`
  margin-top: 15px;
  @media (min-width: 600px) {
  }
`;

export const ButtonSet = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const ButtonStrategy = styled(ButtonClean)`
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  span {
    margin-right: 10px;
  }
  @media (min-width: 600px) {
    width: 45%;
    margin-bottom: 0;
  }
`;

export const IconVideo = styled(OndemandVideoOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const InfoSafeText = styled.div`
  margin: 10px 0;
  font-weight: 600;
`;
