import React from 'react';
import {
  AdditionalContainer,
  Title,
  FilesWrapper,
  FileWrapper,
  File,
  FileImage,
  IconBox,
} from 'pages/TheFlow/AdditionalResources/styles';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import { useAdditionalResources } from 'pages/TheFlow/AdditionalResources/data/useAdditionalResources';
import { PageContent } from 'ui/layouts';

const AdditionalResources = () => {
  const { fileList, hasContentToShow } = useAdditionalResources();

  return hasContentToShow ? (
    <PageContent>
      <AdditionalContainer>
        <Title>Additional Resources</Title>
        <FilesWrapper>
          {fileList.map(({ fileName, fileSource, fileThumbUrl }) => (
            <FileWrapper key={fileName}>
              <a href={fileSource} target="_blank" rel="noopener noreferrer">
                <File>
                  <FileImage src={fileThumbUrl}>
                    <IconBox>
                      <ArrowDownwardRoundedIcon size="small" />
                    </IconBox>
                  </FileImage>
                </File>
              </a>
              <span>{fileName}</span>
            </FileWrapper>
          ))}
        </FilesWrapper>
      </AdditionalContainer>
    </PageContent>
  ) : null;
};

export { AdditionalResources };
