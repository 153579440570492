import styled, { css } from 'styled-components';
import ButtonClean from 'ui/Button/Clean';
import DefaultButton from 'ui/Button/Default';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    padding-bottom: 40px;
    position: relative;
    display: block;
    @media (min-width: 600px) {
      overflow-y: scroll;
      width: 700px;
      padding-bottom: 40px;
    }
  `}
`;

export const CloseModal = styled.div`
  ${() => css`
    position: absolute;
    top: -25px;
    right: 20px;
    cursor: pointer;
    padding: 5px;
    span {
      font-weight: bolder;
    }
    @media (min-width: 600px) {
    }
  `};
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    margin-top: 45px;
    text-align: center;
    padding: 0 20px;
    line-height: 22px;
    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 32px;
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.montserrat};
      letter-spacing: -0.7px;
      font-weight: 800;
      line-height: 32px;
    }
    @media (min-width: 600px) {
      padding: 0 40px;
      h2 {
        line-height: 22px;
        font-size: 26px;
        letter-spacing: 0.1px;
      }
    }
  `};
`;

export const SectionBody = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding: 0 20px;
    line-height: 22px;
    @media (min-width: 600px) {
      padding: 0 40px;
      text-align: left;
    }
  `};
`;

export const SectionBottom = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    line-height: 22px;
    @media (min-width: 600px) {
      padding: 0 40px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  `};
`;

export const ButtonContinue = styled(DefaultButton)`
  ${() => css`
    width: 80%;
    font-size: 18px;
    text-align: center;
    line-height: 28px;
    @media (min-width: 600px) {
      width: 40%;
    }
  `}
`;

export const ButtonClose = styled(ButtonClean)`
  width: 80%;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 20px;
  @media (min-width: 600px) {
    width: 40%;
    margin-bottom: 0;
  }
`;
