import React, { Fragment, useEffect } from 'react';
import { PageContent } from 'ui/layouts';
import Steps from 'pages/TheFlow/Steps';
import Presentation from 'pages/TheFlow/Presentation';
import UserUtils from 'pages/TheFlow/UserUtils';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';
import WelcomeHeader from 'ui/WelcomeHeader';
import ChooseLeader from 'ui/ChooseLeaderModal';
import { AdditionalResources } from 'pages/TheFlow/AdditionalResources';
import Setup from './Setup';
import Loader from 'ui/Loader';

const TheFlow = () => {
  const {
    handleUpdatePositionStatus,
    handleUpdateMemberStatus,
    data,
    // statusBar,
    pageContent,
    loading,
    error,
    videosInfo,
    referralId,
    doterraId,
    isShadowUserEnabled,
    handleSetShawdowUser,
    welcomeMessage,
    profilePicture,
    isSetupStep,
  } = useTheFlow();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [data]);

  if (loading) return <Loader />;

  if (data && isSetupStep) {
    const { id } = data.self;

    return (
      <Setup
        ownerId={id}
        welcomeMessage={welcomeMessage}
        profilePicture={profilePicture}
      />
    );
  }

  return (
    <Fragment>
      <WelcomeHeader />
      <PageContent>
        <Presentation pageContent={pageContent} />
        <Steps
          data={data}
          loading={loading}
          error={error}
          videosInfo={videosInfo}
          handleUpdatePositionStatus={handleUpdatePositionStatus}
          handleUpdateMemberStatus={handleUpdateMemberStatus}
          referralId={referralId}
          doterraId={doterraId}
          isShadowUserEnabled={isShadowUserEnabled}
          handleSetShawdowUser={handleSetShawdowUser}
        />
      </PageContent>
      <UserUtils data={data} />
      <AdditionalResources />
      <ChooseLeader />
    </Fragment>
  );
};

export default TheFlow;
