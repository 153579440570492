import styled, { css } from 'styled-components';
import Button from 'ui/Button/Default';
import ButtonReverse from 'ui/Button/Reverse';
import LinkIcon from '@material-ui/icons/Link';

export const Wrapper = styled.div`
  border-radius: 8px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.11);
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  background-color: white;
  @media (min-width: 600px) {
    padding: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
  @media (min-width: 600px) {
  }
`;

export const Line = styled.span`
  line-height: 17px;
  font-size: 15px;
  @media (min-width: 600px) {
    line-height: 30px;
  }
`;

export const Line2 = styled.span`
  font-size: 14px;
  line-height: 17px;
  @media (min-width: 600px) {
    line-height: 30px;
  }
`;

export const ButtonMain = styled(Button)`
  margin-top: 0;
  margin-bottom: 25px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-size: 14px;
  span {
    margin-right: 8px;
  }
  @media (min-width: 600px) {
    max-width: 438px;
    font-size: 16px;
  }
`;

export const ButtonClean = styled(ButtonReverse)`
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  &:hover {
    background-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.7);
  }
  @media (min-width: 600px) {
    max-width: 438px;
    align-self: center;
    margin-top: 10px;
  }
`;

export const WrapperLink = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: 600px) {
      flex-direction: row;
    }
  `};
`;

export const TextLink = styled.div`
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
  color: #0645ad;
  margin-top: 5px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
  padding: 70px 50px 20px;
  p {
    color: #0e0d0d;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
  }
`;

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 24px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    @media (min-width: 600px) {
    }
  `};
`;

export const Icon = styled(LinkIcon)`
  && {
    fill: ${({ theme }) => theme.colors.primary};
    @media (min-width: 600px) {
      font-size: 42px;
      margin-bottom: 10px;
    }
  }
`;
