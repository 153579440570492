import styled, { css } from 'styled-components';
import Binoculars from 'ui/icons/Binoculars';
import TestBottle from 'ui/icons/TestBottle';
import Hammer from 'ui/icons/Hammer';
import ArrowUp from 'ui/icons/UpArrow';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${theme.container.max};
    @media (min-width: 600px) {
    }
  `};
`;

export const List = styled.div`
  ${() => css`
    width: 100%;
    @media (min-width: 600px) {
      width: 80%;
    }
  `};
`;

export const Item = styled.div`
  ${({ theme, isActive, isDashed }) => css`
      list-style-type: none;
      float: left;
      width: 25%;
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      &:first-child:after {
        content: none;
      }

      :before {
        content: ' ';
        line-height: 30px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 1px solid ${
          isActive || isDashed ? theme.colors.primary : theme.colors.white
        };
        display: block;
        text-align: center;
        margin: 0 auto 10px;
        background-color: ${
          isActive ? theme.colors.primary : theme.colors.white
        };
      }
      :after {
        content: '';
        position: absolute;
        width: 100%;
        height: auto;
        border-bottom: 7px solid ${theme.colors.white}
        ${isDashed &&
          `
          border-bottom: 7px dashed ${theme.colors.primary};
        `}
        ${isActive &&
          `
          border-bottom: 7px solid ${theme.colors.primary};
        `}
        background-color: transparent;
        top: 23px;
        left: -50%;
        z-index: -1;
      }
      @media (min-width: 600px) {
        font-size: 16px;
      }
    }
  `};
`;

export const ExploreIcon = styled(Binoculars)`
  ${({ theme, isActive }) => css`
    && {
      top: 11px;
      z-index: 10;
      font-size: 32px;
      position: absolute;
      left: 31%;
      fill: ${isActive ? theme.colors.white : theme.colors.primary};
      @media (min-width: 600px) {
        top: 8px;
        left: 43.5%;
      }
    }
  `}
`;

export const TryIcon = styled(TestBottle)`
  ${({ theme, isActive }) => css`
    && {
      top: 10px;
      z-index: 10;
      font-size: 32px;
      position: absolute;
      color: ${isActive ? theme.colors.white : theme.colors.primary};
      left: 31%;
      @media (min-width: 600px) {
        top: 8px;
        left: 43.2%;
      }
    }
  `}
`;

export const HammerIcon = styled(Hammer)`
  ${({ theme, isActive }) => css`
    && {
      top: 9px;
      z-index: 10;
      font-size: 34px;
      position: absolute;
      color: ${isActive ? theme.colors.white : theme.colors.primary};
      left: 32%;
      @media (min-width: 600px) {
        top: 9px;
        left: 43.5%;
      }
    }
  `}
`;

export const ArrowUpIcon = styled(ArrowUp)`
  ${({ theme, isActive }) => css`
    && {
      top: 9px;
      left: 31.5%;
      z-index: 10;
      font-size: 34px;
      position: absolute;
      color: ${isActive ? theme.colors.white : theme.colors.primary};
      @media (min-width: 600px) {
        top: 9px;
        left: 43%;
      }
    }
  `}
`;
