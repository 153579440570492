import gql from 'graphql-tag';

export const GET_MEMBER_STATUS = gql`
  query getMemberStatus {
    self {
      id
      firstName
      lastName
      authId
      positionStatus
      oweStatus
      email
      profilePicture
      fullName
      doterraAccount {
        id
        backOfficeLink
      }
      referralLinks {
        id
        link
        label
      }
      referredBy {
        id
        fullName
        email
        profilePicture
        doterraAccount {
          id
          backOfficeLink
          memberId
        }
      }
    }
  }
`;
