import React, { useState } from 'react';
import {
  ShareLinkBarContainer,
  Icon,
  IconImage,
  PopoverMessange,
} from 'ui/ShareLinkBar/styles';
import useClipboard from 'react-use-clipboard';
import Popover from '@material-ui/core/Popover';
import emailImage from 'assets/img/shareLink/email.png';
import linkImage from 'assets/img/shareLink/link.png';

const ShareLinkBar = ({ name, refLink }) => {
  const [isLinkCopied, setCopied] = useClipboard(refLink); // eslint-disable-line
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  const generateBody = () =>
    `Hey, there!\n\nAre you interested in learning about natural ways to support your physical and emotional health?\nI partner with dōTERRA to help others discover simple, safe, and affordable solutions to take control of their family’s health and wellness needs.\n\nIf you’d like to learn more – on your own time and pressure-free! – follow this link:\n${refLink}\n\nI’ll follow up to see if you have any questions soon! Can’t wait for you to check it out and let me know what you think.\nSincerely,\n\n${name}`;

  const handleEmailClick = () => {
    window.open(
      `mailto:?subject=An invitation for you&body=${encodeURIComponent(
        generateBody()
      )}`,
      '_blank'
    );
  };

  const handleCopiedLink = event => {
    setCopied();
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
    setTimeout(() => {
      setShowPopover(false);
    }, 2000);
  };

  return (
    <ShareLinkBarContainer>
      <span>Share Your Link: </span>
      <Icon onClick={handleEmailClick}>
        <IconImage src={emailImage} />
      </Icon>
      <Icon onClick={handleCopiedLink}>
        <IconImage src={linkImage} />
      </Icon>
      <Popover
        id={showPopover ? 'simple-popover' : undefined}
        open={showPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <PopoverMessange>Link Copied</PopoverMessange>
      </Popover>
    </ShareLinkBarContainer>
  );
};

export { ShareLinkBar };
