import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
`;

export const CenterContent = styled.div`
  padding-top: 140px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1150px;
  @media (max-width: 600px) {
    padding-top: 60px;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  div {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    font-size: 56px;
    line-height: 54px;
    font-weight: 700;
    img {
      margin-left: 30px;
      margin-top: -10px;
      width: 80px;
      height: 80px;
    }
  }
  span {
    margin-bottom: 29px;
    color: #979797;
    font-size: 16px;
    line-height: 25px;
  }
  a {
    padding: 18px 37px;
    font-size: 17px;
    width: 270px;
  }
  @media (max-width: 600px) {
    padding-left: 20px;
    margin-bottom: 20px;
    div {
      margin-bottom: 14px;
      font-size: 33px;
      line-height: 36px;
      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        margin-top: 0px;
      }
    }
    a {
      align-self: center;
    }
  }
`;
export const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    img {
      width: 90%;
      height: auto;
    }
  }
`;

export const Button = styled(RouterLink)`
  padding: 11px 25px;
  border-radius: 50px;
  background-color: #1ad5ca;
  transition: all 200ms ease;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  transform: translate(0px, 0px);
  border: none;
  display: inline-block;

  &:hover {
    background-color: #10aaa1;
    transform: translate(0px, -3px);
  }
`;
