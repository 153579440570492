import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  @media (min-width: 600px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0;
  }
`;
