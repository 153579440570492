import React from 'react';
import { CONTENT } from '../../data/setupPage';
import {
  Container,
  WrapperTitle,
  Title,
  WrapperWelcomeMessage,
  WelcomeMessage,
  Avatar,
  WrapperTitleImageBackground,
  SubTitle,
  SubTitle2,
  WrapperText,
} from './styles';

const Info = ({ welcomeMessage, profilePicture, shouldHideText }) => {
  const { header } = CONTENT;

  const showWelcomeSection = welcomeMessage && profilePicture;

  const addLineBreaks = (string = '') =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  return (
    <Container>
      <WrapperTitle>
        <WrapperTitleImageBackground src={header.imageTitleBackground}>
          <Title>{addLineBreaks(header.title)}</Title>
        </WrapperTitleImageBackground>

        <WrapperText>
          {showWelcomeSection ? (
            <WrapperWelcomeMessage>
              <Avatar src={profilePicture} />
              <WelcomeMessage>{addLineBreaks(welcomeMessage)}</WelcomeMessage>
            </WrapperWelcomeMessage>
          ) : null}
          <SubTitle>{header.subtitle}</SubTitle>
          {shouldHideText ? null : (
            <SubTitle2>To get started, please answer below:</SubTitle2>
          )}
        </WrapperText>
      </WrapperTitle>
    </Container>
  );
};

export { Info };
