import React from 'react';
import loading from '../assets/loading.svg';

const Loading = () => (
  <div className="spinner">
    <img alt="Loading" src={loading} />
  </div>
);

export default Loading;
