import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 293.7 293.7" className={props.className}>
      <path
        d="M120.1,36.2c-4.5-0.2-8.5-0.1-12.5-0.6c-4.7-0.6-8.3-4.4-9.1-8.8c-0.7-4.2,1.4-9,5.4-11c2-1,4.4-1.5,6.7-1.5
			c27.9-0.1,55.8-0.1,83.8,0c7.2,0,12.2,4.5,12.1,10.8c0,6.3-5,10.7-12.2,10.9c-3,0.1-6,0-9.5,0c0,1.9,0,3.3,0,4.7
			c0,19.3-0.1,38.6,0.1,58c0,2.6,0.9,5.5,2.2,7.6c27.1,43,54.4,85.8,81.6,128.7c1.9,2.9,3.6,6,4.9,9.2c5.6,14.7-2.5,27.5-18.2,28.2
			c-16.1,0.7-32.3,0.5-48.4,0.5c-49.6,0-99.2,0-148.8-0.1c-4.6,0-9.4-0.2-13.8-1.3c-11.6-2.8-17.4-12.9-14-24.3
			c1.3-4.4,3.5-8.6,6-12.5c27.1-42.8,54.3-85.6,81.4-128.4c1.4-2.2,2.2-5.1,2.2-7.6c0.2-19.2,0.1-38.4,0.1-57.5
			C120.1,39.6,120.1,38,120.1,36.2z M141.8,36.2c0,1.7,0,3.1,0,4.5c0,21.5,0,43-0.1,64.5c0,2.4-0.8,5.2-2.1,7.2
			c-14.7,23.3-29.5,46.6-44.3,69.8c-0.7,1.2-1.4,2.4-2.3,4c39.8,0,79,0,118.8,0c-0.9-1.6-1.6-2.8-2.4-4
			c-14.8-23.3-29.6-46.5-44.2-69.9c-1.3-2.1-2.1-4.8-2.2-7.2c-0.2-20.9-0.1-41.8-0.1-62.7c0-2,0-4,0-6.2
			C155.7,36.2,149,36.2,141.8,36.2z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
