import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 360 360" className={props.className}>
      <path
        className="st0"
        d="M126.2,172.7c-20.6-19.7-31.3-43.3-28.5-71.7c2.1-21.3,12-39,27.7-53.3c32.4-29.5,79.5-29,111.6,0.4
			c32.4,29.6,41.3,85.1-1.1,124.8c1.1,0.5,2.1,1,3.2,1.4c45,20.1,75.1,53.7,89.2,100.9c9.5,31.8-13,61.3-46.4,61.4
			c-42.3,0.1-84.6,0-126.9,0c-24.7,0-49.4,0-74.1,0c-17.3,0-31.5-6.4-41.3-20.9c-9.2-13.6-10.1-28.4-5-43.8
			c15.1-45.7,44.6-78.3,88.6-97.7C124.1,173.7,125,173.2,126.2,172.7z M180.7,316.7C180.7,316.7,180.7,316.7,180.7,316.7
			c34.5,0,69.1,0.1,103.6,0c17.3,0,30.3-16.7,25.7-33.4c-14.2-51-46.8-83.7-98.2-97.3c-5.4-1.4-8.2-4-8.8-8.5
			c-0.6-4.7,1.4-8.3,6.5-10.7c34.5-17.1,42.8-55.1,29.7-84.9c-11.2-25.5-38-39.5-67.4-35.9c-36.8,4.6-60.4,37.8-53.9,75.5
			c3.6,20.7,15.5,35.5,34,45.1c5.4,2.8,7.4,6,6.9,10.9c-0.5,4.7-3.2,7.2-9,8.7c-51.1,13.6-83.3,46.2-98,96.8
			c-4.8,16.4,9.9,34.4,26.3,34.1C112.3,316.3,146.6,316.7,180.7,316.7z"
      />
    </SvgIcon>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};
