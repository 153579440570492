import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'pages/TheFlow/Steps/Cards/styles';
import StepBox from 'pages/TheFlow/Steps/StepBox';
import UpdateMembershipModal from 'pages/TheFlow/Steps/UpdateMembershipModal';
import PrepareToShareModal from 'pages/TheFlow/Steps/PrepareToShareModal';
import ValidateDoTerraIDModal from 'pages/TheFlow/Steps/ValidateDoTerraIDModal';
import ShadowUserVipModal from 'pages/TheFlow/Steps/ShadowUserVipModal';
import useReactRouter from 'use-react-router';
import { POSITION_STATUS, MEMBER_STATUS } from 'config/constants';
import ReactGA from 'react-ga';
import useTheFlow from 'pages/TheFlow/data/useTheFlow';

import { useAuth0 } from 'context/auth';
import { ModalPreviewGreeting } from 'pages/Profile/ModalPreviewGreeting';
import get from 'lodash/get';

const Cards = ({
  doTerraStatus,
  oweStatus,
  handleUpdatePositionStatus,
  videosInfo,
  referralId,
  doterraId,
  isShadowUserEnabled,
  handleSetShawdowUser,
}) => {
  const { http } = useAuth0();
  const { history } = useReactRouter();
  const [isOpenMembershipModal, setIsOpenMembershipModal] = useState(false);
  const [isOpenPrepareToShareModal, setIsOpenPrepareToShareModal] = useState(
    false
  );
  const [isOpenDoterrIDModal, setIsOpenDoterrIDModal] = useState(false);
  const [isOpenShadowUserVipModal, setIsOpenShadowUserVipModal] = useState(
    false
  );

  const [previewMessage, setPreviewMessage] = useState('')
  const [previewProfilePicture, setPreviewProfilePicture] = useState('')

  const {profilePicture,loading, data} = useTheFlow()

  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const handleClosePreview = () => {
    setIsOpenPreview(false);
  }

  useEffect(()=>{
    if(doTerraStatus === "EXPLORING" && isShadowUserEnabled){
      setIsOpenPreview(isShadowUserEnabled)
      setPreviewMessage(get(data,'self.userCustomization.welcomeMessage',''))
      setPreviewProfilePicture(profilePicture)
    }
  },[isShadowUserEnabled])

  const {
    EXPLORING,
    PENDING_CUSTOMER,
    CUSTOMER,
    PENDING_BUILDER,
    BUILDER,
  } = POSITION_STATUS;

  const { MEMBER, VIP } = MEMBER_STATUS;

  const handleStepOne = async () => {
    if (doTerraStatus === PENDING_CUSTOMER) {
      return await handleUpdatePositionStatus(EXPLORING);
    }
    history.push('/video-library');
  };

  const handleStepFour = async () => {
    history.push('/video-library');
  };

  const handleStepFive = async () => {
    //send email
    http.post(`/send-mail`, {
      input: {
        mailType: 'readyToUpgrade',
      },
    });
    setIsOpenPrepareToShareModal(true);
  };

  const handleStepSix = async () => {
    history.push('/video-library');
  };

  const handleStepSeven = async () => {
    history.push('/lrp');
  };

  const handleUpdateMembership = () => {
    setIsOpenMembershipModal(true);
  };

  const handleStepTwo = async () => {
    if (referralId) {
      return history.push('/kits');
    }
    setIsOpenDoterrIDModal(true);
  };

  const handleStepThirteen = async () => {
    await handleUpdatePositionStatus(CUSTOMER);
  };

  const handleStepEight = () => {
    window.location.assign('https://login.doterra.com/us/en-us/sign-in');
  };

  const handleStepNine = () => {
    history.push('/confirm-wellness-advocate-status');
  };

  const handleStepTen = () => {
    if (isShadowUserEnabled) {
      setIsOpenShadowUserVipModal(true);
      return;
    }
    history.push('/become-vip');
  };

  const handleStepEleven = () => {
    history.push('/video-library');
  };

  const handleStepFourteen = async () => {
    handleUpdatePositionStatus(BUILDER);
  };

  const handleStepSixteen = async () => {
    //GA EVENT
    ReactGA.event({
      category: 'Feature',
      action: 'Test Funnel'
    });
    handleSetShawdowUser('on');
  };

  const preMemberCards = status => {
    switch (status) {
      case EXPLORING:
        return (
          <>
            <StepBox {...STEPS[1]} cardNumber={1} />
            <StepBox {...STEPS[2]} cardNumber={2} />
          </>
        );
      case PENDING_CUSTOMER:
        return (
          <>
            <StepBox {...STEPS[3]} cardNumber={1} />
            <StepBox {...STEPS[0]} cardNumber={2} />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderCustomerStatus = () => {
    if (isShadowUserEnabled) {
      return <StepBox {...STEPS[5]} cardNumber={2} />;
    }
    return doterraId ? (
      <StepBox {...STEPS[14]} cardNumber={2} />
    ) : (
      <StepBox {...STEPS[5]} cardNumber={2} />
    );
  };

  const memberCards = status => {
    switch (status) {
      case CUSTOMER: // page 3
        return (
          <>
            <StepBox {...STEPS[4]} cardNumber={1} />
            {renderCustomerStatus()}
          </>
        );
      case PENDING_BUILDER: // page 4
        return (
          <>
            <StepBox {...STEPS[9]} cardNumber={1} />
            <StepBox {...STEPS[13]} cardNumber={2} />
          </>
        );
      case BUILDER: // page 5
        return (
          <>
            <StepBox {...STEPS[6]} cardNumber={1} />
            {!isShadowUserEnabled && <StepBox {...STEPS[16]} cardNumber={2} />}
            <StepBox {...STEPS[10]} cardNumber={!isShadowUserEnabled ? 3 : 2} />
          </>
        );
      case EXPLORING: // page 6
        return (
          <>
            <StepBox {...STEPS[1]} cardNumber={1} />
            <StepBox {...STEPS[15]} cardNumber={2} />
          </>
        );
      default:
        return <></>;
    }
  };

  const vipCards = () => (
    <>{!isShadowUserEnabled && <StepBox {...STEPS[16]} />}</>
  );

  const renderSteps = () => {
    if (oweStatus === MEMBER) {
      return memberCards(doTerraStatus);
    }
    if (oweStatus === VIP) {
      return vipCards();
    }
    return preMemberCards(doTerraStatus);
  };

  const STEPS = {
    0: {
      title: 'Still exploring?',
      description:
        'Need a little more time before setting up a customer account? No problem! Just click to go back.',
      buttonLabel: `Go Back`,
      handleButton: handleStepOne,
      isInvertedColour: true,
    },
    1: {
      title: 'Explore.',
      description:
        'Why dōTERRA? Why essential oils? Why NOW? Get answers to all of your questions in the Video Library!',
      buttonLabel: `Take me to the Library!`,
      handleButton: handleStepOne,
      isVideoCard: true,
      videoInfo: videosInfo.explore,
    },
    2: {
      title: 'Try the products.',
      description:
        'Time to put the products to the test! Check out a variety of great starter kits and get 25% off.',
      buttonLabel: 'Try the Products',
      handleButton: handleStepTwo,
    },
    3: {
      title: 'Confirm your membership.',
      description:
        'Were you successful in setting up your customer account? Update your dōTERRA membership status now to continue!',
      buttonLabel: 'Confirm Membership',
      handleButton: handleUpdateMembership,
    },
    4: {
      title: 'Good health starts here.',
      description:
        '"I’ve got my oils… now what?” Your road to good health starts by simply using your oils and making them a part of your life.',
      buttonLabel: 'Learn to love your loot!',
      handleButton: handleStepFour,
      isVideoCard: true,
      videoInfo: videosInfo.learn,
    },
    5: {
      title: 'Prepare to share.',
      description:
        'Once you gain a passion for the products, you may find yourself naturally sharing with others! Upgrade your dōTERRA membership to Wellness Advocate and be compensated for helping others discover holistic, self-directed healthcare. It’s FREE and does not require any additional commitments.',
      buttonLabel: 'Upgrade Account',
      handleButton: handleStepFive,
    },
    6: {
      title: 'Get ready to build.',
      description:
        "Learn fast-start steps to setting up your business, how to start thinking about building your team, and the daily habits you'll need for succes.",
      buttonLabel: 'Check out Video Library!',
      handleButton: handleStepSix,
      isVideoCard: true,
      videoInfo: videosInfo.build,
    },
    7: {
      title: 'Earn awesome rewards.',
      description:
        'Setting up your Loyalty Rewards Program (LRP) will enable you to qualify for commissions and give you the opportunity to try new products and keep your must-haves stocked.',
      buttonLabel: 'Set up my LRP!',
      handleButton: handleStepSeven,
    },
    8: {
      title: 'Upgrade to Wellness Advocate.',
      description:
        'Upgrade your dōTERRA account to Wellness Advocate so you can begin sharing products, earning commissions, and building your team.',
      buttonLabel: 'Upgrade Account',
      handleButton: handleStepEight,
    },
    9: {
      title: 'Confirm builder status.',
      description:
        'Before we begin automating your business, let’s verify that you are a Wellness Advocate and that we have your correct dōTERRA ID.',
      buttonLabel: 'Confirm Status',
      handleButton: handleUpdateMembership,
    },
    10: {
      title: 'Get your VIP Membership.',
      description:
        'Are you ready to put Drippl to work for you? Become a VIP Member and start building both efficiently and fearlessly!',
      buttonLabel: 'Become a VIP',
      handleButton: handleStepTen,
    },
    11: {
      title: 'Explore.',
      description:
        'Take a look at what your contacts will discover in Video Library - our online dōTERRA education experience.',
      buttonLabel: 'Take me to Video Library!',
      handleButton: handleStepEleven,
      isVideoCard: true,
      videoInfo: videosInfo.explore,
    },
    12: {
      title: 'Confirm your membership.',
      description:
        'To get started with Drippl, let us know your current dōTERRA membership status.',
      buttonLabel: 'Confirm Membership',
      handleButton: handleUpdateMembership,
    },
    13: {
      title: 'Still testing the products?',
      description:
        "If you haven't upgraded your account to Wellness Advocate and still want time to test the products, no problem! Let us know by setting your status back to Customer.",
      buttonLabel: 'Set Status to Customer',
      handleButton: handleStepThirteen,
      isInvertedColour: true,
    },
    14: {
      title: 'Ready to Move Forward?',
      description:
        'Now that you know the basics of incorporating dōTERRA into your daily life, learn everything you need to know to Share dōTERRA confidently by advancing to the next step.',
      buttonLabel: 'Share dōTERRA',
      handleButton: handleStepFourteen,
    },
    15: {
      title: 'Enrollments Kits',
      description:
        'Check out the enrollment kits we present to your referrals. The system is designed to sell enrollment kits for you. Click the following button and select one of the kits on the next page to move forward.',
      buttonLabel: 'Test Your Kits',
      handleButton: handleStepTwo,
    },
    16: {
      title: 'Test Enrollment Funnel',
      description:
        'Your personal enrollment funnel will educate your referrals, sell kits, and enroll members on your team.  See everything your referrals will see when you share your referral link.  Give it a try!',
      buttonLabel: 'Test My Funnel',
      handleButton: handleStepSixteen,
    },
  };

  return (
    <Container>
      {renderSteps()}
      <UpdateMembershipModal
        isOpen={isOpenMembershipModal}
        doTerraStatus={doTerraStatus}
        handleClose={() => setIsOpenMembershipModal(false)}
      />
      <PrepareToShareModal
        isOpen={isOpenPrepareToShareModal}
        handleClose={() => setIsOpenPrepareToShareModal(false)}
      />
      <ValidateDoTerraIDModal
        isOpen={isOpenDoterrIDModal}
        handleClose={() => setIsOpenDoterrIDModal(false)}
      />
      <ShadowUserVipModal
        isOpen={isOpenShadowUserVipModal}
        handleClose={() => setIsOpenShadowUserVipModal(false)}
      />
      {!loading &&       <ModalPreviewGreeting
        isOpen={isOpenPreview}
        handleClose={handleClosePreview}
        welcomeMessage={previewMessage}
        profilePicture={previewProfilePicture}
      /> }

    </Container>
  );
};

Cards.propTypes = {
  doTerraStatus: PropTypes.string.isRequired,
  oweStatus: PropTypes.string.isRequired,
  handleUpdateMemberStatus: PropTypes.func,
  handleUpdatePositionStatus: PropTypes.func,
  videosInfo: PropTypes.shape({
    explore: PropTypes.object,
    learn: PropTypes.object,
    build: PropTypes.object,
  }),
};

export default Cards;
