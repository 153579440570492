import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

export const DateSelect = styled(DatePicker)`
  ${() => css`
    display: block;
    font-size: 16px;
    color: #444;
    line-height: 29px;
    padding: 8px 30px 8px 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #d8d8d8;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    outline: none;
    @media (min-width: 600px) {
    }
  `};
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    @media (min-width: 600px) {
      max-width: ${theme.container.max};
      justify-content: space-between;
      flex-direction: row;
    }
  `}
`;

export const BoxContent = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 600px) {
    width: 47%;
    padding-bottom: 0;
  }
`;

export const Left = styled(BoxContent)`
  order: 2;
  @media (min-width: 600px) {
    order: 1;
  }
`;

export const Right = styled(BoxContent)`
  order: 1;
  @media (min-width: 600px) {
    order: 2;
  }
`;

export const Header = styled.div`
  background-color: rgba(231, 231, 231, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 25px 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  span {
    margin-right: 8px;
  }
  @media (min-width: 600px) {
  }
`;
