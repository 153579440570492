import { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_THE_FLOW_INFO } from 'graphql/queries/getTheFlowInfo';
import { UPDATE_POSITION_STATUS } from 'graphql/mutations/updatePositionStatus';
import { SET_SHADOW_USER_STATUS } from 'graphql/mutations/setShadowUser';
import { UPDATE_OWE_STATUS } from 'graphql/mutations/updateMemberStatus';
import { UPDATE_MEMBER_STATUSES } from 'graphql/mutations/updateStatuses';
import { PREMEMBER_PAGE } from 'pages/TheFlow/data/prememberPage';
import { MEMBER_PAGE } from 'pages/TheFlow/data/memberPage';
import { VIP_PAGE } from 'pages/TheFlow/data/vipPage';
import { SETUP_PAGE } from 'pages/TheFlow/data/setupPage';
import { GET_COURSES_PROGRESS } from 'graphql/queries/getCouseProgress';
import { GET_MEMBER_REFER } from 'graphql/queries/getMemberRefer';
import { MEMBER_STATUS } from 'config/constants';

import get from 'lodash/get';

const videoInfo = {
  id: '',
  name: '',
  coverImage: {
    url: '',
  },
};

const PAGES = {
  PREMEMBER: PREMEMBER_PAGE,
  MEMBER: MEMBER_PAGE,
  VIP: VIP_PAGE,
  SETUP: SETUP_PAGE,
};

const defaultStatusBar = {
  EXPLORE: {},
  TRY: {},
  PREPARE: {},
  AUTOMATE: {},
};

const defaultVideos = {
  explore: videoInfo,
  learn: videoInfo,
  build: videoInfo,
};

const useTheFlow = () => {
  const { loading, error, data } = useQuery(GET_THE_FLOW_INFO);
  const [updateUser] = useMutation(UPDATE_POSITION_STATUS);
  const [setShadowUser] = useMutation(SET_SHADOW_USER_STATUS);
  const [updateOWEStatus] = useMutation(UPDATE_OWE_STATUS);
  const [updateMemberStatuses] = useMutation(UPDATE_MEMBER_STATUSES);
  const [getCoursesProgress, { data: dataCourses }] = useLazyQuery(
    GET_COURSES_PROGRESS
  );

  const [pageContent, setPageContent] = useState(PREMEMBER_PAGE.EMPTY);
  const [statusBar, setStatusBar] = useState(defaultStatusBar);
  const [videosInfo, setVideosInfo] = useState(defaultVideos);
  const [isShadowUserEnabled, setIsShadowUserEnabled] = useState(false);

  const handleSetShawdowUser = async status => {
    await setShadowUser({
      variables: { status },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
        {
          query: GET_MEMBER_REFER,
        },
      ],
    });
  };

  const handleUpdatePositionStatus = async status => {
    await updateUser({
      variables: { status },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  const handleUpdateMemberStatuses = async ({ positionStatus, oweStatus }) => {
    await updateMemberStatuses({
      variables: { positionStatus, oweStatus },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  const handleUpdateMemberStatus = async status => {
    await updateOWEStatus({
      variables: { status },
      refetchQueries: () => [
        {
          query: GET_THE_FLOW_INFO,
        },
      ],
    });
  };

  useEffect(() => {
    if (data && data.self) {
      const shadowUser = get(data, 'self.shadowUser', '');
      setIsShadowUserEnabled(shadowUser ? true : false);
      const { oweStatus, positionStatus } = data.self;
      const PAGE_CONTENT = get(
        PAGES,
        `${oweStatus}.${positionStatus}`,
        PREMEMBER_PAGE.EXPLORING
      );
      setPageContent(PAGE_CONTENT);
      const selectedStatusBar = get(
        PAGE_CONTENT,
        'header.stepStatus',
        defaultStatusBar
      );
      setStatusBar(selectedStatusBar);
    }
    if (data && data.labModules) {
      const labVideos = {
        explore: get(data.labModules, '[0]', {}), // module title: Meet doTerra
        learn: get(data.labModules, '[1]', {}), // module title: Live doTerra
        build: get(data.labModules, '[2]', {}), // module title: share doTerra
      };
      setVideosInfo(labVideos);
      getCoursesProgress({
        variables: {
          idExplore: labVideos.explore.id,
          idLive: labVideos.learn.id,
          idBuild: labVideos.build.id,
        },
      });
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (dataCourses) {
      const { explore, live, build } = dataCourses;
      setVideosInfo(prevState => ({
        explore: {
          ...prevState.explore,
          currentVideoInfo: explore,
        },
        learn: {
          ...prevState.learn,
          currentVideoInfo: live,
        },
        build: {
          ...prevState.build,
          currentVideoInfo: build,
        },
      }));
    }
  }, [dataCourses]);

  return {
    loading,
    error,
    data,
    handleUpdatePositionStatus,
    handleUpdateMemberStatus,
    handleUpdateMemberStatuses,
    handleSetShawdowUser,
    pageContent,
    statusBar,
    videosInfo,
    oweStatus: get(data, 'self.oweStatus', 'SETUP'),
    positionStatus: get(data, 'self.positionStatus', 'EXPLORING'),
    referralId: get(data, 'self.referredBy.doterraAccount.memberId', ''),
    isShadowUserEnabled,
    doterraId: get(data, 'self.doterraAccount.memberId', false),
    doterraLoginUrl: get(
      data,
      'self.region.doterraLoginUrl',
      'https://www.doterra.com/US/en'
    ),
    welcomeMessage:
      get(data, 'self.referredBy.userCustomization.welcomeMessage', '') || '',
    profilePicture: get(data, 'self.referredBy.profilePicture', '') || '',
    isSetupStep: get(data, 'self.oweStatus', 'SETUP') === MEMBER_STATUS.SETUP,
  };
};

export default useTheFlow;
